<div class="row form-group">
  <div class="col-md-12">
    <mat-card class="card">
      <mat-card-header>
        <h3>{{ 'project.uploaddocuments' | translate }}</h3>
      </mat-card-header>
      <mat-card-content>
        <upload-files (documentsCountchanged)="documentsCount = $event" (uploadDocumentsClicked)="uploadFiles($event)" [uploadingFiles]="uploadingFiles"></upload-files>
        <div class="row form-group mt-3" *ngIf="documentsCount > 0">
          <mat-form-field appearance="fill" *ngFor="let nomenclatureCategory of nomenclatureCategories">
            <mat-label>{{ nomenclatureCategory.name }}</mat-label>
            <mat-select name="category"
                        (selectionChange)="onSelectCategoryChanged($event)">
              <mat-option *ngFor="let type of nomenclatureCategory.types" [value]="type">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="mandatorySelectControlHasError">{{ 'generic.selectanoption' | translate }}</mat-error>
        </div>
        <div class="row form-group" *ngIf="documentsCount > 0">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'file.name' | translate }}</mat-label>
            <input matInput name="fileName" [(ngModel)]="projectFile.title">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'file.version' | translate }}</mat-label>
            <input matInput name="fileVersion" [(ngModel)]="revisionFile.version">
          </mat-form-field>
          <mat-progress-bar *ngIf="uploadingFiles == true" mode="determinate" value="{{ revisionFile.progressUpload }}"></mat-progress-bar>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row mt-3">

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> {{ 'file.title' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
    </ng-container>

    <ng-container matColumnDef="lastversion">
      <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'file.lastversion' | translate }} </th>
      <td mat-cell *matCellDef="let element" class="text-center"> <span *ngIf="element.revisionFiles" class="badge-success text-center">{{ element.revisionFiles[0]?.version }}</span></td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'file.owner' | translate }} </th>
      <td mat-cell *matCellDef="let element" class="text-center"> 
        <span *ngIf="element.revisionFiles && element.revisionFiles[0]?.owner" style="display: inline-block; margin: 0 auto;">
          <avatar mat-icon-button matTooltip="{{ element.revisionFiles[0]?.owner }}" [isEditable]="false"
          [size]="'30px'"
          [name]="element.revisionFiles[0]?.owner"
          [imageSource]="this.dataAccessServices.currentUser.picture"></avatar>
        </span>
      </td>
    </ng-container>
    
    <ng-container *ngFor="let nomenclatureCategory of nomenclatureCategories">
      <ng-container matColumnDef="{{ nomenclatureCategory.name }}">
        <th mat-header-cell *matHeaderCellDef>{{ nomenclatureCategory.name }}</th>
        <td mat-cell *matCellDef="let element">{{ getNomenclatureType(element, nomenclatureCategory) }}</td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-flat-button color="basic" (click)="viewFile(element)" [disabled]="element.isAutoDeskComputing === true">
          {{ 'file.fileview' | translate }}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" (click)="editFile(element)">{{ 'generic.edit' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteFile(element)">{{ 'generic.delete' | translate }}</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  <!--
  <mat-tab-group #codificationTabs [selectedIndex]="0">
    <mat-tab *ngFor="let nomenclatureCodification of nomenclatureCodifications" label="{{ nomenclatureCodification.name }}">
      
    </mat-tab>
  </mat-tab-group>
  -->
</div>
