import { EntityBase } from "../entity.base.model";
import { RevisionFile } from "../files/revision.file.model";
import { AutoDeskTaskStatus } from "./autodesk.task.status.enum";

export class AutoDeskTask extends EntityBase {
  revisionFileId: string;
  derivatives: string;
  status: string;
  progress: string;
  urn: string;
}
