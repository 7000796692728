import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StringHelpers {
    public cleanForBucket(data: string): string {
        let cleanedString = data.toLowerCase().replace(/ /g, '_').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        cleanedString = cleanedString.replace(/î/g, 'i');
        cleanedString = cleanedString.replace(/é/g, 'e');
        cleanedString = cleanedString.replace(/è/g, 'e');
        cleanedString = cleanedString.replace(/ô/g, 'o');
        return cleanedString;
    }    
  }