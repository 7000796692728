import { EntityBase } from "../entity.base.model";
import { UserGroup } from "./user.group.model";

export class Group extends EntityBase {
  projectId: string;
  name: string;
  isDocumentsValidators: boolean;

  userGroups: Array<UserGroup>;
}
