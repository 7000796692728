import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Scoring } from 'src/app/models/entities/suppliers/scoring.model';

@Injectable({
  providedIn: 'root'
})
export class ScoringsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getScorings(project: Project): Promise<Array<Scoring>> {
    console.log('ScoringsServices.getScorings: Start to get scorings', project);

    return new Promise<Array<Scoring>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ScoringsServices.getScorings : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Scoring[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `scorings`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('ScoringsServices.getScorings : Scorings', data);
        resolve(data.documents);
      });
    });
  }

  public async addScoring(scoring: Scoring): Promise<Scoring> {
    console.log('ScoringsServices.addScoring: Start to add scoring', scoring);

    return new Promise<Scoring>(async (resolve, reject) => {

      const scoringData = { 
        projectId: scoring.projectId, 
        title: scoring.title, 
        weighting: scoring.weighting, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ScoringsServices.addScoring : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Scoring>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `scorings`, document: scoringData }, httpOptions).toPromise().then((data: any) => {
        console.log('ScoringsServices.addScoring : Scoring added', data);
        scoring._id = data.insertedId;
        resolve(scoring);
      });

    });
  }
}
