import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { QuotesServices } from 'src/app/services/quotes/quotes.services';
import { ContractsServices } from 'src/app/services/contracts/contracts.services';

import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { Contract } from 'src/app/models/entities/contracts/contract.model';
import { QuoteStatus } from 'src/app/models/entities/quotes/quote.status.enum';
import { ContractStatus } from 'src/app/models/entities/contracts/contract.status.enum';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent {
  
  private quoteId: string;

  public selectedProject: Project = null;
  public quote: Quote = new Quote();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private quotesServices: QuotesServices,
    private contractsServices: ContractsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('QuoteComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.quoteId = params['quoteid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
     }

  async ngOnInit() {
    let suppliers = new Array<Supplier>();
    await this.quotesServices.getQuote(this.quoteId).then(data => {
      console.log('QuoteComponent.ngOnInit : Quote', data);
      this.quote = data;
    });
  }

  public async updateQuote(): Promise<void> {
    await this.quotesServices.updateQuote(this.quote).then((data: Quote) => {
      console.log('QuoteComponent.updateSupplier: Quote updated', data);
      this.quote = data;
    });

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'quotes']);
  }

  public async convertToContract(): Promise<void> {

    if(this.quote.status == QuoteStatus.accepted) {
      const contract = new Contract();
      contract.supplierId = this.quote.supplierId;
      contract.quoteId = this.quote._id;
      contract.originalTitle = this.quote.originalTitle;
      contract.documentTitle = this.quote.documentTitle;
      contract.name = this.quote.name;
      contract.comment = this.quote.comment;
      contract.downloadURL = null;
      contract.ownerId = this.quote.ownerId;
      contract.status = ContractStatus.proofread;
      contract.amount = this.quote.amount;

    await this.contractsServices.addContract(contract).then((data: Contract) => {
      console.log('QuoteComponent.convertToContract: Contract added', data);
    });

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'contracts']);
    } else {
      this.snackBar.open(this.translateService.instant('quote.statusnotaccepted'));
    }
  }
}
