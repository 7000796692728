import { Component, ElementRef, ViewChild } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";
import { SettingsServices } from "src/app/services/commons/settings.services";


@Component({
  selector: 'snapshot-modal',
  templateUrl: 'snapshot.modal.html'
})
export class SnapshotModal {

  @ViewChild('videoElement') videoElement: ElementRef;
  video: any;
  stream: any;
  public snapshot: string = null;

  constructor(
    public dialogRef: MatDialogRef<SnapshotModal>,
    private settingsServices: SettingsServices
  ) {}

  ngAfterViewInit() {
    this.video = this.videoElement.nativeElement;
    this.startCamera();
  }

  async startCamera() {
    try {
      let constraints = null;
      if(this.settingsServices.isCellPhone) {
        constraints = {
          video: {
            facingMode: { exact: 'environment' }
          },
          audio: false
        };
      } else {
        constraints = { video: true, audio: false };
      }
      
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.video.srcObject = this.stream;
      this.video.play();
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  }

  capturePhoto() {
    const canvas = document.createElement('canvas');
    canvas.width = this.video.videoWidth;
    canvas.height = this.video.videoHeight;
    canvas.getContext('2d').drawImage(this.video, 0, 0, canvas.width, canvas.height);
    this.snapshot = canvas.toDataURL('image/jpeg');
    this.dialogRef.close(this.snapshot);
    this.stopCamera();
  }

  stopCamera() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }

  onNoClick(): void {
    this.stopCamera();
    this.dialogRef.close(false);
  }
}