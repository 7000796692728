import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import {
  ViewerOptions,
  ViewerInitializedEvent
} from 'ng2-adsk-forge-viewer';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';

import { DataAccessServices } from 'src/app/services/data.access.services';
import { AutoDeskServices } from '../../../services/autodesk/autodesk.services';
import { FilesServices } from 'src/app/services/files/files.services';
import { AnnotationTypesServices } from 'src/app/services/annotations/annotation.types.services';
import { AnnotationsServices } from 'src/app/services/annotations/annotations.services';
import { RevisionFilesServices } from 'src/app/services/files/revision.files.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { AnnotationPicturesServices } from 'src/app/services/annotations/annotation.pictures.services';

import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { AnnotationPicture } from 'src/app/models/entities/annotations/annotation.pictures.model';

import { AddAnnotationModal } from 'src/app/shared/components/modals/annotations/add.annotation.modal';
import { EditAnnotationModal } from 'src/app/shared/components/modals/annotations/edit.annotation.modal';
import { AnnotationsTypesFiltersModal } from 'src/app/shared/components/modals/annotations/annotations.types.filters.modal';
import { AnnotationsCampaignsFiltersModal } from 'src/app/shared/components/modals/annotations/annotations.campaigns.filters.modal';
import { SnapshotModal } from 'src/app/shared/components/modals/snapshot/snapshot.modal';
import { CampaignsServices } from 'src/app/services/campaigns/campaigns.services';
import { Campaign } from 'src/app/models/entities/campaigns/campaign.model';
import { Task } from 'src/app/models/entities/tasks/task.model';
import { EditTaskModal } from 'src/app/shared/components/modals/edit-task/edit.task.modal';
import { TasksServices } from 'src/app/services/tasks/tasks.services';
import { TaskType } from 'src/app/models/entities/tasks/task.type.enum';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { AutodeskViewerComponent } from 'src/app/shared/components/autodesk-viewer/autodesk-viewer.component';

declare const Autodesk: any;

@Component({
  selector: 'app-view.construction.files',
  templateUrl: './view.construction.files.component.html',
  styleUrls: ['./view.construction.files.component.scss']
})

export class ViewConstructionFilesComponent {

  @ViewChild(AutodeskViewerComponent) autodeskViewer: AutodeskViewerComponent;

  private selectedProject: Project;
  private revisionFileId: string;
  private campaigns: Array<Campaign> = new Array<Campaign>();
  private tasks: Array<Task> = new Array<Task>();
  
  public revisionFile: RevisionFile;
  public annotations: Array<Annotation> = new Array<Annotation>();
  public annotationTypes: Array<AnnotationType> = new Array<AnnotationType>();
  public suppliers: Array<Supplier> = new Array<Supplier>();
  public pdfDocuments: any;
  public annotationsDataSource = new MatTableDataSource<Annotation>(this.annotations);
  public annotationsDisplayedColumns: string[] = ['number', 'subject', 'campaign', 'ownerFullName', 'viewName', 'takesnapshot', 'edit'];
  public tasksDataSource = new MatTableDataSource<Task>(this.tasks);
  public tasksDisplayedColumns: string[] = ['supplier', 'name', 'startDate', 'endDate', 'edit'];
  
  public isGeometryLoaded: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataAccessServices: DataAccessServices,
    private autoDeskServices: AutoDeskServices,
    private filesServices: FilesServices,
    private revisionFilesServices: RevisionFilesServices,
    private annotationsServices: AnnotationsServices,
    private annotationTypesServices: AnnotationTypesServices,
    private annotationPicturesServices: AnnotationPicturesServices,
    private usersServices: UsersServices,
    private campaignsServices: CampaignsServices,
    private tasksServices: TasksServices,
    private suppliersServices: SuppliersServices,
    private dialog: MatDialog
    ) {
      this.activatedRoute.params.subscribe(params => {
        console.log('ViewFilesComponent.constructor : Retrieved params', params, this.dataAccessServices.currentUser);
        this.revisionFileId = params['revisionfileid'];
        const projectId = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
      });
  }

  public async ngAfterViewInit() {
    console.log('ViewFilesComponent.ngAfterViewInit : Initialiez viewer');

    this.autodeskViewer.isGeometryLoaded.subscribe(value => {
      this.isGeometryLoaded = value;
    });

    await this.revisionFilesServices.getRevisionFile(this.revisionFileId).then((data: RevisionFile) => {
      console.log('ViewFilesComponent.ngAfterViewInit: File', data);
      this.revisionFile = data;
    });

    let projectFile = null;
    await this.filesServices.getFile(this.revisionFile.projectFileId).then(data => {
      console.log('ViewFilesComponent.ngOnInit : Get file', data);
      projectFile = data;
    });

    await this.annotationTypesServices.getAnnotationTypes(projectFile.projectId).then(data => {
      console.log('ViewFilesComponent.ngOnInit : Annotation types', data);
      data.forEach(item => item.visible = true);
      this.annotationTypes = data;
    });

    await this.annotationsServices.getAnnotations(this.revisionFile).then((data: Array<Annotation>) => {
      console.log('ViewFilesComponent.ngAfterViewInit: Annotations', data);
      this.annotations = data;
      this.annotationsDataSource = new MatTableDataSource<Annotation>(this.annotations);
    });

    this.annotations.forEach(async annotation => {
      this.setOwnerFullName(annotation);
      await this.annotationPicturesServices.getAnnotationPictures(annotation).then((data: Array<AnnotationPicture>) => {
        console.log('ViewFilesComponent.ngAfterViewInit: Annotation pictures', data);
        if(annotation.annotationPictures == null) {
          annotation.annotationPictures = new Array<AnnotationPicture>();
        }
        annotation.annotationPictures = data;
      });

      await this.campaignsServices.getCampaigns(projectFile.projectId).then((data: Array<Campaign>) => {
        console.log('ViewFilesComponent.ngAfterViewInit: Campaigns', data);
        data.forEach(item => item.visible = true);
        this.campaigns = data;
      });

      if(annotation.campaignId !== undefined && annotation.campaignId !== null) {
        annotation.campaign = new List(this.campaigns).First(c => c._id === annotation.campaignId);
      }
    });

    await this.suppliersServices.getSuppliers(projectFile.projectId).then(data => {
      console.log('ViewFilesComponent.ngAfterViewInit : Suppliers', data);
      this.suppliers = data;
    });

    await this.tasksServices.getTasks(projectFile.projectId).then((data: Array<Task>) => {
      console.log('ViewFilesComponent.ngAfterViewInit: Tasks', data);
      data.forEach(d => {
        if(d.supplierId !== undefined && d.supplierId !== null) {
          d.supplier = new List(this.suppliers).First(s => s._id === d.supplierId);
        }
      });
      this.tasks = data;
      const justTasks = new List(this.tasks).Where(t => t.type === TaskType.task).OrderBy(t => new Date(t.start)).ToArray();
      this.tasksDataSource = new MatTableDataSource<Task>(justTasks);
    });
  }

  public getRowStyle(task: Task) {
    const currentDate = new Date();
    if (currentDate >= new Date(task.start) && currentDate <= new Date(task.end)) {
      return { 'color': 'orange' };
    } else if (currentDate > task.end) {
      return { 'color': 'red' };
    } else {
      return { 'color': 'black' };
    }
  }

  public takeSnapshot(annotation: Annotation) {
    console.log('ViewFilesComponent.takeSnapshot : Take a snapshot', annotation);
    const dialogRef = this.dialog.open(SnapshotModal, {
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== false) {
        let annotationPicture = new AnnotationPicture();
        annotationPicture.annotationId = annotation._id;
        annotationPicture.data = result;
        this.annotationPicturesServices.addAnnotationPicture(annotationPicture).then((data: AnnotationPicture) => {
          console.log('ViewFilesComponent.takeSnapshot : Annotation picture added', data);
          if(annotation.annotationPictures == null) {
            annotation.annotationPictures = new Array<AnnotationPicture>();
          }
          annotation.annotationPictures.push(data);
        });
      }
    });
    
  }
  
  public editTask(task: Task) {
    const dialogRef = this.dialog.open(EditTaskModal, {
      data: { task: task, tasks: this.tasks, project: this.selectedProject },
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
    });
  }

  public openAnnotationTypesFilters() {
    const dialogRef = this.dialog.open(AnnotationsTypesFiltersModal, {
      data: [...this.annotationTypes],
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== false) {
        // clear annotation dots
        this.annotationTypes = result as Array<AnnotationType>;
        this.annotations.forEach(item => this.autodeskViewer.removeAnnotation(item));
        // filters only taken annotations
        let filteredAnnotations = new Array<Annotation>();
        this.annotations.forEach(item => {
          if(item.campaignId !== null && 
            item.campaignId !== undefined) {
              if(new List(this.campaigns).First(c => c._id == item.campaignId).visible) {
                filteredAnnotations.push(item);
              }
          } else {
            if(new List(this.annotationTypes).First(at => at._id == item.annotationTypeId).visible) {
              filteredAnnotations.push(item);
            }
          }
        });
        // draw filtred annotations
        filteredAnnotations.forEach(item => this.autodeskViewer.drawAnnotation(item));
      }
    });
  }

  public openAnnotationCampaignsFilters() {
    const dialogRef = this.dialog.open(AnnotationsCampaignsFiltersModal, {
      data: [...this.campaigns],
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== false) {
        // clear annotation dots
        this.campaigns = result as Array<Campaign>;
        this.annotations.forEach(item => this.autodeskViewer.removeAnnotation(item));
        // filters only taken annotations
        let filteredAnnotations = new Array<Annotation>();
        this.annotations.forEach(item => {
          if(item.campaignId !== null && 
            item.campaignId !== undefined) {
              if(new List(this.campaigns).First(c => c._id == item.campaignId).visible) {
                filteredAnnotations.push(item);
              }
          } else {
            if(new List(this.annotationTypes).First(at => at._id == item.annotationTypeId).visible) {
              filteredAnnotations.push(item);
            }
          }
        });
        // draw filtred annotations
        filteredAnnotations.forEach(item => this.autodeskViewer.drawAnnotation(item));
      }
    });
  }

  private async setOwnerFullName(annotation: Annotation): Promise<void> {
    this.usersServices.get(annotation.ownerId).then(data => {
      annotation.ownerFullName = `${data.firstname} ${data.lastname}`;
    });
  }
  
  /*
  private loadSupplier(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Autodesk.Viewing.Document.load(`urn:${this.autoDeskServices.base64UrlEncode('urn:adsk.objects:os.object:65981a81a3b45357020869cc/fd333634-3fa9-4f32-a338-e18e8c0a9590-uploads_files_3652828_Truck_Fbx.fbx')}`, (document) => {
        const defaultModel = document.getRoot().getDefaultGeometry();
        console.log('ViewFilesComponent.loadSupplier : Default model', defaultModel);
        this.defaultViewName = defaultModel.data.name;
        const options = {
          //placementTransform: new Autodesk.Matrix4().setTranslation(this.documentCenter.x, this.documentCenter.y, this.documentCenter.z),
          globalOffset: { x: 0, y: 0, z: 0 }
        };
        this.viewer.loadDocumentNode(document, defaultModel, options).then((data) => {
          console.log('ViewFilesComponent.loadSupplier : Supplier loaded', data);
          resolve(true);
        });
       
        this.viewer.loadDocumentNode(document, defaultModel,
          {
            keepCurrentModels: true,
            preserveView: true,  // 2D drawings
            modelSpace: true,    // 2D drawings
            applyRefPoint: true, // 3D shared coordinates
            applyScaling: 'm',   // force all models to same scale
            globalOffset: {x:0,y:0,z:0},  // force all models to origin
            placementTransform: new THREE.Matrix4().setPosition(new THREE.Vector3(100, 0, 0)) // Exemple: déplacer le modèle de 10 unités sur l'axe x
        }).then((data) => {
          console.log('ViewFilesComponent.loadSupplier : Supplier loaded', data);
          resolve(true);
        });
   
      });
    });
  }
  */

  

}
