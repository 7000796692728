import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html'
})
export class SuppliersComponent {

  public selectedProject: Project = null;
  public suppliers: Array<Supplier> = new Array<Supplier>();
  public displayedColumns: string[] = ['name', 'licenceNumber', 'deliveredLicenceDate', 'companyDigitalIdentifier', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Supplier>(this.suppliers);
  public panelOpenState = false;
  public searchedSuppliers: Supplier = new Supplier();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('SuppliersComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  public async ngOnInit() {
    await this.suppliersServices.getSuppliers(this.selectedProject).then(data => {
      console.log('SuppliersComponent.ngOnInit : Supliers', data);
      this.suppliers = data;
      this.dataSource = new MatTableDataSource<Supplier>(this.suppliers);
    });
  }

  public onSearchedProjectsChanged(): void {
    if (this.searchedSuppliers.name !== undefined && this.searchedSuppliers.name !== null && this.searchedSuppliers.name !== '') {
      const filteredSuppliers = new List(this.suppliers).Where(p => p.name.toLowerCase().includes(this.searchedSuppliers.name.toLowerCase())).ToArray();
      this.dataSource = new MatTableDataSource<Supplier>(filteredSuppliers);
    }
  }

  public resetFilters(): void {
    this.searchedSuppliers = new Supplier();
    this.dataSource = new MatTableDataSource<Supplier>(this.suppliers);
  }

  public addSupplier(): void {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'suppliers', 'add']);
  }

  public deleteSupplier(supplier: Supplier): void {

  }
}
