import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';
import { Group } from 'src/app/models/entities/users/group.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { DataAccessServices } from 'src/app/services/data.access.services';
import { GroupsServices } from 'src/app/services/users/groups.services';

@Component({
  selector: 'app-add-group',
  templateUrl: './add.group.component.html',
  styleUrls: ['./add.group.component.scss']
})
export class AddGroupComponent {

  public selectedProject: Project = null;
  public group: Group = new Group();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private dataAccessServices: DataAccessServices,
    private groupsServices: GroupsServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('AddNomenclatureCodificationComponent.constructor : Retrieved params', params);
      const projectid = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
      this.group.projectId = this.selectedProject._id;
      this.group.isDocumentsValidators = false;
    });
  }
  
  public addGroup(): Promise<void> {
    return this.groupsServices.addGroup(this.group).then((data: Group) => {
      console.log('AddGroupComponent.addGroup: Group added', data);
      this.router.navigate(['projects', 'project', this.selectedProject._id, 'groups']);
    });
  }
}
