<div class="col-md-8 offset-md-2">
  <mat-card>
    <mat-card-header class="d-flex justify-content-center">
      <mat-card-title>{{ 'profile.myprofile' | translate }}</mat-card-title>
    </mat-card-header>
    <form>
      <mat-card-content>

        <div class="row form-group">
          <mat-form-field>
            <mat-label>{{ 'user.email' | translate }}</mat-label>
            <input matInput name="userEmail" placeholder="{{ 'user.email' | translate }}" [(ngModel)]="dataAccessServices.currentUser.email">
          </mat-form-field>
        </div>

        <div class="row form-group">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'profile.language' | translate }}</mat-label>
            <mat-select
                        placeholder="{{ 'profile.languagesample' | translate }}"
                        [(ngModel)]="settingsServices.currentLanguage"
                        name="language"
                        (selectionChange)="onSelectLanguageChanged($event)">
              <mat-option *ngFor="let item of settingsServices.supportedlanguages" [value]="item.value">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        

        <div class="row form-group d-flex justify-content-center">
          <button mat-raised-button color="primary" (click)="validateSettings()">{{ 'generic.validate' | translate }}</button>
        </div>

      </mat-card-content>

    </form>
  </mat-card>
</div>


