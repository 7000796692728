import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';

import { DataAccessServices } from 'src/app/services/data.access.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { BoxesServices } from 'src/app/services/iot/boxes.services';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { SlotsServices } from 'src/app/services/projects/slots.services';
import { BoxConfigurationsServices } from 'src/app/services/iot/box.configurations.services';
import { BoxConfiguration } from 'src/app/models/entities/iot/boxes/box.configuration.model';

@Component({
  selector: 'app-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss']
})
export class BoxesComponent {

  public panelOpenState: boolean;
  public selectedProject: Project = null;
  public slots: Array<Slot> = new Array<Slot>();
  public boxes: Array<Box> = new Array<Box>();
  public boxPageIndex: number = 1;
  public boxPageSize: number = 25;
  public boxTotal: number = 0;
  public displayedBoxesColumns: string[] = ['ipaddress', 'macaddress', 'slot', 'edit'];
  public boxesDataSource = new MatTableDataSource<Box>(this.boxes);
  public searchedBoxes: Box = new Box();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataAccessServices: DataAccessServices,
    private slotsServices: SlotsServices, 
    private boxesServices: BoxesServices,
    private boxConfigurationsServices: BoxConfigurationsServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('BoxesComponent.constructor : Retrieved params', params);
      const projectId = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
    });
  }

  public async ngOnInit() {

    await this.slotsServices.getSlots(this.selectedProject).then(data => {
      console.log('BoxesComponent.constructor : Slots', data);
      this.slots = data;
    });

    await this.boxesServices.getBoxes(this.selectedProject).then(data => {
      console.log('BoxesComponent.constructor : Boxes', data);
      this.boxes = data;
    });

    for(let i = 0; i < this.boxes.length; i++) {
      let box = this.boxes[i];
      await this.boxConfigurationsServices.getBoxConfigurationByBoxId(box._id).then((data: BoxConfiguration) => {
        box.configuration = data;
      });
  
      await this.slotsServices.getSlot(box.configuration.slotId).then((data: Slot) => {
        box.configuration.slot = data;
      });
    }

    this.boxesDataSource = new MatTableDataSource<Box>(this.boxes);
  }

  public resetBoxFilters(): void {

  }

  public onSearchedBoxesChanged(): void {

  }
}
