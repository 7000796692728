import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectStatus } from '../../models/entities/projects/project.status.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectStatusServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }
  
    public async getProjectStatus(): Promise<Array<ProjectStatus>> {
      console.log('ProjectStatusServices.getProjectStatus: Start to get project status');
      
      return new Promise<Array<ProjectStatus>>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectStatusServices.getProjectStatus : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<ProjectStatus[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `projectstatus` }, httpOptions).toPromise().then((data: any) => {
          console.log('ProjectStatusServices.getProjectStatus : Project status', data);
          resolve(data.documents);
        });
      });
    }

  public async addProjectStatus(projectStatus: ProjectStatus): Promise<ProjectStatus> {
    console.log('ProjectStatusServices.addProjectStatus: Start to add project status', projectStatus);

    return new Promise<ProjectStatus>((resolve, reject) => {
      console.log('ProjectStatusServices.addProjectStatus: Start to add project status', projectStatus);

      const projectStatusData = { 
        name: projectStatus.name, 
        modification: new Date(),
        creation: new Date() 
      };
  
      return new Promise<ProjectStatus>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectStatusServices.addProjectStatus : Token', data);
          token = data;
        });
  
        const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<ProjectStatus>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `projectstatus`, document: projectStatusData }, httpOptions).toPromise().then((data: any) => {
          console.log('ProjectStatusServices.addProjectStatus : Project status added', data);
          projectStatus._id = data.insertedId;
          resolve(projectStatus);
        });
      });
    });

  }
}
