import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { SuppliersScoringComponent } from './list/suppliers-scoring.component';
import { SupplierScoringComponent } from './details/supplier-scoring.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/suppliersscorings', component: SuppliersScoringComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/suppliersscorings/supplierscoring/:supplierscoringid', component: SupplierScoringComponent, canActivate: [AuthGuardServices] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SuppliersScoringRoutingModule { }
