<div class="row mt-3">
    <div class ="col-md-1">
        <button mat-flat-button color="primary" (click)="toggleAddTaskModal()">{{ 'generic.add' | translate }}</button>
    </div>
    <div class ="col-md-3">
        <mat-form-field class="full-width">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class ="col-md-3">
        <mat-form-field>
          <mat-label>{{ 'scheduler.starttime' | translate }}</mat-label>
          <input matInput type="time" [(ngModel)]="startTime" (ngModelChange)="onStartTimeChange($event)">
        </mat-form-field>
    </div>
    <div class ="col-md-3">
        <mat-form-field>
          <mat-label>{{ 'scheduler.endtime' | translate }}</mat-label>
          <input matInput type="time" [(ngModel)]="endTime" (ngModelChange)="onEndTimeChange($event)">
        </mat-form-field>
    </div>
    <div class ="col-md-2">
        <mat-form-field>
            <mat-select placeholder="{{ 'scheduler.type' | translate }}" [(ngModel)]="schedulerViewType">
                <mat-option [value]="'week'">{{ 'scheduler.types.week' | translate }}</mat-option>
                <mat-option [value]="'month'">{{ 'scheduler.types.month' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row mt-3">
    <timesheet 
    [tasks]="tasks" 
    [selectedDate]="selectedDate" 
    [startTime]="startTime"
    [endTime]="endTime"
    [hoursDisplayed]="hoursDisplayed"
    [viewType]="schedulerViewType"
    [isCellPhone]="this.settingsServices.isCellPhone"
    (selectedTaskChanged)="selectedTaskChanged($event)"></timesheet>
</div>
