import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { List } from "linqts";

import { AnnotationType } from "src/app/models/entities/annotations/annotation.type.model";

@Component({
  selector: 'annotations-types-filters-modal',
  templateUrl: 'annotations.types.filters.modal.html'
})
export class AnnotationsTypesFiltersModal {

  public isAllChecked: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AnnotationsTypesFiltersModal>,
    @Inject(MAT_DIALOG_DATA) public annotationTypes: Array<AnnotationType>,
  ) {}

  async ngOnInit() {
    if(new List(this.annotationTypes).Any(at => at.visible == false)) {
      this.isAllChecked = false;
    }
  } 

  public updateAllChecked() {
    if(this.isAllChecked) {
      this.annotationTypes.forEach(item => item.visible = true);
    } else {
      this.annotationTypes.forEach(item => item.visible = false);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }
}