import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import * as CryptoJS from 'crypto-js';

import { environment } from '../../../environments/environment';

import { LocalStorageServices } from '../commons/local.storage.services';
import { DataAccessServices } from '../data.access.services';

import { User } from '../../models/entities/users/user.model';
import { RealmTokenServices } from '../commons/realm.token.services';

@Injectable({
  providedIn: 'root'
})
export class UsersServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

    public async authenticate(email: string, password: string): Promise<User> {
      console.log('UsersServices.authenticate: Start to get user by email', email);
      
      return new Promise<User>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.authenticate : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        const hash = CryptoJS.SHA256(`${User.prefix_salt}${password}${User.suffix_salt}`);
        password = hash.toString(CryptoJS.enc.Hex);
        await this.httpClient.post<User>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `users`, filter: { 'email': { $eq: email }, 'password': { $eq: password } } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.authenticate : Items', data);
          resolve(data.document);
        });
      });
    }

    public async getUserByEmail(email: string): Promise<User> {
      console.log('UsersServices.getUserByEmail: Start to get user by email', email);
      
      return new Promise<User>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.getUserByEmail : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        await this.httpClient.post<User>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `users`, filter: { 'email': { $eq: email } } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.getUserByEmail : Items', data);
          resolve(data.document);
        });
      });
    }

    public async get(userId: string): Promise<User> {
      console.log('UsersServices.get: Start to get user by id', userId);
  
      return new Promise<User>(async (resolve, reject) => {
  
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.get : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<User>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `users`, filter: { '_id': { $eq: { $oid: userId } } } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.get : User', data);
          resolve(data.document);
        });
  
      });
    }

    public async addUser(user: User): Promise<User> {
      console.log('UsersServices.addUser: Start to add user', user);
  
      const userData = { 
        firstname: user.firstname, 
        lastname: user.lastname, 
        email: user.email.toLowerCase(), 
        profile: user.profile,
        modification: new Date(),
        creation: new Date() 
      };
  
      return new Promise<User>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.addUser : Token', data);
          token = data;
        });
  
        const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<User>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `users`, document: userData }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.addUser : Project added', data);
          user._id = data.insertedId;
          resolve(user);
        });
      });
  
    }

    public async userSubscribed(user: User): Promise<User> {
      console.log('UsersServices.userSubscribed: Start to user subscription', user);
      
      return new Promise<User>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.userSubscribed : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<User>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-subscriptions`, filter: { 'userId': { $eq: user._id }  } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.userSubscribed : Items', data);
          resolve(data.document);
        });
      });
    }
    
    public async subscribeUser(user: User, token: string): Promise<User> {
      console.log('UsersServices.subscribeUser: Start to subscribe user', user);
  
      const subscriptionData = { 
        userId: user._id, 
        token: token, 
        modification: new Date(),
        creation: new Date() 
      };
  
      return new Promise<User>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.subscribeUser : Token', data);
          token = data;
        });
  
        const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<User>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-subscriptions`, document: subscriptionData }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.subscribeUser : User subscribed', data);
          user._id = data.insertedId;
          resolve(user);
        });
      });
  
    }

    public async updateSubscription(subscriptionId: string, userToken: string): Promise<User> {
      console.log('UsersServices.updateSubscription: Start to update subscription', subscriptionId, userToken);
  
      return new Promise<User>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.updateSubscription : Token', data);
          token = data;
        });
    
        const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
    
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
  
        const subscriptionData = { 
          token: userToken, 
          modification: new Date()
        };
          
        await this.httpClient.post<User>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-subscriptions`, filter: { '_id': { $eq: { $oid: subscriptionId } } }, update: { $set: subscriptionData } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.updateSubscription : Subscription updated', data);
          if(data.matchedCount > 0) {
            resolve(data.document);
           } else {
            reject(data);
          }
        });
      });
    }

    public async updateUser(user: User): Promise<User> {
      console.log('UsersServices.updateUser: Start to update user', user);
  
      return new Promise<User>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.updateUser : Token', data);
          token = data;
        });
    
        const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
    
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
  
        const userData = { 
          firstname: user.firstname, 
          lastname: user.lastname, 
          profile: user.profile, 
          modification: new Date()
        };
          
        await this.httpClient.post<User>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `users`, filter: { '_id': { $eq: { $oid: user._id } } }, update: { $set: userData } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.updateUser : User updated', data);
          if(data.matchedCount > 0) {
            resolve(data.document);
           } else {
            reject(data);
          }
        });
      });
    }
}
