import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';

import { DataAccessServices } from 'src/app/services/data.access.services';
import { GroupsServices } from 'src/app/services/users/groups.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Group } from 'src/app/models/entities/users/group.model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {

  public panelOpenState: boolean;
  public selectedProject: Project = null;
  public groups: Array<Group> = new Array<Group>();
  public groupPageIndex: number = 1;
  public groupPageSize: number = 25;
  public groupTotal: number = 0;
  public displayedGroupsColumns: string[] = ['name', 'isvalidator'];
  public groupsDataSource = new MatTableDataSource<Group>(this.groups);
  public searchedGroups: Group = new Group();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataAccessServices: DataAccessServices,
    private groupsServices: GroupsServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('DocumentsComponent.constructor : Retrieved params', params);
      const projectId = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
    });
  }

  public async ngOnInit() {
    await this.groupsServices.getGroups(this.selectedProject).then(data => {
      console.log('DocumentsComponent.constructor : Groups', data);
      this.groups = data;
      this.groupsDataSource = new MatTableDataSource<Group>(this.groups);
    });
  }

  public resetGroupFilters(): void {

  }

  public onSearchedGroupsChanged(): void {

  }

  public updateGroup(group: Group): void {
    group.isDocumentsValidators = !group.isDocumentsValidators;
    console.log('DocumentsComponent.updateGroup : Start to change validators status', event, group);
    this.groupsServices.updateGroup(group).then(data => {
      console.log('DocumentsComponent.updateGroup : Group updated', data);
    });
  }
}
