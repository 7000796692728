import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import {
  ViewerOptions,
  ViewerInitializedEvent
} from 'ng2-adsk-forge-viewer';
import { MatDialog } from '@angular/material/dialog';

import { DataAccessServices } from '../../../services/data.access.services';
import { RevisionFilesServices } from '../../../services/files/revision.files.services';
import { RevisionFileCommentsServices } from 'src/app/services/files/revision.file.comments.services';
import { AutoDeskServices } from 'src/app/services/autodesk/autodesk.services';
import { UsersServices } from 'src/app/services/users/users.services';

import { Project } from '../../../models/entities/projects/project.model';
import { RevisionFile } from '../../../models/entities/files/revision.file.model';
import { RevisionFileStatus } from 'src/app/models/entities/files/revision.file.status.enum';
import { AddRevisionFileCommentModal } from 'src/app/shared/components/modals/revisionfiles/add.revision.file.comment.modal';
import { RevisionFileComment } from 'src/app/models/entities/files/revision.file.comment.model';
import { SignatureModal } from 'src/app/shared/components/modals/signature/signature.modal';

@Component({
  selector: 'app-edit.filereview',
  templateUrl: './edit.filereview.component.html',
  styleUrls: ['./edit.filereview.component.scss']
})
export class EditFileReviewComponent {

  public defaultViewName: string = null;
  public selectedProject: Project = null;
  public selectedRevisionFile: RevisionFile;
  public revisionFileComments: Array<RevisionFileComment> = new Array<RevisionFileComment>(); 
  public views2d: any;
  public views3d: any;
  public views: any;

  private revisionFileId: string;
  private viewer : any;
  private viewerDocument: any;
  private selectedViewIndex: number = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private autoDeskServices: AutoDeskServices,
    private revisionFilesServices: RevisionFilesServices,
    private revisionFileCommentsServices: RevisionFileCommentsServices) {

    this.activatedRoute.params.subscribe(params => {
      console.log('EditFileReviewComponent.constructor : Retrieved params', params);
      let projectid = params['projectid'];
      this.revisionFileId = params['revisionfileid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }
    });
  }

  public async ngOnInit() {
    console.log('EditFileReviewComponent.ngOnInit : Start');
  }

  public async ngAfterViewInit() {
    console.log('EditFileReviewComponent.ngAfterViewInit : Initialiez viewer');
    await this.getRevisionFile();
    await this.getRevisionFileComments();

    await this.initializeViewer().then(data => {
      console.log('ViewFilesComponent.ngAfterViewInit: Viewer initialized', data);
    });
  }

  public async approveFile() {

    let revisionFileComment = new RevisionFileComment();
    revisionFileComment.ownerId = this.dataAccessServices.currentUser._id;
    revisionFileComment.revisionFileId = this.selectedRevisionFile._id;
    const dialogRef = this.dialog.open(AddRevisionFileCommentModal, {
      data: revisionFileComment,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== undefined && result !== null) {
        const signatureDialogRef = this.dialog.open(SignatureModal, {
          data: this.selectedRevisionFile,
        });
        signatureDialogRef.afterClosed().subscribe(async result => {
          console.log('The dialog was closed', result);
          if(result !== undefined && result !== null) {
            this.selectedRevisionFile.status = RevisionFileStatus.accepted;
            this.selectedRevisionFile.userValidatedId = this.dataAccessServices.currentUser._id;
            await this.revisionFilesServices.updateRevisionFile(this.selectedRevisionFile).then(data => {
              console.log('FilesComponent.approveFile: Revision file approved', data);
            });
            await this.revisionFileCommentsServices.addRevisionFileComment(revisionFileComment).then(data => {
              console.log('FilesComponent.approveFile: Revision file comment added', data);
              this.revisionFileComments.unshift(data);
            });
          }
        });
      }
    });    
  }

  public async changeRequestFile() {
    let revisionFileComment = new RevisionFileComment();
    revisionFileComment.ownerId = this.dataAccessServices.currentUser._id;
    revisionFileComment.revisionFileId = this.selectedRevisionFile._id;
    const dialogRef = this.dialog.open(AddRevisionFileCommentModal, {
      data: revisionFileComment,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== undefined && result !== null) {
        this.selectedRevisionFile.status = RevisionFileStatus.changerequest;
        await this.revisionFilesServices.updateRevisionFile(this.selectedRevisionFile).then(data => {
          console.log('FilesComponent.approveFile: Revision file change requested', data);
        });
        await this.revisionFileCommentsServices.addRevisionFileComment(revisionFileComment).then(data => {
          console.log('FilesComponent.approveFile: Revision file comment added', data);
          this.revisionFileComments.unshift(data);
        });
      }
    });
  }

  public async rejectFile() {
    let revisionFileComment = new RevisionFileComment();
    revisionFileComment.ownerId = this.dataAccessServices.currentUser._id;
    revisionFileComment.revisionFileId = this.selectedRevisionFile._id;
    const dialogRef = this.dialog.open(AddRevisionFileCommentModal, {
      data: revisionFileComment,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== undefined && result !== null) {
        this.selectedRevisionFile.status = RevisionFileStatus.denied;
        await this.revisionFilesServices.updateRevisionFile(this.selectedRevisionFile).then(data => {
          console.log('EditFileReviewComponent.approveFile: Revision file denied', data);
        });
        await this.revisionFileCommentsServices.addRevisionFileComment(revisionFileComment).then(data => {
          console.log('EditFileReviewComponent.approveFile: Revision file comment added', data);
          this.revisionFileComments.unshift(data);
        });
      }
    });
  }

  public on2DSelectViewChanged(event) {
    console.log('ViewFilesComponent.on2DSelectViewChanged : View selection changed', event);
    this.selectedViewIndex = event.value;
    const view = this.views2d[this.selectedViewIndex];
    console.log('ViewFilesComponent.on2DSelectViewChanged : Selected view', view);
    this.viewer.loadDocumentNode(this.viewerDocument, view);
  }

  public on3DSelectViewChanged(event) {
    console.log('ViewFilesComponent.on3DSelectViewChanged : View selection changed', event);
    this.selectedViewIndex = event.value;
    const view = this.views3d[this.selectedViewIndex];
    console.log('ViewFilesComponent.on3DSelectViewChanged : Selected view', view);
    this.viewer.loadDocumentNode(this.viewerDocument, view);
  }

  private async getRevisionFile(): Promise<void> {
    await this.revisionFilesServices.getRevisionFile(this.revisionFileId).then((data: RevisionFile) => {
      console.log('EditFileReviewComponent.getRevisionFile: RevisionFile', data);
      this.selectedRevisionFile = data;
    });

    await this.usersServices.get(this.selectedRevisionFile.ownerId).then(data => {
      this.selectedRevisionFile.owner = `${data.firstname} ${data.lastname}`;
    });
  }

  private async getRevisionFileComments(): Promise<void> {
    await this.revisionFileCommentsServices.getRevisionFileComments(this.selectedRevisionFile).then((data: Array<RevisionFileComment>) => {
      console.log('EditFileReviewComponent.getRevisionFileComments: RevisionFileComments', data);
      this.revisionFileComments = new List(data).OrderByDescending(r => r.creation).ToArray();
    });

    for (let i = 0; i < this.revisionFileComments.length; i++) { 
      const revisionFileComment = this.revisionFileComments[i];
      await this.usersServices.get(revisionFileComment.ownerId).then(data => {
        revisionFileComment.owner = `${data.firstname} ${data.lastname}`;
        revisionFileComment.ownerData = data;
      });
    }
  }

  private async initializeViewer() {
    console.log('EditFileReviewComponent.initializeViewer : Start to generate token');
    
    const initializerOptions: Autodesk.Viewing.InitializerOptions = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',
      getAccessToken: function(onTokenReady) {
        this.autoDeskServices.getToken().then((data: string) => {
          console.log('EditFileReviewComponent.initializeViewer : getAccessToken - Token generated', data);
          onTokenReady(data, 3600 * 30);
        });
      }.bind(this)
    };

    const viewerOptions: ViewerOptions = {
      initializerOptions: initializerOptions,
      onViewerInitialized: function (args: ViewerInitializedEvent): void {
        console.log('EditFileReviewComponent.initializeViewer : OnViewerInitialized', args);
      }
    };
    
    Autodesk.Viewing.Initializer(viewerOptions.initializerOptions, () => {
      const forgeViewer = document.getElementById('forgeViewer');
      this.viewer = new Autodesk.Viewing.GuiViewer3D(forgeViewer);
      this.viewer.start();
      this.viewer.loadExtension('Autodesk.PDF').then(() => {
        console.log('EditFileReviewComponent.initializeViewer : Extension pdf loaded');
      });
      this.loadDocument();
    });
    
  }

  private loadDocument() {
    Autodesk.Viewing.Document.load(`urn:${this.autoDeskServices.base64UrlEncode(this.selectedRevisionFile.decodedUrn)}`, (document) => {
      const defaultModel = document.getRoot().getDefaultGeometry();
      console.log('EditFileReviewComponent.loadDocument : Default model', defaultModel);
      this.defaultViewName = defaultModel.data.name;
      this.viewer.loadDocumentNode(document, defaultModel);
      this.setViews(document);
    }, (error) => {console.error('EditFileReviewComponent.loadDocument : Failed', error);});
  }

  private setViews(viewerDocument) {
    this.viewerDocument = viewerDocument;
    const documentRoot = this.viewerDocument.getRoot();
    this.views = documentRoot.search({type:'geometry'});
    const documents3d = documentRoot.search({type:'geometry', role: '3d'});
    this.views3d = documents3d;
    const documents2d = documentRoot.search({type:'geometry', role: '2d'});
    this.views2d = documents2d;
  }

}
