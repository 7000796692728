import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';

import { SuppliersRoutingModule } from './suppliers.routing.module';

import { SuppliersComponent } from './list/suppliers.component';
import { AddSupplierComponent } from './add/add.supplier.component';

@NgModule({
  declarations: [
    SuppliersComponent,
    AddSupplierComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),

    MaterialModule,
    SharedModule,

    SuppliersRoutingModule,
  ],
  providers: [],
  bootstrap: []
})
export class SuppliersModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
