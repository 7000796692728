<h1 mat-dialog-title>{{ 'modal.deleteentity.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <mat-label>{{ 'modal.deleteentity.confirm' | translate }}</mat-label>
  </div>
  <div class="row">
    <mat-label>{{ entityName }}</mat-label>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="entityName" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>