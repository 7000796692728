import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { FilesServices } from '../../../services/files/files.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { ProjectFile } from '../../../models/entities/files/file.model';
import { Project } from '../../../models/entities/projects/project.model';
import { Group } from 'src/app/models/entities/users/group.model';
import { ProjectGroupsServices } from 'src/app/services/projects/project.groups.services';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { SlotsServices } from 'src/app/services/projects/slots.services';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent {

  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;

  public isEditing: boolean = false;
  public files: Array<ProjectFile> = new Array<ProjectFile>();
  public selectedProject: Project = null;
  public groups: Array<Group> = new Array<Group>();

  public slots: Array<Slot> = new Array<Slot>();
  public slotPageIndex: number = 1;
  public slotPageSize: number = 25;
  public slotTotal: number = 0;
  public displayedSlotsColumns: string[] = ['name', 'description'];
  public slotsDataSource = new MatTableDataSource<Slot>(this.slots);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    public dataAccessServices: DataAccessServices,
    private projectGroupsServices: ProjectGroupsServices,
    private groupsServices: GroupsServices,
    private projectsServices: ProjectsServices,
    private filesServices: FilesServices,
    private slotsServices: SlotsServices) {

    this.activatedRoute.params.subscribe(params => {
      console.log('ProjectComponent.constructor : Retrieved params', params);
      let id = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }
      this.getProjectFiles();
      this.getProjectGroups();
      this.getSlots();
    });
  }

  public async ngAfterViewInit() {

  }

  public getStreamedImage(project: Project): SafeUrl {
    if (project === null || project === undefined || project.image === undefined || project.image === null) {
      return this.sanitizer.bypassSecurityTrustUrl('assets/images/empty-picture.jpg');
    } else {
      let objectURL = project.image;
      return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }

  public onImageDropped(files: any): void {
    let file = files[0];
    console.log('ProjectComponent.onImageDropped : Image dropped ', file);
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log('ProjectComponent.onImageDropped : Image load ', reader.result);
      this.selectedProject.image = reader.result as string;
    }
    reader.readAsDataURL(file);
  }

  public fileBrowseHandler(event: any) {
    console.log("ProjectComponent.fileBrowseHandler: ", event);
    this.onImageDropped(event.target.files);
  }

  public onClicked(event: any): void {
    console.log("ProjectComponent.onClicked: ", event);
  }

  public addProjectStatus(): void {
    this.router.navigate(['/addprojectstatus']);
  }

  public editProject(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing === true) {
      this.selectedProject.projectStatus = new List(this.dataAccessServices.projectStatus).FirstOrDefault(ps => ps._id == this.selectedProject.projectStatus._id);
    }
  }

  public updateProject(): void {
    this.projectsServices.updateProject(this.selectedProject).then((project: Project) => {
      console.log('ProjectComponent.updateProject: Project updated');
      this.isEditing = !this.isEditing;
    });
  }

  public deleteProject(): void {
    this.projectsServices.deleteProject(this.selectedProject).then((result: boolean) => {
      console.log('ProjectComponent.deleteProject: Project deleted', result);
      this.router.navigate(['/projects']);
    });
  }

  private getProjectFiles(): void {
    this.filesServices.getFiles(this.selectedProject).then((files: Array<ProjectFile>) => {
      console.log('ProjectComponent.getProjectFiles: Files', files);
      this.files = files;
    });
  }

  private async getProjectGroups() {
    let projectGroups: Array<ProjectGroup> = new Array<ProjectGroup>();
    
    await this.projectGroupsServices.getByProject(this.selectedProject).then((data: Array<ProjectGroup>) => {
      console.log('ProjectComponent.getProjectGroups: Project groups', data);
      projectGroups = data;
    });

    for(let i = 0; i < projectGroups.length; i++) {
      const projectGroup = projectGroups[i];
      await this.groupsServices.getGroup(projectGroup.groupId).then((data: Group) => {
        this.groups.push(data);
      });
    }
  }

  private async getSlots() {
    await this.slotsServices.getSlots(this.selectedProject).then(data => {
      console.log('ProjectComponent.constructor : Slots', data);
      this.slots = data;
      this.slotsDataSource = new MatTableDataSource<Slot>(this.slots);
    });
  }
}
