import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';

import { SettingsRoutingModule } from './settings.routing.module';

import { GroupsComponent } from './groups/list/groups.component';
import { AddGroupComponent } from './groups/add/add.group.component';
import { GroupComponent } from './groups/details/group.component';
import { AddUserComponent } from './users/add/add.user.component';
import { DocumentsComponent } from './documents/documents.component';
import { AnnotationsComponent } from './annotations/list/annotations.component';
import { AddAnnotationTypeComponent } from './annotations/add/add.annotation.type.component';
import { AnnotationTypeComponent } from './annotations/details/annotation.type.component';
import { BoxesComponent } from './iot/list/boxes.component';
import { BoxComponent } from './iot/details/box.component';
import { ScoringsComponent } from './scorings/scorings.component';

@NgModule({
  declarations: [
    GroupsComponent,
    AddGroupComponent,
    GroupComponent,
    AddUserComponent,
    DocumentsComponent,
    AnnotationsComponent,
    AddAnnotationTypeComponent,
    AnnotationTypeComponent,
    BoxesComponent,
    BoxComponent,
    ScoringsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),

    MaterialModule,
    SharedModule,
    NgxChartsModule,

    SettingsRoutingModule,
  ],
  providers: [],
  bootstrap: []
})
export class SettingsModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
