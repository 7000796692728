import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { ProjectsComponent } from './list/projects.component';
import { ProjectComponent } from './details/project.component';
import { AddProjectComponent } from './add/add.project.component';
import { AddSlotComponent } from './add/add.slot.component';

const routes: Routes = [
  { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid', component: ProjectComponent, canActivate: [AuthGuardServices] },
  { path: 'addproject', component: AddProjectComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/slots/add', component: AddSlotComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
