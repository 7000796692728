import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { environment } from 'src/environments/environment';

import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { Observable, finalize, from } from 'rxjs';
import { UsersServices } from '../users/users.services';
import { DataAccessServices } from '../data.access.services';

@Injectable({
  providedIn: 'root'
})
export class GoogleMessagingServices {

  @Output() messageReceived: EventEmitter<any> = new EventEmitter();

  private token: string = null;

  public currentMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private angularFireMessaging: AngularFireMessaging,
    private dataAccessServices: DataAccessServices,
    private usersServices: UsersServices) {
    }

    public requestPermission() {
      console.log('GoogleMessagingServices.requestPermission : Start to require permission for messaging');
      this.angularFireMessaging.requestToken.subscribe(async (token) => {
        console.log(`GoogleMessagingServices.requestPermission : Token received ${token}`);
        const userSubscription = await this.usersServices.userSubscribed(this.dataAccessServices.currentUser);
        console.debug(`GoogleMessagingServices.requestPermission : User subscription ${JSON.stringify(userSubscription)}`);
        if(userSubscription == null) {
          console.log(`GoogleMessagingServices.requestPermission : Subscribe user`);
          this.usersServices.subscribeUser(this.dataAccessServices.currentUser, token);
        } else {
          console.log(`GoogleMessagingServices.requestPermission : User already subscribed`);
          this.usersServices.updateSubscription(userSubscription._id, token);
        }
      });
    }
    
    public receiveMessages() {
      console.log('GoogleMessagingServices.receiveMessages : Start to subscribe for receive messages');
      this.angularFireMessaging.messages.subscribe((payload) => {
        console.log(`GoogleMessagingServices.receiveMessages : Message received ${payload}`);
        this.currentMessage.next(payload);
        this.messageReceived.emit(payload);
      });
    }
    /*
    public googleAuth() {
      console.log('AuthenticationService.googleAuth : Start to use google auth');
      return this.authLogin(new GoogleAuthProvider()).then((res: any) => {
        this.router.navigate(['/dashboard']);
      }).catch((error) => {
        console.error('AuthenticationService.googleAuth : Failed', error);
      });
    }
    
    public async requestPermission() : Promise<void> {
      console.log(`GoogleMessagingServices.requestPermission: Start to get token`);
      
      const formData = new HttpParams()
          .set('grant_type', 'authorization_code')
          .set('code', '4/0AeaYSHBiWCFab4jA9ywuDx9UeUHhlvOf5wqkri6Bx5mlTRPI4KkdcBflQSj_3hwbgiWlxg') 
          .set('client_id', '148533469075-p2htmgnf0uuk3lp3njvpc34m22dug7ip.apps.googleusercontent.com')
          .set('client_secret', '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')
          .set('redirect_uri', 'http://localhost:4200');

          const headers = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded');

          return new Promise((resolve, reject) => {
            this.httpClient.post<any>('https://oauth2.googleapis.com/token', formData.toString(), { headers }).toPromise()
            .then(data => {
              console.log('GoogleMessagingServices.sendMessage: Notification sent', data);
              resolve(data);
            }).catch(error => {
              console.error('GoogleMessagingServices.sendMessage: Error sending notification', error);
              reject(error)
            });
          });
    }

    public async receiveMessages() : Promise<void> {
      this.angularFireMessaging.messages.subscribe(
      (payload) => {
      console.log('GoogleMessagingServices.receiveMessage : New message received. ', payload);
      this.messageReceived.emit(payload);
      this.currentMessage.next(payload);
      })
    }
    */
    public async sendMessage(/*title: string, body: string, userId: string*/) : Promise<void> {
      console.log('GoogleMessagingServices.sendMessage: Start to send message');

      if(this.token !== undefined && this.token !== null) {
        const notification: any = {
          message: {
            topic: "alarms",
            notification: { title: "Door open", body: "Appartment 211, door 1132" },
          },
          data: { project_id: 21, object_id: 1258, sensor_id: 126985 }
        };
        
        await this.httpClient.post<any>('https://fcm.googleapis.com/v1/projects/bimosoft-2f86c/messages:send', notification, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }).toPromise().then(data => {
          console.log('GoogleMessagingServices.sendMessage: Notification sent', data);
        });
      }
    }
}
