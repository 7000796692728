import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from '../../models/entities/files/file.model';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';
import { AnnotationPicture } from 'src/app/models/entities/annotations/annotation.pictures.model';

@Injectable({
  providedIn: 'root'
})
export class AnnotationPicturesServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getAnnotationPictures(annotation: Annotation): Promise<Array<AnnotationPicture>> {
    console.log('AnnotationPicturesServices.getAnnotationPictures: Start to get annotation pictures', annotation);

    return new Promise<Array<AnnotationPicture>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationPicturesServices.getAnnotationPictures : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<AnnotationPicture[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-pictures`, filter: { 'annotationId': annotation._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationPicturesServices.getAnnotationPictures : Annotation pictures', data);
        resolve(data.documents);
      });
    });
  }

  public async addAnnotationPicture(annotationPicture: AnnotationPicture): Promise<AnnotationPicture> {
    console.log('AnnotationPicturesServices.addAnnotationPicture: Start to add annotation picture', annotationPicture);

    return new Promise<AnnotationPicture>(async (resolve, reject) => {

      const annotationPictureData = { 
        annotationId: annotationPicture.annotationId, 
        data: annotationPicture.data, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationPicturesServices.addAnnotationPicture : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<AnnotationPicture>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-pictures`, document: annotationPictureData }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationPicturesServices.addAnnotationPicture : Annotation picture added', data);
        annotationPicture._id = data.insertedId;
        resolve(annotationPicture);
      });

    });
  }
}
