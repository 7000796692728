<h1 mat-dialog-title>{{ 'modal.addannotation.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addannotation.annotationtype' | translate }}</mat-label>
      <mat-select name="category" [(ngModel)]="annotation.annotationTypeId">
        <mat-option *ngFor="let annotationType of annotationTypes" [value]="annotationType._id">{{ annotationType.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addannotation.campaign' | translate }}</mat-label>
      <mat-select name="category" [(ngModel)]="annotation.campaignId">
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign._id">{{ campaign.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addannotation.subject' | translate }}</mat-label>
      <input matInput [(ngModel)]="annotation.subject">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addannotation.comment' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="annotation.comment"></textarea>
    </mat-form-field>
  </div>
  <div class="row">
    <button mat-button (click)="onNoClick()">{{ 'modal.addannotation.takeasnapshot' | translate }}</button>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="annotation.subject" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>