import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { ScoringsServices } from 'src/app/services/suppliers/scorings.services';
import { Scoring } from 'src/app/models/entities/suppliers/scoring.model';
import { SuppliersScoringsServices } from 'src/app/services/suppliers/supplier.scorings.services';
import { SupplierScoring } from 'src/app/models/entities/suppliers/supplier.scoring.model';

@Component({
  selector: 'app-suppliers-scoring',
  templateUrl: './suppliers-scoring.component.html'
})
export class SuppliersScoringComponent {

  public selectedProject: Project = null;
  public suppliers: Array<Supplier> = new Array<Supplier>();
  public panelOpenState = false;
  public searchedSuppliers: Supplier = new Supplier();
  public scorings: Array<Scoring> = new Array<Scoring>();
  public displayedColumns: string[] = ['scoring', 'description', 'score', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Supplier>(this.suppliers);
  public supplierScoring: SupplierScoring = new SupplierScoring();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private scoringsServices: ScoringsServices,
    private suppliersScoringsServices: SuppliersScoringsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('SuppliersScoringComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  public async ngOnInit() {
    await this.suppliersServices.getSuppliers(this.selectedProject).then(data => {
      console.log('SuppliersScoringComponent.ngOnInit : Supliers', data);
      this.suppliers = data;
      this.dataSource = new MatTableDataSource<Supplier>(this.suppliers);
    });

    await this.scoringsServices.getScorings(this.selectedProject).then(data => {
      console.log('SuppliersScoringComponent.ngOnInit : Scorings', data);
      this.scorings = data;
    });

    this.suppliers.forEach(async supplier => {
      this.getSupplierScoring(supplier);
    });
  }

  public getDataSource(supplierScoring: Array<SupplierScoring>) {
    return new MatTableDataSource<SupplierScoring>(supplierScoring);
  }

  public async addSupplierScoring() {
    await this.suppliersScoringsServices.addSupplierScoring(this.supplierScoring).then(data => {
      console.log('SuppliersScoringComponent.addSupplierScoring : Supplier scoring', data);
      this.supplierScoring = new SupplierScoring();
    });

    this.suppliers.forEach(async supplier => {
      this.getSupplierScoring(supplier);
    });
  }

  public async deleteSupplierScoring(supplierScoring: SupplierScoring) {
    console.log('SuppliersScoringComponent.deleteSupplierScoring : Supplier scoring', supplierScoring);
    await this.suppliersScoringsServices.deleteSupplierScoring(supplierScoring).then(data => {
      console.log('SuppliersScoringComponent.deleteSupplierScoring : Supplier scoring deleted', data);
    });

    this.suppliers.forEach(async supplier => {
      this.getSupplierScoring(supplier);
    });
  }

  private async getSupplierScoring(supplier: Supplier) {
    await this.suppliersScoringsServices.getSupplierScorings(supplier).then(data => {
      console.log('SuppliersScoringComponent.getSupplierScoring : Suppliers scorings', data);
      data.forEach(supplierScoring => {
        supplierScoring.scoring = new List(this.scorings).First(s => s._id == supplierScoring.scoringId);
      });
      supplier.supplierScorings = data;
    });
  }
}
