<div style="display: flex;">
  <div style="width: 10%;">
    <div #dates></div>
  </div>
  <div style="width: 90%;">
    <div #hours></div>
    <div #quarthours></div>
    <div #grid></div>
  </div>
</div>
