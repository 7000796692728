import { EntityBase } from "../entity.base.model";
import { SupplierScoring } from "./supplier.scoring.model";

export class Supplier extends EntityBase {
  projectId: string;
  licenceNumber: string;
  status: string;
  licenceType: string;
  deliveredLicenceDate: Date;
  restriction: string;
  restrictionStartDate: Date;
  restrictionEndDate: Date;
  insuranceCompany: string;
  insuranceAmount: string;
  annualPaymentDate: Date;
  email: string;
  address: string;
  companyDigitalIdentifier: string;
  name: string;
  phoneNumber: string;
  city: string;
  legalStatus: string;
  administrativeRegionCode: string;
  administrativeRegion: string;

  supplierScorings: Array<SupplierScoring>;
}
