import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Project } from '../../models/entities/projects/project.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getProjects(): Promise<Array<Project>> {
    console.log('ProjectsServices.getProjects: Start to get projects');
      
    return new Promise<Array<Project>>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectsServices.getProjects : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Project[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `projects` }, httpOptions).toPromise().then((data: any) => {
        console.log('ProjectsServices.getProjects : Projects', data);
        resolve(data.documents);
      });
    });
  }

  public async getProject(projectId: string): Promise<Project> {
    console.log('ProjectsServices.getProjects: Start to get projects');
      
    return new Promise<Project>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectsServices.getProject : Token', data);
          token = data;
        });

        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Project>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `projects`, filter: { '_id': { $eq: { $oid: projectId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('ProjectsServices.getProject : Project', data);
        resolve(data.document);
      });
    });
  }

  public async addProject(project: Project): Promise<Project> {
    console.log('ProjectsServices.addProject: Start to add project', project);

    const projectData = { 
      address: project.address, 
      description: project.description, 
      image: project.image, 
      latitude: project.latitude,
      longitude: project.longitude,
      name: project.name,
      phase: project.phase,
      projectStatusId: project.projectStatus._id,
      modification: new Date(),
      creation: new Date() 
    };

    return new Promise<Project>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ProjectsServices.addProject : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Project>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `projects`, document: projectData }, httpOptions).toPromise().then((data: any) => {
        console.log('PaymentsServices.addPayment : Project added', data);
        project._id = data.insertedId;
        resolve(project);
      });
    });

  }

  public async updateProject(project: Project): Promise<Project> {
    console.log('ProjectsServices.updateProject: Start to update project', project);

    return new Promise<Project>(async (resolve, reject) => {
      
    });

  }

  public async deleteProject(project: Project): Promise<boolean> {
    console.log('ProjectsServices.deleteProject: Start to add project', project);

    return new Promise<boolean>((resolve, reject) => {
      
    });

  }
}
