<div class="mt-3">

  <div class="row">

    <div class="col-md-11">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'generic.add' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <mat-form-field>
              <input matInput type="text" name="scoringTitle" placeholder="{{ 'scoring.title' | translate }}" [(ngModel)]="scoring.title">
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field>
              <input matInput type="text" name="scoringWeighting" placeholder="{{ 'scoring.weighting' | translate }}" [(ngModel)]="scoring.weighting">
            </mat-form-field>
          </div>

          <div class="row form-group d-flex justify-content-center">
            <button mat-raised-button color="primary" class="btn-block" (click)="addScoring()">{{ 'generic.add' | translate }}</button>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
  
</div>

<div class="mt-3">

  <div class="col-md-4 offset-md-5">
    <mat-spinner *ngIf="this.scorings.length === 0"></mat-spinner>
  </div>
  
  <table mat-table [dataSource]="dataSource" *ngIf="this.dataAccessServices.projects.length > 0">

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> {{ 'scoring.title' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
    </ng-container>

    <ng-container matColumnDef="weighting">
      <th mat-header-cell *matHeaderCellDef> {{ 'scoring.weighting' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.weighting }} </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteScoring(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
                 *ngIf="this.scorings.length > 0"
                 [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</div>
