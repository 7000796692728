import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { QuotesServices } from 'src/app/services/quotes/quotes.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { Campaign } from 'src/app/models/entities/campaigns/campaign.model';
import { CampaignsServices } from 'src/app/services/campaigns/campaigns.services';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html'
})
export class CampaignsComponent {

  public selectedProject: Project = null;
  public campaigns: Array<Campaign> = new Array<Campaign>();
  public displayedColumns: string[] = ['name', 'color', 'startDate', 'endDate', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Campaign>(this.campaigns);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private campaignsServices: CampaignsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('CampaignsComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  public async ngOnInit() {
    await this.campaignsServices.getCampaigns(this.selectedProject).then(data => {
      console.log('CampaignsComponent.ngOnInit : Campaigns', data);
      this.campaigns = data;
    });

    this.dataSource = new MatTableDataSource<Campaign>(this.campaigns);
  }

  public deleteCampaign(campaign: Campaign): void {

  }
}
