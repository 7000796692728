<div class="row form-group">
  <div class="col-md-5" *ngIf="settingsServices.isCellPhone === false">
    <div class="login-logo">
      <img src="assets/images/logo.jpeg" alt="logo" class="login-logo-image" />
    </div>
  </div>
  <div *ngIf="settingsServices.isCellPhone === true">
    <div class="login-logo-cell-phone">
      <img src="assets/images/logo.jpeg" alt="logo" style="height: 50%;width: 100%" class="login-logo-image-cell-phone" />
    </div>
  </div>
  <div [ngClass]="{'col-md-12': settingsServices.isCellPhone, 'col-md-7': !settingsServices.isCellPhone}">
    <div class="login-form">
      <mat-spinner diameter="100" *ngIf="isAuthenticating"></mat-spinner>
      <form *ngIf="!isAuthenticating">
        <div class="row form-group">
          <mat-form-field>
            <mat-label>{{ 'login.email' | translate }}</mat-label>
            <input matInput name="email" placeholder="yves.durand@gmail.com" [(ngModel)]="email">
          </mat-form-field>
        </div>

        <div class="row form-group">
          <mat-form-field>
            <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password">
            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="row form-group d-flex justify-content-center">
          <button mat-raised-button color="primary" class="btn-block" (click)="authenticate()">{{ 'generic.validate' | translate }}</button>
        </div>
        <!--
        <div class="row form-group d-flex justify-content-center">
          <button mat-raised-button color="primary" class="btn-block" (click)="googleAuthentication()">{{ 'login.googleAuthentication' | translate }}</button>
        </div>
-->
        <div class="mt-5" style="display: flex; align-items: center;">
          <mat-icon>arrow_back</mat-icon> 
          <a href="https://sg-bim.com" style="padding-left:20px;">{{ 'login.backtowebom' | translate }}</a>
        </div>
      </form>
    </div>
  </div>
</div>

