import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import jwt_decode from "jwt-decode";

import { LocalStorageServices } from './local.storage.services';

@Injectable({
  providedIn: 'root'
})
export class RealmTokenServices {

  private token: string = null;

  constructor(
    private httpClient: HttpClient,
    private localStorageServices: LocalStorageServices
  ) { }

  public async getToken(): Promise<string> {
    console.log('RealmTokenServices.getToken : Start to get token');
    
    let cachedToken = this.localStorageServices.get('realm_token');
    if(cachedToken !== undefined && cachedToken !== null) {
      this.token = cachedToken;
    }

    return new Promise<string>(async (resolve, reject) => {
      if(this.token === null || this.token === undefined) {
        console.debug('RealmTokenServices.getToken : The token is not initialized');
        await this.generateToken().then(data => {
          console.log('RealmTokenServices.getToken : The generated token', data);
          this.token = data;
          this.localStorageServices.set('realm_token', data);
          resolve(this.token);
        }).catch(error => reject(error));
      } else {
        console.debug('RealmTokenServices.getToken : The token is already initialized');
        const decodedToken: any = jwt_decode(this.token);
        console.debug('RealmTokenServices.getToken : The decoded token', decodedToken);
        if(decodedToken) {
          const expirationTimestamp = decodedToken.exp;
          const expirationDate = new Date(expirationTimestamp * 1000);
          const currentDate = new Date();
          if (currentDate < expirationDate) {
            console.log('RealmTokenServices.getToken : The token is valid');
            resolve(this.token);
          } else {
            console.log('RealmTokenServices.getToken : The token is expired');
            this.localStorageServices.remove('autodesk_token');
            await this.generateToken().then(data => {
              console.log('RealmTokenServices.getToken : The generated token', data);
              this.token = data;
              this.localStorageServices.set('realm_token', data);
              resolve(this.token);
            });
          }
        }
      }
    });
  }

  private async generateToken(): Promise<string> {
    console.log('RealmTokenServices.generateToken : Start to generate token');
    return new Promise<string>(async (resolve, reject) => {
      const authenticationUrl = `${environment.mongoapi.authenticationUrl}/${environment.mongoapi.appId}/auth/providers/api-key/login?Content-Type=application/json`;
        await this.httpClient.post(authenticationUrl, { key: environment.mongoapi.userApplicationKey }).toPromise().then((data: any) => {
          console.log('UsersServices.authenticate : Token', data);
          resolve(data.access_token);
        }).catch(error => {
          console.log('RealmTokenServices.getToken : Failed', error);
          reject(500);
        });
    });
  }
}
