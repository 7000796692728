import { EntityBase } from "../entity.base.model";
import { ContractStatus } from "./contract.status.enum";

export class Contract extends EntityBase {
  supplierId: string;
  quoteId: string;
  originalTitle: string;
  documentTitle: string;
  name: string;
  comment: string;
  downloadURL: string;
  ownerId: string;
  status: ContractStatus;
  amount: number;

  quote: string;
  supplier: string;
  owner: string;
  progressUpload: number;
  data: File;
}
