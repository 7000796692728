import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { Group } from '../../models/entities/users/group.model';
import { UserGroup } from '../../models/entities/users/user.group.model';
import { User } from '../../models/entities/users/user.model';
import { RealmTokenServices } from '../commons/realm.token.services';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getByGroup(groupId: string): Promise<Array<UserGroup>> {
    console.log('UserGroupsServices.getByGroup: Start to get UserGroup', groupId);

    return new Promise<Array<UserGroup>>(async (resolve, reject) => {
      let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UserGroupsServices.getByGroup : Token', data);
          token = data;
        });
  
        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Array<UserGroup>>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-groups`, filter: { 'groupId': { $eq: groupId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('UserGroupsServices.getByGroup : User groups', data);
          resolve(data.documents);
        });
    });
  }

  public async getByUser(userId: string): Promise<Array<UserGroup>> {
    console.log('UserGroupsServices.getByUser: Start to get UserGroups', userId);

    return new Promise<Array<UserGroup>>(async (resolve, reject) => {

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('UserGroupsServices.getByUser : Token', data);
        token = data;
      });
  
      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Array<UserGroup>>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-groups`, filter: { 'userId': { $eq: userId } } }, httpOptions).toPromise().then((data: any) => {
        console.log('UserGroupsServices.getByUser : User groups', data);
        resolve(data.documents);
      });
    });
  }

  public async getUserGroup(userGroupId: number): Promise<UserGroup> {
    console.log('UserGroupsServices.getUserGroup: Start to get UserGroup', userGroupId);

    return new Promise<UserGroup>((resolve, reject) => {
      
    });
  }

  public async addUserGroup(userGroup: UserGroup): Promise<UserGroup> {
    console.log('UserGroupsServices.addUserGroup: Start to add UserGroup', userGroup);

    return new Promise<UserGroup>(async (resolve, reject) => {
      const userGroupData = { 
        groupId: userGroup.groupId, 
        userId: userGroup.userId, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('UserGroupsServices.addUserGroup : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<UserGroup>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-groups`, document: userGroupData }, httpOptions).toPromise().then((data: any) => {
        console.log('UserGroupsServices.addUserGroup : Group added', data);
        userGroup._id = data.insertedId;
        resolve(userGroup);
      });
    });

  }

  public async updateUserGroup(userGroup: UserGroup): Promise<UserGroup> {
    console.log('UserGroupsServices.updateUserGroup: Start to update UserGroup', userGroup);

    return new Promise<UserGroup>((resolve, reject) => {
      
    });

  }

  public async deleteUserGroup(userGroup: UserGroup): Promise<boolean> {
    console.log('UserGroupsServices.deleteUserGroup: Start to user group', userGroup);

    return new Promise<boolean>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('UserGroupsServices.deleteUserGroup : Token', data);
        token = data;
      });

      const deleteUrl = `${environment.mongoapi.apiDeleteOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<boolean>(deleteUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `user-groups`, filter: { '_id': { $oid: userGroup._id } } }, httpOptions).toPromise().then((data: any) => {
        console.log('UserGroupsServices.deleteUserGroup : User Group deleted', data);
        if(data.deletedCount > 0) {
          resolve(true);
         } else {
          reject(data);
        }
      });
    });

  }

}
