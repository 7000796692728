import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { FilesServices } from '../../../services/files/files.services';
import { RevisionFilesServices } from '../../../services/files/revision.files.services';
import { AutoDeskServices } from 'src/app/services/autodesk/autodesk.services';
import { TranslateService } from '@ngx-translate/core';

import { ProjectFile } from '../../../models/entities/files/file.model';
import { Project } from '../../../models/entities/projects/project.model';
import { RevisionFile } from '../../../models/entities/files/revision.file.model';
import { NomenclatureCodification } from '../../../models/entities/projects/nomenclature.codification.model';
import { UsersServices } from 'src/app/services/users/users.services';
import { User } from 'src/app/models/entities/users/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { S3MultipartUpload } from 'src/app/models/contracts/s3.multipart.upload';
import { AutoDeskTask } from 'src/app/models/entities/autodesk/autodesk.task.model';
import { AutodeskTranslateJob } from 'src/app/models/contracts/autodesk.translate.job.contract';
import { S3UploadCompleted } from 'src/app/models/contracts/s3.upload.completed.contract';
import { RevisionFileStatus } from 'src/app/models/entities/files/revision.file.status.enum';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit {

  public selectedProject: Project;
  public isEditing: boolean;
  public documentsCount: number = 0;
  public revisionFiles: Array<RevisionFile> = new Array<RevisionFile>();
  public selectedProjectFile: ProjectFile = new ProjectFile();
  public currentRevisionFile: RevisionFile;
  public displayedColumns: string[] = ['name', 'version', 'ownerId', 'status', 'edit', 'autodesk', 'view', 'delete'];
  public dataSource = new MatTableDataSource<RevisionFile>(this.revisionFiles);
  public uploadingFiles: boolean = false;
  public version: string;
  public comment: string;

  private fileId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    public dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private autoDeskServices: AutoDeskServices,
    private filesServices: FilesServices,
    private revisionFilesServices: RevisionFilesServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('ProjectComponent.constructor : Retrieved params', params);
      let id = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }
      this.fileId = params['fileid'];
    });
  }

  public async ngOnInit() {
    await this.filesServices.getFile(this.fileId).then((file: ProjectFile) => {
      this.selectedProjectFile = file;
    });

    await this.revisionFilesServices.getRevisionFiles(this.selectedProjectFile).then((data: Array<RevisionFile>) => {
      this.revisionFiles = new List(data).OrderByDescending(r => r.creation).ToArray();
      this.dataSource = new MatTableDataSource<RevisionFile>(this.revisionFiles);
      this.version = `${this.revisionFiles.length + 1}`;
    });

    for(var i = 0; i < this.revisionFiles.length; i++) {
      const revisionFile = this.revisionFiles[i];
      await this.usersServices.get(revisionFile.ownerId).then((data: User) => {
        revisionFile.owner = `${data.firstname} ${data.lastname}`;
      });
    }
  }

  public editRevisionFile(revisionFile: RevisionFile) {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'edit', revisionFile._id]);
  }
  
  public async uploadFiles(files: any[]): Promise<void> {
    this.uploadingFiles = true;

    for (let i = 0; i < files.length; i++) {
      const dataFile = files[i];
      console.log('FileComponent.uploadFiles : ', dataFile);

      this.currentRevisionFile = new RevisionFile();
      this.currentRevisionFile.version = this.version;
      this.currentRevisionFile.comment = this.comment;

      //Add revision file
      await this.revisionFilesServices.uploadFileProgressEvent.subscribe((progress: number) => {
        console.log('FileComponent.uploadFiles : progress received', progress);
        this.currentRevisionFile.progressUpload = progress;
      });

      this.currentRevisionFile.status = RevisionFileStatus.proofread;
      this.currentRevisionFile.data = dataFile;
      this.currentRevisionFile.projectFileId = this.selectedProjectFile._id;
      this.currentRevisionFile.ownerId = this.dataAccessServices.currentUser._id;

      await this.revisionFilesServices.uploadRevisionFile(this.selectedProject, this.selectedProjectFile, this.currentRevisionFile).then((data: RevisionFile) => {
        console.log('FileComponent.uploadFiles : Upload file finished', data);
        this.currentRevisionFile = data;
      });

      await this.revisionFilesServices.getDownloadUrl(this.selectedProject, this.currentRevisionFile).then((data: RevisionFile) => {
        console.log('FileComponent.uploadFiles : Get download url finished', data);
        this.currentRevisionFile = data;
      });

      await this.revisionFilesServices.addRevisionFile(this.currentRevisionFile).then((data: RevisionFile) => {
        console.log('FileComponent.uploadFiles : RevisionFile added', data);
        this.currentRevisionFile = data;
        this.currentRevisionFile.owner = `${this.dataAccessServices.currentUser.firstname} ${this.dataAccessServices.currentUser.lastname}`
        this.revisionFiles.unshift(this.currentRevisionFile);
      });

      this.revisionFiles = new List(this.revisionFiles).OrderByDescending(r => r.creation).ToArray();
      this.dataSource = new MatTableDataSource<RevisionFile>(this.revisionFiles);
      this.version = `${this.revisionFiles.length + 1}`;
    }

    this.uploadingFiles = false;
  }

  public async computeFile(revisionFile: RevisionFile): Promise<void> {

    revisionFile.isAutoDeskComputing = true;

    if(revisionFile.data === null || revisionFile.data === undefined) {
      await this.revisionFilesServices.loadDataFile(revisionFile).then(data => {
        console.log('FileComponent.computeFile : Data file loaded', data);
      });
    }

    // Check if bucket exist
    let isBucketExists = false;
    await this.autoDeskServices.checkBucketExists(this.selectedProject._id).then((data: any) => {
      console.log('FileComponent.computeFile : Bucket exists', data);
      isBucketExists = data;
    });

    // Create bucket if not exist
    if(isBucketExists === false) {
      await this.autoDeskServices.createBucket(this.selectedProject._id).then((data: any) => {
        console.log('FileComponent.computeFile : Bucket creation', data);
      });
    }

    // Initiate a direct to S3 multipart upload
    let s3MultipartUpload: S3MultipartUpload = null;
    await this.autoDeskServices.initiateS3MultipartUpload(this.selectedProject._id, revisionFile).then((data: any) => {
      console.log('FileComponent.computeFile : S3 multipart upload', data);
      s3MultipartUpload = data;
    });
  
    // Upload file on bucket
    revisionFile.progressAutoDesk = this.translateService.instant('viewfile.uploadfileonautodesk')
    await this.autoDeskServices.uploadRevisionFile(s3MultipartUpload, revisionFile).then((data: any) => {
      console.log('FileComponent.computeFile : Upload project file', data);
    });

    // Complete upload file 
    let s3UploadCompleted: S3UploadCompleted = null;
    await this.autoDeskServices.completeUploadRevisionFile(s3MultipartUpload, this.selectedProject, revisionFile).then((data: any) => {
      console.log('FileComponent.computeFile : Upload completed', data);
      s3UploadCompleted = data;
    });

    // Create translate job
    let autodeskTranslateJob: AutodeskTranslateJob = null;
    await this.autoDeskServices.translateRevisionFile(s3UploadCompleted).then((data: any) => {
      console.log('FileComponent.computeFile : Translate file', data);
      autodeskTranslateJob = data;
    });

    // Add autodesk task
    let autoDeskTask: AutoDeskTask = new AutoDeskTask();
    autoDeskTask.revisionFileId = revisionFile._id;
    autoDeskTask.progress = ""
    autoDeskTask.urn = autodeskTranslateJob.urn;
    autoDeskTask.status = autodeskTranslateJob.status;
    await this.autoDeskServices.addAutodeskTask(autoDeskTask).then((data: any) => {
      console.log('FileComponent.computeFile : AutoDesk task added', data);
      autoDeskTask = data;
    });
    
    const checkAutodeskTaskInterval = setInterval(async () => {
      await this.autoDeskServices.checkAutodeskTask(autoDeskTask).then(data => {
        console.log('FileComponent.viewFile : Check AutoDesk task', data);
        autoDeskTask = data;
        revisionFile.progressAutoDesk = autoDeskTask.progress;
        autoDeskTask.status = autoDeskTask.status;
        if(autoDeskTask.progress === "complete") {
          revisionFile.isAutoDeskComputing = false;
          revisionFile.urn = autoDeskTask.urn;
          clearInterval(checkAutodeskTaskInterval);
        }
      });
    }, 5000);

    console.log('FileComponent.viewFile : Update AutoDesk task', autoDeskTask);

    await this.autoDeskServices.updateAutodeskTask(autoDeskTask).then((data: any) => {
      console.log('FileComponent.computeFile : AutoDesk task updated', data);
      autoDeskTask = data;
    });

    console.log('FileComponent.computeFile : AutoDesk task', autoDeskTask);
    revisionFile.decodedUrn = s3UploadCompleted.objectId;

    await this.revisionFilesServices.updateRevisionFile(revisionFile).then(data => {
      console.log('FileComponent.computeFile : Project file updated', data);
      revisionFile = data;
    });
  }

  public viewFile(revisionFile: RevisionFile) {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'viewfile', revisionFile._id]);
  }

  public updateFile() {

  }

  public async deleteFile(revisionFile: RevisionFile) {

    // delete the revison file on database

    // remove the file on storage
  }
}
