<div class="mt-3">

  <div class="row">

    <div class="col-md-12">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'generic.add' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'supplierscoring.supplier' | translate }}</mat-label>
              <mat-select name="supplier" [(ngModel)]="supplierScoring.supplierId">
                <mat-option *ngFor="let supplier of suppliers" [value]="supplier._id">{{ supplier.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'supplierscoring.scoring' | translate }}</mat-label>
              <mat-select name="scoring" [(ngModel)]="supplierScoring.scoringId">
                <mat-option *ngFor="let scoring of scorings" [value]="scoring._id">{{ scoring.title }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>{{ 'supplierscoring.description' | translate }}</mat-label>
              <textarea matInput rows="8" [(ngModel)]="supplierScoring.description"></textarea>
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>{{ 'supplierscoring.score' | translate }}</mat-label>
              <input matInput type="number" name="projectName" [(ngModel)]="supplierScoring.score">
            </mat-form-field>
          </div>

          <div class="row form-group d-flex justify-content-center">
            <button mat-raised-button color="primary" class="btn-block" (click)="addSupplierScoring()">{{ 'generic.add' | translate }}</button>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
</div>

<div class="mt-3">

  <div class="col-md-4 offset-md-5">
    <mat-spinner *ngIf="this.scorings.length === 0"></mat-spinner>
  </div>
  
  <div *ngFor="let supplier of suppliers">
    <div class="row mt-3"><h3>{{ supplier.name }}</h3></div>
    <div class="row mt-3">
      <table mat-table [dataSource]="getDataSource(supplier.supplierScorings)" *ngIf="this.scorings.length > 0">

        <ng-container matColumnDef="scoring">
          <th mat-header-cell *matHeaderCellDef> {{ 'supplierscoring.scoring' | translate }} </th>
          <td mat-cell *matCellDef="let element"> <span [truncateText]="20">{{ element.scoring.title }}</span> </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> {{ 'supplierscoring.description' | translate }} </th>
          <td mat-cell *matCellDef="let element"> <span [truncateText]="20">{{ element.description }}</span> </td>
        </ng-container>
    
        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef> {{ 'supplierscoring.score' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.score }} </td>
        </ng-container>
    
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['supplierscoring', element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
        </ng-container>
    
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteSupplierScoring(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
