import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from 'src/app/services/data.access.services';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { AnnotationTypesServices } from 'src/app/services/annotations/annotation.types.services';
import { AnnotationTypeGroupsServices } from 'src/app/services/annotations/annotation.type.groups.services';

import { Group } from 'src/app/models/entities/users/group.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';
import { AnnotationTypeGroup } from 'src/app/models/entities/annotations/annotation.type.group.model';

@Component({
  selector: 'app-add-annotation-type',
  templateUrl: './add.annotation.type.component.html',
  styleUrls: ['./add.annotation.type.component.scss']
})
export class AddAnnotationTypeComponent {

  public selectedProject: Project = null;
  public annotationType: AnnotationType = new AnnotationType();
  public groups: Array<Group> = new Array<Group>();
  public groupPageIndex: number = 1;
  public groupPageSize: number = 25;
  public groupTotal: number = 0;
  public displayedGroupsColumns: string[] = ['name', 'associate'];
  public groupsDataSource = new MatTableDataSource<Group>(this.groups);
  public associatedGroups: Array<Group> = new Array<Group>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersServices,
    private dataAccessServices: DataAccessServices,
    private userGroupsServices: UserGroupsServices,
    private groupsServices: GroupsServices,
    private annotationTypesServices: AnnotationTypesServices,
    private annotationTypeGroupsServices: AnnotationTypeGroupsServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('AddAnnotationTypeComponent.constructor : Retrieved params', params);
      const projectid = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
      this.annotationType.projectId = this.selectedProject._id;
    });
  }

  async ngOnInit() {
    await this.groupsServices.getGroups(this.selectedProject).then(data => {
      console.log('AddAnnotationTypeComponent.ngOnInit : Groups', data);
      this.groups = data;
      this.groupsDataSource = new MatTableDataSource<Group>(this.groups);
    });
  } 
  
  public async addAnnotationType() {
    console.log('AddAnnotationTypeComponent.addAnnotationType : Start to add annotation type', this.annotationType);
    let annotationType = null;
    await this.annotationTypesServices.addAnnotationType(this.annotationType).then(data => {
      console.log('AddAnnotationTypeComponent.addAnnotationType : Annotation type added', data);
      annotationType = data;
    });

    for(let i = 0; i < this.associatedGroups.length; i++) {
      const group = this.associatedGroups[i]; 
      const annotationTypeGroup = new AnnotationTypeGroup(); 
      annotationTypeGroup.groupId = group._id;
      annotationTypeGroup.annotationTypeId = annotationType._id;
      await this.annotationTypeGroupsServices.addAnnotationTypeGroup(annotationTypeGroup).then(data => {
        console.log('AddAnnotationTypeComponent.addAnnotationType : Annotation type group added', data);
      });
    }

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'settingsannotations']);
  }

  public associateGroup(event, group: Group) {
    console.log('AddAnnotationTypeComponent.associateGroup : Start to associate', event, group);
    if(event.checked === true) {
      this.associatedGroups.push(group);
    } else {
      const index = this.associatedGroups.indexOf(group);
      this.associatedGroups.splice(index, 1);
    }
    console.log('AddAnnotationTypeComponent.associateGroup : Associated groups', this.associatedGroups);
  }
  
}
