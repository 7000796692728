<div class="row form-group">
  <div class="col-md-12">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title-group>
          <h3>{{ 'file.maininformations' | translate }}</h3>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <upload-files (documentsCountchanged)="documentsCount = $event" (uploadDocumentsClicked)="uploadFiles($event)" [uploadingFiles]="uploadingFiles"></upload-files>
        <div class="row form-group mt-3" *ngIf="documentsCount > 0">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'file.comment' | translate }}</mat-label>
            <input matInput name="fileComment" [(ngModel)]="comment" [disabled]="uploadingFiles === true">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'file.version' | translate }}</mat-label>
            <input matInput name="fileVersion" [(ngModel)]="version" [disabled]="uploadingFiles === true">
          </mat-form-field>
          <mat-progress-bar *ngIf="uploadingFiles == true" mode="determinate" value="{{ currentRevisionFile.progressUpload }}"></mat-progress-bar>
        </div>
        <div class="row mt-3">
          <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> {{ 'file.name' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>

            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef> {{ 'file.version' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.version }} </td>
            </ng-container>

            <ng-container matColumnDef="ownerId">
              <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'file.owner' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <span *ngIf="element.owner" style="display: inline-block; margin: 0 auto;">
                  <avatar mat-icon-button matTooltip="{{ element.owner }}" [isEditable]="false"
                  [size]="'30px'"
                  [name]="element.owner"
                  [imageSource]="this.dataAccessServices.currentUser.picture"></avatar>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> {{ 'file.status' | translate }} </th>
              <td mat-cell *matCellDef="let element">
                <span [ngSwitch]="element.status">
                  <span *ngSwitchCase="0" class="badge-warning text-center"> {{ 'file.statusenum.proofread' | translate }} </span>
                  <span *ngSwitchCase="1" class="badge-success text-center"> {{ 'file.statusenum.accepted' | translate }} </span>
                  <span *ngSwitchCase="2" class="badge-warning text-center"> {{ 'file.statusenum.changerequest' | translate }} </span>
                  <span *ngSwitchCase="3" class="badge-danger text-center"> {{ 'file.statusenum.denied' | translate }} </span>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" (click)="editRevisionFile(element)">
                  {{ 'generic.edit' | translate }}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="autodesk">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="basic" (click)="computeFile(element)" [disabled]="element.isAutoDeskComputing === true">
                  <span *ngIf="element.isAutoDeskComputing === false || element.isAutoDeskComputing === undefined">
                    {{ 'file.computeautodesk' | translate }}
                  </span>
                  <span *ngIf="element.isAutoDeskComputing === true">
                    {{ element.progressAutoDesk }}
                  </span>
                </button>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="basic" (click)="viewFile(element)" [disabled]="element.isAutoDeskComputing === true || element.decodedUrn === null || element.decodedUrn === undefined || element.decodedUrn === ''">
                  {{ 'file.fileview' | translate }}
                </button>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteFile(element)">{{ 'generic.delete' | translate }}</button> </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
