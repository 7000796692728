import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from '../../models/entities/files/file.model';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';

@Injectable({
  providedIn: 'root'
})
export class SuppliersServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getSuppliers(project: Project): Promise<Array<Supplier>> {
    console.log('SuppliersServices.getSuppliers: Start to get suppliers', project);

    return new Promise<Array<Supplier>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersServices.getSuppliers : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Supplier[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `suppliers`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersServices.getSuppliers : Annotations', data);
        resolve(data.documents);
      });
    });
  }

  public async getSupplier(supplierId: string): Promise<Supplier> {
    console.log('SuppliersServices.getSupplier: Start to get supplier', supplierId);
      
    return new Promise<Supplier>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('SuppliersServices.getSupplier : Token', data);
          token = data;
        });

        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Project>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `suppliers`, filter: { '_id': { $eq: { $oid: supplierId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersServices.getSupplier : Project', data);
        resolve(data.document);
      });
    });
  }

  public async addSupplier(supplier: Supplier): Promise<Supplier> {
    console.log('SuppliersServices.addSupplier: Start to add supplier', supplier);

    return new Promise<Supplier>(async (resolve, reject) => {

      const supplierData = { 
        projectId: supplier.projectId, 
        licenceNumber: supplier.licenceNumber, 
        status: supplier.status, 
        licenceType: supplier.licenceType, 
        deliveredLicenceDate: supplier.deliveredLicenceDate,
        restriction: supplier.restriction,
        restrictionStartDate: supplier.restrictionStartDate,
        restrictionEndDate: supplier.restrictionEndDate,
        insuranceCompany: supplier.insuranceCompany,
        insuranceAmount: supplier.insuranceAmount,
        annualPaymentDate: supplier.annualPaymentDate,
        email: supplier.email,
        address: supplier.address,
        companyDigitalIdentifier: supplier.companyDigitalIdentifier,
        name: supplier.name,
        phoneNumber: supplier.phoneNumber,
        city: supplier.city,
        legalStatus: supplier.legalStatus,
        administrativeRegionCode: supplier.administrativeRegionCode,
        administrativeRegion: supplier.administrativeRegion,
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersServices.addSupplier : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `suppliers`, document: supplierData }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersServices.addSupplier : Annotation added', data);
        supplier._id = data.insertedId;
        resolve(supplier);
      });

    });
  }
}
