<div class="form-container">

    <mat-form-field>
        <mat-label>{{ 'user.firstname' | translate }}</mat-label>
        <input matInput placeholder="{{ 'user.firstnamesample' | translate }}" [(ngModel)]="user.firstname">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'user.lastname' | translate }}</mat-label>
        <input matInput placeholder="{{ 'user.lastnamesample' | translate }}" [(ngModel)]="user.lastname">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'user.email' | translate }}</mat-label>
        <input matInput placeholder="{{ 'user.emailsample' | translate }}" [(ngModel)]="user.email">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{ 'user.profile' | translate }}</mat-label>
        <mat-select [(ngModel)]="user.profile">
            <mat-option *ngFor="let state of userProfiles" [value]="state.value">
              {{ 'user.profile.' + state.viewValue | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-raised-button class="mt-3" color="basic" [routerLink]="'/groups'">{{ 'generic.cancel' | translate }}</button>

    <button mat-raised-button class="mt-3" color="primary" (click)="addUser()">{{ 'generic.validate' | translate }}</button>

</div>
