import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { SuppliersComponent } from './list/suppliers.component';
import { AddSupplierComponent } from './add/add.supplier.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/suppliers', component: SuppliersComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/suppliers/add', component: AddSupplierComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SuppliersRoutingModule { }
