import { Component } from '@angular/core';

import { SettingsServices } from '../../services/commons/settings.services';
import { DataAccessServices } from 'src/app/services/data.access.services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  constructor(
    public settingsServices: SettingsServices,
    public dataAccessServices: DataAccessServices
  ) { }

  public onSelectLanguageChanged(event: any): void {
    console.log('ProfileComponent.onSelectLanguageChanged : ', event);
    this.settingsServices.setLanguage(this.settingsServices.currentLanguage);
  }

  public validateSettings(): void {
    this.settingsServices.setLanguage(this.settingsServices.currentLanguage);
  }
}
