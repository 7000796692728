import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[truncateText]'
})
export class TruncateTextDirective implements AfterViewInit  {
  @Input('truncateText') maxLength: number;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    console.log('TruncateTextDirective.ngAfterViewInit : Start to truncate on', this.maxLength);
    let text = this.elementRef.nativeElement.innerText;
    console.log('TruncateTextDirective.ngAfterViewInit : Start to truncate text' + text);
    if (text.length > this.maxLength) {
      text = text.substring(0, this.maxLength) + '...';
      this.elementRef.nativeElement.innerText = text;
    }
  }
}
