import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';

import { LocalStorageServices } from '../../services/commons/local.storage.services';
import { DataAccessServices } from './../../services/data.access.services';
import { UsersServices } from './../../services/users/users.services';
import { PaymentsServices } from './../../services/payments/payments.services';

import { User } from '../../models/entities/users/user.model';
import { SettingsServices } from './../../services/commons/settings.services';
import { LicenceType } from './../../models/entities/payments/licence.type.enum';
import { Payment } from './../../models/entities/payments/payment.model';
import { UserProfile } from 'src/app/models/entities/users/user.profile.enum';
import { GoogleAuthenticationServices } from 'src/app/services/commons/google.authentication.services';
import { GoogleMessagingServices } from 'src/app/services/commons/google.messaging.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public isAuthenticating: boolean = false;
  public email: string = null;
  public password: string = null;
  public hidePassword: boolean = true;

  private loginDisplay: boolean = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private googleAuthenticationServices: GoogleAuthenticationServices,
    public settingsServices: SettingsServices,
    private translateService: TranslateService,
    private localStorageServices: LocalStorageServices,
    private dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private paymentsServices: PaymentsServices,
    private googleMessagingServices: GoogleMessagingServices
  ) { }

  public async authenticate(): Promise<void> {
    await this.usersServices.authenticate(this.email, this.password).then((data: User) => {
      this.dataAccessServices.currentUser = data;
      this.googleMessagingServices.requestPermission();
      this.googleMessagingServices.receiveMessages();
    }).catch(error => {
      console.error('LoginComponent.authenticate : Failed', error);
      if(error === 404) {
        this.snackBar.open(this.translateService.instant('login.usernotfound'));
      }
      else if(error === 500) {
        this.snackBar.open(this.translateService.instant('generic.internalerror'));
      }
    });

    this.dataAccessServices.currentUser.password = '**********';
    this.localStorageServices.set('currentuser', this.dataAccessServices.currentUser);

    if(this.dataAccessServices.currentUser.profile !== UserProfile.guest) {
      await this.paymentsServices.getByUser(this.dataAccessServices.currentUser).then((data: Array<Payment>) => {
        console.log('LoginComponent.authenticate : Get payments by user', data);
        const lastPayment = new List(data).OrderBy(p => p.creation).Last();
        const date = new Date(lastPayment.creation);
        const today = new Date();
    
        if(lastPayment.amount === LicenceType.PARTICULAR_MONTHLY) {
          const thirtyDaysAgo = new Date(today);
          thirtyDaysAgo.setDate(today.getDate() - 30);
          if (date > thirtyDaysAgo) {
            console.log("LoginComponent.authenticate : 30 days licence valide");
            this.router.navigate(['/dashboard']);
          } else {
            this.snackBar.open(this.translateService.instant('login.licenceexpired'));
            //window.open(environment.globalVestaUrl + '/userinformations/20', '_blank');
          }
        } else {
          this.snackBar.open(this.translateService.instant('login.unmanagedlicencepleasecontacttheadministrator'));
        }
      });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  public async googleAuthentication(){
    //await this.googleAuthenticationServices.googleAuth();
  }
}
