import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { Project } from '../../../models/entities/projects/project.model';
import { UserProfile } from 'src/app/models/entities/users/user.profile.enum';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { Group } from 'src/app/models/entities/users/group.model';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { ProjectGroupsServices } from 'src/app/services/projects/project.groups.services';

@Component({
  selector: 'app-add-project',
  templateUrl: './add.project.component.html',
  styleUrls: ['./add.project.component.scss']
})
export class AddProjectComponent {

  public projectStatusFormControl: FormControl = new FormControl();
  public filteredProjectStatusOptions: Observable<string[]>;
  public projectStatusOptions: Array<string>;
  public project: Project = new Project();

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    public dataAccessServices: DataAccessServices,
    private groupsServices: GroupsServices,
    private userGroupsServices: UserGroupsServices,
    private projectGroupsServices: ProjectGroupsServices,
    private projectsServices: ProjectsServices) { }

  ngOnInit() {
    this.projectStatusOptions = new List(this.dataAccessServices.projectStatus).Select(ps => ps.name).ToArray();
    this.filteredProjectStatusOptions = this.projectStatusFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  public filter(value: string): string[] {
    console.log('AddProjectComponent.filter : ', value);

    if (new List(this.dataAccessServices.projectStatus).Any(ps => ps.name.toLowerCase().includes(value.toLowerCase()))) {
      this.project.projectStatus = new List(this.dataAccessServices.projectStatus).First(ps => ps.name.toLowerCase().includes(value.toLowerCase()));
      console.log('AddProjectComponent.filter : Selected project status', this.project.projectStatus);
    }

    return this.projectStatusOptions.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }
  
  public async addProject(): Promise<void> {
    await this.projectsServices.addProject(this.project).then((data: Project) => {
      console.log('AddProjectComponent.addProject: Project added', data);
      this.project = data;
      this.dataAccessServices.projects.push(this.project);
    });

    let group = new Group();
    group.name = this.translateService.instant('project.default');
    group.projectId = this.project._id;
    await this.groupsServices.addGroup(group).then((data: Group) => {
      console.log('AddProjectComponent.addProject: Group added', data);
      group = data;
    });

    const projectGroup = new ProjectGroup();
    projectGroup.groupId = group._id;
    projectGroup.projectId = this.project._id;
    await this.projectGroupsServices.addProjectGroup(projectGroup).then((data: ProjectGroup) => {
      console.log('AddProjectComponent.addProject: Project group added', data);
    });

    if(this.dataAccessServices.currentUser.profile === UserProfile.administrator) {
      const userGroup = new UserGroup();
      userGroup.groupId = group._id;
      userGroup.userId = this.dataAccessServices.currentUser._id;
      await this.userGroupsServices.addUserGroup(userGroup).then((data: UserGroup) => {
        console.log('AddProjectComponent.addProject: User group added', data);
      });
    }

    this.router.navigate(['/projects']);
  }

  public getStreamedImage(): SafeUrl {
    if (this.project === null || this.project === undefined || this.project.image === undefined || this.project.image === null) {
      return this.sanitizer.bypassSecurityTrustUrl('assets/images/empty-picture.jpg');
    } else {
      let objectURL = this.project.image;
      return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }

  public onImageDropped(files: any): void {
    let file = files[0];
    console.log('AddProjectComponent.onImageDropped : Image dropped ', file);
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log('AddProjectComponent.onImageDropped : Image load ', reader.result);
      this.project.image = reader.result as string;
    }
    reader.readAsDataURL(file);
  }

  public onClicked(event: any): void {
    console.log("AddProjectComponent.onClicked: ", event);
  }

  public addProjectStatus(): void {
    this.router.navigate(['/addprojectstatus']);
  }
}
