import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from 'src/app/models/entities/files/file.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { S3MultipartUpload } from 'src/app/models/contracts/s3.multipart.upload';
import { S3UploadCompleted } from 'src/app/models/contracts/s3.upload.completed.contract';
import { AutodeskTranslateJob } from 'src/app/models/contracts/autodesk.translate.job.contract';
import { AutoDeskTask } from 'src/app/models/entities/autodesk/autodesk.task.model';
import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';
import { AutoDeskTokenServices } from '../commons/autodesk.token.services';

@Injectable({
  providedIn: 'root'
})
export class AutoDeskServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices,
    private autoDeskTokenServices: AutoDeskTokenServices) {}

  public async getToken(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      this.autoDeskTokenServices.getToken().then(data => {
        console.log('AutoDeskServices.getToken: Token', data);
        resolve(data);
      });
    });
  }

  public async checkBucketExists(bucketKey: string): Promise<boolean> {

    let accessToken = null;
    await this.autoDeskTokenServices.getToken().then(data => {
      console.log('AutoDeskServices.checkBucketExists: Token', data);
      accessToken = data;
    });

    console.debug('AutoDeskServices.checkBucketExists: Retrieved token', accessToken);
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

    return new Promise<boolean>(async (resolve, reject) => {
      await this.httpClient.get<any>(environment.autodesk.bucket.url, { headers })
      .toPromise().then(data => {
        console.log('AutoDeskServices.checkBucketExists: Buckets', data.items);
        resolve(data.items.some(bucket => bucket.bucketKey === bucketKey));
      });
    });
  }

  public async createBucket(bucketKey: string): Promise<any> {

    let accessToken = null;
    await this.autoDeskTokenServices.getToken().then(data => {
      console.log('AutoDeskServices.createBucket: Token', data);
      accessToken = data;
    });

    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`); 

    const body = {
      bucketKey: bucketKey,
      access: 'full',
      policyKey: 'transient'
    };

    console.log('AutoDeskServices.createBucket: Start to create the bucket', encodeURIComponent(bucketKey));

    return new Promise<any>(async (resolve, reject) => {
      await this.httpClient.post(environment.autodesk.bucket.url, body, { headers }).toPromise().then(data => {
        console.log('AutoDeskServices.createBucket: Bucket result', data);
        resolve(data);
      });
    });
  }

  public async initiateS3MultipartUpload(bucketKey: string, revisionFile: RevisionFile): Promise<S3MultipartUpload> {

    let accessToken = null;
    await this.autoDeskTokenServices.getToken().then(data => {
      console.log('AutoDeskServices.initiateS3MultipartUpload: Token', data);
      accessToken = data;
    });

    const autodeskMultipartUrl = `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/objects/${revisionFile.name}/signeds3upload?minutesExpiration=15`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return new Promise<S3MultipartUpload>(async (resolve, reject) => {
      await this.httpClient.get<any>(autodeskMultipartUrl, { headers })
      .toPromise().then(data => {
        console.log('AutoDeskServices.initiateS3MultipartUpload: s3 multipart uploads', data);
        resolve(data);
      });
    });
  }

  public async uploadRevisionFile(s3MultipartUpload: S3MultipartUpload, revisionFile: RevisionFile): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {

      console.log('AutoDeskServices.uploadRevisionFile: Start to upload', s3MultipartUpload, revisionFile);
      for(let i = 0; i < s3MultipartUpload.urls.length; i++) {
        const url = s3MultipartUpload.urls[i];
        const headers = new HttpHeaders()
        .set('Content-Type', `application/octet-stream`)
        //.set('Content-Length', revisionFile.data.size.toString());
        await this.httpClient.put<any>(url, revisionFile.data, { headers })
        .toPromise().then(data => {
          console.log('AutoDeskServices.uploadRevisionFile: Upload completed', data);
          resolve(data);
        });
      }
    });
  }

  public async completeUploadRevisionFile(s3MultipartUpload: S3MultipartUpload, project: Project, revisionFile: RevisionFile): Promise<S3UploadCompleted> {
    return new Promise<S3UploadCompleted>(async (resolve, reject) => {
      console.log('AutoDeskServices.completeUploadRevisionFile: Start to complete upload', s3MultipartUpload, project, revisionFile);

      let accessToken = null;
      await this.autoDeskTokenServices.getToken().then(data => {
        console.log('AutoDeskServices.completeUploadRevisionFile: Token', data);
        accessToken = data;
      });

      const autodeskCompleteUploadUrl = `https://developer.api.autodesk.com/oss/v2/buckets/${project._id}/objects/${revisionFile.name}/signeds3upload`;
  
      const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`)
      .set('Content-Type', `application/json`)
      .set('x-ads-meta-Content-Type', `application/json`);
      const data = {
        ossbucketKey: project._id,
        ossSourceFileObjectKey: revisionFile.name,
        access: 'full',
        uploadKey: s3MultipartUpload.uploadKey
      };  
      await this.httpClient.post<S3UploadCompleted>(autodeskCompleteUploadUrl, data, { headers })
      .toPromise().then(data => {
        console.log('AutoDeskServices.completeUploadRevisionFile: Upload completed', data);
        resolve(data);
      });
    });
  }

  public async translateRevisionFile(s3UploadCompleted: S3UploadCompleted): Promise<AutodeskTranslateJob> {
    console.log('AutoDeskServices.translateRevisionFile: Start to translate file', s3UploadCompleted);

    return new Promise<AutodeskTranslateJob>(async (resolve, reject) => {
      
      let accessToken = null;
      await this.autoDeskTokenServices.getToken().then(data => {
        console.log('AutoDeskServices.translateRevisionFile: Token', data);
        accessToken = data;
      });

      const url = 'https://developer.api.autodesk.com/modelderivative/v2/designdata/job';
      const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`)
      .set('Content-Type', 'application/json');

      const data = {
        input: {
          urn: this.base64UrlEncode(s3UploadCompleted.objectId)
        },
        output: {
          formats: [
            {
              type: 'svf',
              views: ['2d', '3d']
            }
          ]
        }
      };

      await this.httpClient.post<AutodeskTranslateJob>(url, data, { headers }).toPromise().then(data => {
        console.log('AutoDeskServices.translateRevisionFile: Translate job', data);
        resolve(data);
      });
    });
  }

  public async addAutodeskTask(autoDeskTask: AutoDeskTask): Promise<AutoDeskTask> {
    return new Promise<AutoDeskTask>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AutoDeskServices.addAutodeskTask : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const autoDeskTaskData = { 
        revisionFileId: autoDeskTask.revisionFileId, 
        progress: autoDeskTask.progress, 
        urn: autoDeskTask.urn, 
        status: autoDeskTask.status, 
        derivatives: autoDeskTask.derivatives, 
        modification: new Date(),
        creation: new Date() 
      };
      
      await this.httpClient.post<AutoDeskTask>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `autodesk-tasks`, document: autoDeskTaskData }, httpOptions).toPromise().then((data: any) => {
        console.log('AutoDeskServices.addAutodeskTask : AutoDeskTask added', data);
        autoDeskTask._id = data.insertedId;
        resolve(autoDeskTask);
      });
    });
  }

  public async checkAutodeskTask(autoDeskTask: AutoDeskTask): Promise<AutoDeskTask> {
    
    return new Promise<AutoDeskTask>(async (resolve, reject) => {

      let accessToken = null;
      await this.autoDeskTokenServices.getToken().then(data => {
        console.log('AutoDeskServices.checkAutodeskTask: Token', data);
        accessToken = data;
      });

      const checkJobUrl = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${autoDeskTask.urn}/manifest`;

      const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`);

      await this.httpClient.get<AutoDeskTask>(checkJobUrl, { headers })
      .toPromise().then(data => {
        console.log('AutoDeskServices.completeUploadProjectFile: Upload completed', data);
        resolve(data);
      });
    });
  }

  public async updateAutodeskTask(autoDeskTask: AutoDeskTask): Promise<AutoDeskTask> {
    return new Promise<AutoDeskTask>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AutoDeskServices.updateAutodeskTask : Token', data);
        token = data;
      });

      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const autoDeskTaskData = { 
        progress: autoDeskTask.progress, 
        urn: autoDeskTask.urn, 
        status: autoDeskTask.status, 
        derivatives: autoDeskTask.derivatives, 
        modification: new Date()
      };
      
      await this.httpClient.post<AutoDeskTask>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `autodesk-tasks`, filter: { '_id': { $eq: { $oid: autoDeskTask._id } } }, update: { $set: autoDeskTaskData } }, httpOptions).toPromise().then((data: any) => {
        console.log('AutoDeskServices.updateAutodeskTask : AutoDeskTask updated', data);
        if(data.matchedCount > 0) {
          resolve(autoDeskTask);
         } else {
          reject(data);
        }
      });
    });
  }

  public base64UrlEncode(input: string): string {
    const base64 = btoa(input); // Encode la chaîne en Base64 standard
    const base64Url = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Convertit en Base64URL
    return base64Url;
  }
}
