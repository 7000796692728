<mat-card class="card">
  <mat-card-header>
    <h3>{{ 'quote.uploaddocuments' | translate }}</h3>
  </mat-card-header>
  <mat-card-content>
    <upload-files (documentsCountchanged)="documentsCount = $event" (uploadDocumentsClicked)="uploadFile($event)" [uploadingFiles]="uploadingFile"></upload-files>
    <div class="row mt-3" *ngIf="documentsCount > 0">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'contract.quote' | translate }}</mat-label>
        <mat-select name="category" [(ngModel)]="contract.quoteId">
          <mat-option *ngFor="let quote of quotes" [value]="quote._id">{{ quote.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="contract.quoteId === null || contract.quoteId === undefined">
        <mat-label>{{ 'quote.supplier' | translate }}</mat-label>
        <mat-select name="category" [(ngModel)]="contract.supplierId">
          <mat-option *ngFor="let supplier of suppliers" [value]="supplier._id">{{ supplier.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'contract.name' | translate }}</mat-label>
        <input matInput name="contractName" [(ngModel)]="contract.name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'contract.comment' | translate }}</mat-label>
        <textarea matInput rows="8" [(ngModel)]="contract.comment"></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'contract.amount' | translate }}</mat-label>
        <input matInput name="contractAmount" [(ngModel)]="contract.amount">
      </mat-form-field>
      <mat-progress-bar *ngIf="uploadingFile == true" mode="determinate" value="{{ contract.progressUpload }}"></mat-progress-bar>
    </div>
  </mat-card-content>
</mat-card>


<div class="mt-3">
  
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef> {{ 'contract.supplier' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.supplier }} </td>
    </ng-container>

    <ng-container matColumnDef="quote">
      <th mat-header-cell *matHeaderCellDef> {{ 'contract.quote' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.quote }} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'contract.name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef> {{ 'contract.owner' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.owner }} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> {{ 'contract.amount' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.amount }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ 'contract.statusname' | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <span [ngSwitch]="element.status">
          <span *ngSwitchCase="0" class="badge-warning text-center"> {{ 'contract.status.proofread' | translate }} </span>
          <span *ngSwitchCase="1" class="badge-success text-center"> {{ 'contract.status.accepted' | translate }} </span>
          <span *ngSwitchCase="2" class="badge-warning text-center"> {{ 'contract.status.changerequest' | translate }} </span>
          <span *ngSwitchCase="3" class="badge-danger text-center"> {{ 'contract.status.denied' | translate }} </span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" (click)="downloadDocument(element)"><mat-icon>download</mat-icon> {{ 'generic.download' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['contract', element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteSupplier(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
                 *ngIf="quotes.length > 0"
                 [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</div>
