import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[customDropFiles]',
})
export class CustomDropFilesDirective {

  constructor() {
    console.log('CustomDropFilesDirective.constructor');
  }
  
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() clicked = new EventEmitter<any>();

  // Click listener
  @HostListener('click', ['$event']) onClick(event) {
    console.debug('CustomDropFilesDirective.click :', event);
    event.preventDefault();
    event.stopPropagation();
    this.clicked.emit(event);
  }

  @HostListener('mouseenter') onMouseEnter() {
    console.debug('CustomDropFilesDirective.mouseenter :', event);
  }

  @HostListener('mouseleave') onMouseLeave() {
    console.debug('CustomDropFilesDirective.mouseleave :', event);
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) ondrop(event) {
    console.debug('CustomDropFilesDirective.drop :', event);
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = (event.target.files || event.dataTransfer.files) as File[];
    console.log('CustomDropFilesDirective.drop : ', files.length, 'file(s) dropped');
    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
