<h1 mat-dialog-title>{{ 'modal.addrevisionfilecomment.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addannotation.comment' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="revisionFileComment.comment"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="revisionFileComment" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>