import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material.module';
import { ViewerModule } from 'ng2-adsk-forge-viewer';
import { MglTimelineModule } from 'angular-mgl-timeline';

import { SharedModule } from "../../shared/shared.module";
//import { UploadFilesComponent } from '../../shared/components/upload-files/upload.files.component';

import { FileReviewsRoutingModule } from './filereviews.routing.module';

import { ViewFilesComponent } from './view/view.files.component';
import { FilesComponent } from './list/files.component';
import { EditFileReviewComponent } from './edit/edit.filereview.component';

@NgModule({
    declarations: [
    ViewFilesComponent,
    FilesComponent,
    EditFileReviewComponent
    ],
    providers: [],
    bootstrap: [],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
        }),
        MglTimelineModule,
        ViewerModule,
        MaterialModule,
        FileReviewsRoutingModule,
        SharedModule,
        //UploadFilesComponent
    ]
})
export class FileReviewsModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
