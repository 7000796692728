import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';
import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { BoxConfiguration } from 'src/app/models/entities/iot/boxes/box.configuration.model';

@Injectable({
  providedIn: 'root'
})
export class BoxConfigurationsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getBoxConfiguration(boxConfigurationId: string): Promise<BoxConfiguration> {
      console.log('BoxConfigurationsServices.getBoxConfiguration: Start to get box configuration', boxConfigurationId);
  
      return new Promise<BoxConfiguration>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('BoxConfigurationsServices.getBoxConfiguration : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<Box>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `box-configurations`, filter: { '_id': { $oid: boxConfigurationId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('BoxConfigurationsServices.getBoxConfiguration : Box configuration', data);
          resolve(data.document);
        });
      });
  }
  
  public async getBoxConfigurationByBoxId(boxId: string): Promise<BoxConfiguration> {
    console.log('BoxConfigurationsServices.getBoxConfigurationByBoxId: Start to get box configuration', boxId);

    return new Promise<BoxConfiguration>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('BoxConfigurationsServices.getBoxConfigurationByBoxId : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Box>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `box-configurations`, filter: { 'boxId': boxId } }, httpOptions).toPromise().then((data: any) => {
        console.log('BoxConfigurationsServices.getBoxConfigurationByBoxId : Box configuration', data);
        resolve(data.document);
      });
    });
}
}
