import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import firebase from "firebase/compat/app";
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { GoogleAuthProvider } from '@angular/fire/auth';

import { DataAccessServices } from '../data.access.services';
import { UsersServices } from '../users/users.services';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthenticationServices {

  public currentUser: firebase.User = null;
  

  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private dataAccessServices: DataAccessServices,
    private usersServices: UsersServices
    ) { 
    }

    private authLogin(provider: any) {
      return this.angularFireAuth
        .signInWithPopup(provider)
        .then(async (result) => {
          console.log(`AuthenticationService.authLogin : Result ${JSON.stringify(result.user)}`);
          this.currentUser = result.user;
          await this.usersServices.getUserByEmail(result.user.email).then(data => {
            console.log(`AuthenticationService.authLogin : getUserByEmail ${JSON.stringify(data)}`);
            if(data === null) {
              // todo: SignUp
            } else {
              this.dataAccessServices.currentUser = data;
            }
          });
          this.router.navigate(['/dashboard']);
        })
        .catch((error) => {
           window.alert(error + " " + error.message);
        });
    }
}