import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { Project } from 'src/app/models/entities/projects/project.model';

@Injectable({
  providedIn: 'root'
})
export class SlotsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getSlot(slotId: string): Promise<Slot> {
      console.log('SlotsServices.getSlot: Start to get slot', slotId);
  
      return new Promise<Slot>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('SlotsServices.getSlot : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<Slot>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `slots`, filter: { '_id': { $oid: slotId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('SlotsServices.getSlot : Slot', data);
          resolve(data.document);
        });
      });
  }
  
  public async getSlots(project: Project): Promise<Array<Slot>> {
    console.log('SlotsServices.getSlots: Start to get slots', project);

    return new Promise<Array<Slot>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SlotsServices.getSlots : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Slot[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `slots`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('SlotsServices.getSlots : Slots', data);
        resolve(data.documents);
      });
    });
  }

  public async addSlot(slot: Slot): Promise<Slot> {
    console.log('SlotsServices.addSlot: Start to add a slot', slot);

    return new Promise<Slot>(async (resolve, reject) => {
      const slotData = { 
        projectId: slot.projectId, 
        name: slot.name, 
        description: slot.description, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SlotsServices.addSlot : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Slot>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `slots`, document: slotData }, httpOptions).toPromise().then((data: any) => {
        console.log('SlotsServices.addSlot : Project group added', data);
        slot._id = data.insertedId;
        resolve(slot);
      });
    });

  }

  public async updateSlot(slot: Slot): Promise<Slot> {
    console.log('SlotsServices.updateSlot: Start to update slot', slot);

    return new Promise<Slot>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SlotsServices.updateSlot : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const slotData = { 
        name: slot.name, 
        description: slot.description, 
        modification: new Date()
      };
        
      await this.httpClient.post<Slot>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `slots`, filter: { '_id': { $eq: { $oid: slot._id } } }, update: { $set: slotData } }, httpOptions).toPromise().then((data: any) => {
        console.log('SlotsServices.updateSlot : Slot updated', data);
        if(data.matchedCount > 0) {
          resolve(slot);
         } else {
          reject(data);
        }
      });
    });
  }
}
