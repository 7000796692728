import { Campaign } from "../campaigns/campaign.model";
import { EntityBase } from "../entity.base.model";
import { AnnotationPicture } from "./annotation.pictures.model";
import { AnnotationType } from "./annotation.type.model";

export class Annotation extends EntityBase {
  revisionFileId: string;
  annotationTypeId: string;
  campaignId: string;
  viewIndex: number;
  number: number;
  coordX: number;
  coordY: number;
  coordZ: number;
  subject: string;
  comment: string;
  ownerId: string; 

  annotationType: AnnotationType;
  ownerFullName: string;
  viewName: string;
  annotationPictures: Array<AnnotationPicture> = new Array<AnnotationPicture>();
  campaign: Campaign;
}
