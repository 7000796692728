<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'projectstatus.addprojectstatus' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div class="row form-group">

      <mat-form-field class="mt-3">
        <input matInput type="text" placeholder="{{ 'projectstatus.namesample' | translate }}" [(ngModel)]="projectStatus.name" (input)="onProjectStatusChanged()">
      </mat-form-field>

      <button mat-raised-button class="mt-3" color="basic" [routerLink]="'/projects'">{{ 'generic.cancel' | translate }}</button>

      <button mat-raised-button class="mt-3" color="primary" (click)="addProjectStatus()">{{ 'generic.validate' | translate }}</button>
    </div>
    
  </mat-card-content>
</mat-card>
