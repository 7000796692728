<h1 mat-dialog-title>{{ 'modal.signature.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <signature-pad #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button (click)="clearPad()">{{ 'generic.clear' | translate }}</button>
  <button mat-button [mat-dialog-close]="revisionFile" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>