import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';

import { QuotesRoutingModule } from './quotes.routing.module';

import { QuotesComponent } from './list/quotes.component';
import { QuoteComponent } from './details/quote.component';

@NgModule({
  declarations: [
    QuotesComponent,
    QuoteComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),

    MaterialModule,
    SharedModule,

    QuotesRoutingModule,
  ],
  providers: [],
  bootstrap: []
})
export class QuotesModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
