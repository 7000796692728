import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';
import { Group } from 'src/app/models/entities/users/group.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { DataAccessServices } from 'src/app/services/data.access.services';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { User } from 'src/app/models/entities/users/user.model';
import { UserProfile } from 'src/app/models/entities/users/user.profile.enum';
import { UsersServices } from 'src/app/services/users/users.services';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add.user.component.html',
  styleUrls: ['./add.user.component.scss']
})
export class AddUserComponent {

  private groupid: string;

  public selectedProject: Project = null;
  public group: Group = new Group();
  public user: User = new User();
  public userProfiles: any = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersServices,
    private dataAccessServices: DataAccessServices,
    private userGroupsServices: UserGroupsServices,
    private groupsServices: GroupsServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('AddUserComponent.constructor : Retrieved params', params);
      const projectid = params['projectid'];
      this.groupid = params['groupid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
    });
    this.userProfiles = Object.keys(UserProfile)
      .filter(key => !isNaN(Number(UserProfile[key])))
      .map(key => ({ value: UserProfile[key], viewValue: key }));
  }

  async ngOnInit() {
    await this.groupsServices.getGroup(this.groupid).then((data: Group) => {
      console.log('AddUserComponent.ngOnInit : Group', data);
      this.group = data;
    });
  } 
  
  public async addUser(): Promise<void> {
    await this.usersServices.addUser(this.user).then((data: User) => {
      console.log('AddUserComponent.addUser: User added', data);
      this.user = data;
    });

    const userGroup = new UserGroup();
    userGroup.groupId = this.group._id;
    userGroup.userId = this.user._id;
    await this.userGroupsServices.addUserGroup(userGroup).then((data: UserGroup) => {
      console.log('AddUserComponent.addUser: User group added', data);
    });

    this.router.navigate(['projects', 'project', this.selectedProject._id, 'groups']);
  }
}
