import { EntityBase } from "../entity.base.model";
import { User } from "../users/user.model";

export class RevisionFileComment extends EntityBase {
  revisionFileId: string;
  ownerId: string;
  comment: string;

  owner: string;
  ownerData: User;
}
