import { Injectable, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { List } from 'linqts';

import { ProjectsServices } from './projects/projects.services';
import { ProjectStatusServices } from './projects/project.status.services';
import { GroupsServices } from './users/groups.services';
import { ProjectGroupsServices } from './projects/project.groups.services';
import { UserGroupsServices } from './users/user.groups.services';

import { User } from "./../models/entities/users/user.model";
import { ProjectStatus } from '../models/entities/projects/project.status.model';
import { Project } from "./../models/entities/projects/project.model";
import { UserProfile } from '../models/entities/users/user.profile.enum';
import { UserGroup } from '../models/entities/users/user.group.model';
import { Group } from '../models/entities/users/group.model';
import { ProjectGroup } from '../models/entities/projects/project.group.model';
import { Alarm } from '../models/entities/alarms/alarm.model';
import { AlarmsServices } from './alarms/alarms.services';
import { SlotsServices } from './projects/slots.services';
import { Slot } from '../models/entities/projects/slot.model';
import { SensorsServices } from './iot/sensors.services';
import { Sensor } from '../models/entities/iot/sensors/sensor.model';
import { SensorTypesServices } from './iot/sensor.types.services';
import { SensorType } from '../models/entities/iot/sensors/sensor.type.model';

@Injectable({
  providedIn: 'root'
})
export class DataAccessServices implements OnDestroy {

  public cacheRefreshed: EventEmitter<boolean> = new EventEmitter();
  public iscacherefreshed: boolean = false;

  public currentUser: User = null;

  public userGroups: Array<UserGroup> = Array<UserGroup>();

  public users: Array<User> = Array<User>();
  public usersPageIndex: number = 1;
  public usersPageSize: number = 25;
  public usersTotal: number = 0; 

  public projectStatus: Array<ProjectStatus> = new Array<ProjectStatus>();
  public projectStatusPageIndex: number = 1;
  public projectStatusPageSize: number = 25;
  public projectStatusTotal: number = 0; 

  public projects: Array<Project> = Array<Project>(); 
  public projectsPageIndex: number = 1;
  public projectsPageSize: number = 25;
  public projectsTotal: number = 0;

  public alarms: Array<Alarm> = Array<Alarm>(); 

  constructor(
    private groupsServices: GroupsServices,
    private userGroupsServices: UserGroupsServices,
    private projectGroupsServices: ProjectGroupsServices,
    private projectsServices: ProjectsServices,
    private projectStatusServices: ProjectStatusServices,
    private alarmsServices: AlarmsServices,
    private slotsServices: SlotsServices,
    private sensorsServices: SensorsServices,
    private sensorTypesServices: SensorTypesServices) {}

  public ngOnDestroy(): void {
  }

  public async initialize(): Promise<boolean> {
    console.log('DataAccessServices.initialize: Start to initialize data');
    
    return new Promise<boolean>(async (resolve, reject) => {

      if (this.currentUser === null || this.currentUser === undefined) {
        reject(false);
      } else {
        await this.projectStatusServices.getProjectStatus().then((data: Array<ProjectStatus>) => {
          this.projectStatus = data;
        });

        // Si le current user est un administrator alors afficher tous les projets
        // Si le current user est un user alors afficher les projets des groupes auxquels le user est rattaché

        if(this.currentUser.profile === UserProfile.guest || this.currentUser.profile === UserProfile.user) {
          await this.userGroupsServices.getByUser(this.currentUser._id).then((data: Array<UserGroup>) => {
            console.log('DataAccessServices.initialize: Get user groups', data);
            this.userGroups = data;
          });
          
          let projectGroups = new Array<ProjectGroup>();
          for(let i = 0; i < this.userGroups.length; i++) {
            const userGroup = this.userGroups[i];
            let group = null;
            await this.groupsServices.getGroup(userGroup.groupId).then((data: Group) => {
              console.log('DataAccessServices.initialize: Get group', data);
              group = data;
            });

            await this.projectGroupsServices.getByGroup(group).then((data: Array<ProjectGroup>) => {
              console.log('DataAccessServices.initialize: Get project groups', data);
              projectGroups = projectGroups.concat(data);
            });
          }

          for(let i = 0; i < projectGroups.length; i++) {
            const projectGroup = projectGroups[i];
            await this.projectsServices.getProject(projectGroup.projectId).then((data: Project) => {
              console.log('DataAccessServices.initialize: Get project', data);
              this.projects.push(data);
            });
          }

          const nonTypedData = this.projects as any;
          for(let i = 0; i < nonTypedData.length; i++) {
            const projectData = nonTypedData[i];
            projectData.projectStatus = new List(this.projectStatus).First(ps => ps._id == projectData.projectStatusId);
          }
        } else {
        
          await this.projectsServices.getProjects().then((data: Array<Project>) => {
            console.log('DataAccessServices.initialize: Get projects', data);
            const nonTypedData = data as any;
            for(let i = 0; i < nonTypedData.length; i++) {
              const projectData = nonTypedData[i];
              projectData.projectStatus = new List(this.projectStatus).First(ps => ps._id == projectData.projectStatusId);
            }
            this.projects = data;
          });
        }

        for(let i = 0; i < this.projects.length; i++) {
          const project = this.projects[i];
          await this.alarmsServices.getAlarms(project).then((data: Array<Alarm>) => {
            console.log('DataAccessServices.initialize: Get alarms', data);
            this.alarms = this.alarms.concat(data);
          });
        }

        for(let i = 0; i < this.alarms.length; i++) {
          let alarm = this.alarms[i];
          await this.projectsServices.getProject(alarm.projectId).then((data: Project) => {
            console.log('DataAccessServices.initialize: Get project', data);
            alarm.project = data;
          });
          await this.slotsServices.getSlot(alarm.slotId).then((data: Slot) => {
            console.log('DataAccessServices.initialize: Get slot', data);
            alarm.slot = data;
          });
          await this.sensorsServices.getSensor(alarm.sensorId).then((data: Sensor) => {
            console.log('DataAccessServices.initialize: Get sensor', data);
            alarm.sensor = data;
          });
          await this.sensorsServices.getSensor(alarm.sensorId).then((data: Sensor) => {
            console.log('DataAccessServices.initialize: Get sensor', data);
            alarm.sensor = data;
          });
          await this.sensorTypesServices.getSensorType(alarm.sensor.sensorTypeId).then((data: SensorType) => {
            console.log('DataAccessServices.initialize: Get sensor type', data);
            alarm.sensorType = data;
          });
        }

        this.iscacherefreshed = true;
        this.cacheRefreshed.emit(this.iscacherefreshed);
        resolve(this.iscacherefreshed);
      }
    });
  }

  public async refresh(): Promise<boolean> {
    console.log('DataAccessServices.refresh: Start to refresh data');

    return new Promise<boolean>(async (resolve, reject) => {

      if (this.currentUser === null || this.currentUser === undefined) {
        reject();
      } else {
        this.cacheRefreshed.emit(true);
        this.iscacherefreshed = true;
        resolve(true);
      }
    });
  }

  public isCacheRefreshing(): boolean {
    return this.iscacherefreshed == false;
  }
}
