<div class="row">
  <div class="col-md-4">
    <div class="row">
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title-group>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="row mt-auto" *ngIf="isEditing === false">
              <mat-label><b>{{ selectedProject.name }}</b></mat-label>
            </div>
            <div class="row mt-auto" *ngIf="isEditing === true">
              <mat-form-field>
                <input matInput name="projectName" [(ngModel)]="selectedProject.name">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <img *ngIf="isEditing === false" mat-card-image [src]="getStreamedImage(selectedProject)" width="250" />
            <div *ngIf="isEditing === true" customDropFiles (filesDropped)="onImageDropped($event)" (clicked)="onClicked($event)">
              <img mat-card-image [src]="getStreamedImage(selectedProject)" width="250" class="image-container" />
            </div>
          </div>
          <div class="row from-group" *ngIf="selectedProject !== null">
            <div class="col-md-6 mt-3" *ngIf="isEditing === true">
              <mat-form-field>
                <mat-select placeholder="{{ 'project.phase' | translate }}" [(ngModel)]="selectedProject.phase">
                  <mat-option [value]="0">{{ 'project.phases.conception' | translate }}</mat-option>
                  <mat-option [value]="1">{{ 'project.phases.construction' | translate }}</mat-option>
                  <mat-option [value]="2">{{ 'project.phases.exploitation' | translate }}</mat-option>
                  <mat-option [value]="3">{{ 'project.phases.destruction' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6 mt-3" *ngIf="isEditing === false">
              <span class="badge-default text-center">
                <span [ngSwitch]="selectedProject.phase">
                  <span *ngSwitchCase="0"> {{ 'project.phases.conception' | translate }} </span>
                  <span *ngSwitchCase="1"> {{ 'project.phases.construction' | translate }} </span>
                  <span *ngSwitchCase="2"> {{ 'project.phases.exploitation' | translate }} </span>
                  <span *ngSwitchCase="3"> {{ 'project.phases.destruction' | translate }} </span>
                </span>
              </span>
            </div>
            <div class="col-md-6 mt-3" *ngIf="isEditing === false">
              <span class="badge-default text-center">{{ selectedProject.projectStatus.name }}</span>
            </div>
          </div>
          <div class="row form-group">
            <div class="row mt-3" *ngIf="isEditing === false">
              <button mat-raised-button color="basic" [routerLink]="'/projects'"><mat-icon>cancel</mat-icon> {{ 'generic.cancel' | translate }}</button>
            </div>
            <div class="row mt-3" *ngIf="isEditing === false">
              <button mat-raised-button color="primary" (click)="editProject()"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button>
            </div>
            <div class="row mt-3" *ngIf="isEditing === true">
              <button mat-raised-button color="basic" (click)="editProject()"><mat-icon>cancel</mat-icon> {{ 'generic.cancel' | translate }}</button>
            </div>
            <div class="row mt-3" *ngIf="isEditing === true">
              <button mat-raised-button color="primary" (click)="updateProject()"><mat-icon>edit</mat-icon> {{ 'generic.validate' | translate }}</button>
            </div>
            <div class="row mt-3" *ngIf="isEditing === true">
              <button mat-raised-button color="warn" (click)="deleteProject()"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="row mt-3">
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title-group>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <mat-tab-group>
            <mat-tab label="{{ 'project.description' | translate }}">
              <div class="row" style="padding:20px; width:80%">
                <div class="row mt-3"*ngIf="isEditing === false">
                  {{ selectedProject.description }}
                </div>
                <div class="row mt-3" *ngIf="isEditing === true">
                  <mat-form-field>
                    <textarea matInput name="projectDescription" [(ngModel)]="selectedProject.description"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'project.location' | translate }}">
              <div class="row mt-3" *ngIf="isEditing === false">
                <mat-label>{{ selectedProject.address }}</mat-label>
              </div>
              <div class="row mt-3" *ngIf="isEditing === false">
                <div #mapContainer id="map" style="min-width: 100%; min-height: 100%"></div>
              </div>
              <div class="row mt-auto" *ngIf="isEditing === true">
                <mat-form-field>
                  <textarea matInput name="projectAddress" [(ngModel)]="selectedProject.address"></textarea>
                </mat-form-field>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="col-md-8">
    <mat-tab-group>
      <mat-tab label="{{ 'project.slots' | translate }} ({{ groups.length }})"> 
        <div class="row mt-3">
          <button mat-raised-button color="primary" [routerLink]="['slots', 'add']"><mat-icon>add</mat-icon> {{ 'generic.add' | translate }}</button>
        </div>
        <div class="row">
          <table mat-table [dataSource]="slotsDataSource">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'slots.name' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>
  
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> {{ 'slots.description' | translate }} </th>
              <td mat-cell *matCellDef="let element">{{ element.description }}</td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedSlotsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedSlotsColumns;"></tr>
          </table>
  
          <mat-paginator *ngIf="slots?.length > 0"
                        [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons
                        aria-label="Select page">
          </mat-paginator>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'project.statistics' | translate }}"> 
        <mat-list role="list">
          <mat-list-item role="listitem">{{ 'project.numberoffiles' | translate }} {{ files.length }}</mat-list-item>
        </mat-list>
      </mat-tab>
      <mat-tab label="{{ 'project.groups' | translate }} ({{ groups.length }})"> 
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let group of groups">{{ group.name }}</mat-list-item>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

