<div class="row" *ngIf="dataAccessServices.iscacherefreshed == false">
  <mat-spinner diameter="100"></mat-spinner>
</div>

<div class="row" *ngIf="dataAccessServices.iscacherefreshed == true">
  <div class="col-md-4 mt-3">
    <div class="dashboard-card">
      <div class="row">
        <div class="title"><h4>{{ 'dashboard.projects' | translate }}</h4></div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <mat-icon>list</mat-icon>
        </div>
        <div class="col-8 text-end">
          <span>{{ this.dataAccessServices.projects.length }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 mt-3">
    <div class="dashboard-card">
      <div class="row">
        <div class="title"><h4>{{ 'dashboard.documentstoreview' | translate }}</h4></div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <mat-icon>collections_bookmark</mat-icon>
        </div>
        <div class="col-8 text-end">
          <span>{{ this.revisionFilesToReview }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 mt-3">
    <div class="dashboard-card">
      <div class="row">
        <div class="title"><h4>{{ 'dashboard.documentstohave' | translate }}</h4></div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <mat-icon>error_outline</mat-icon>
        </div>
        <div class="col-8 text-end">
          <span>0</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col-md-6" style="height:60vh">
    <ngx-charts-bar-vertical
      [results]="graphData"
      [gradient]="false"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxisLabel]="'Construction materials'"
      [yAxisLabel]="'CO2 (Kg)'"
    >
    </ngx-charts-bar-vertical>
  </div>
  <div class="col-md-6">
    <mat-card class="card">
      <mat-card-header>
        <div class="text-center">
          <h4>{{ 'dashboard.lastdocuments' | translate }}</h4>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="fileDataSource">

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> {{ 'file.title' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
          </ng-container>

          <ng-container matColumnDef="lastversion">
            <th mat-header-cell *matHeaderCellDef> {{ 'file.lastversion' | translate }} </th>
            <td mat-cell *matCellDef="let element"> 
              <span class="badge-success text-center"> {{ element.lastVersion }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="ownerId">
            <th mat-header-cell *matHeaderCellDef> {{ 'file.owner' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.owner }} </td>
          </ng-container>

          <ng-container matColumnDef="creation">
            <th mat-header-cell *matHeaderCellDef> {{ 'file.creation' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.creation | date:'short' }} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedFileColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedFileColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row mt-5">
  <mat-card class="card">
    <mat-card-header>
      <div class="text-center">
        <h4>{{ 'dashboard.alarms' | translate }}</h4>
      </div>
    </mat-card-header>
    <mat-card-content>
      <table mat-table [dataSource]="alarmDataSource">

        <ng-container matColumnDef="projectName">
          <th mat-header-cell *matHeaderCellDef> {{ 'alarm.projectName' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.project.name }} </td>
        </ng-container>

        <ng-container matColumnDef="slotName">
          <th mat-header-cell *matHeaderCellDef> {{ 'alarm.slotName' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.slot.name }} </td>
        </ng-container>

        <ng-container matColumnDef="sensorType">
          <th mat-header-cell *matHeaderCellDef> {{ 'alarm.sensorType' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.sensorType.name }} </td>
        </ng-container>

        <ng-container matColumnDef="topic">
          <th mat-header-cell *matHeaderCellDef> {{ 'alarm.topic' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.topic }} </td>
        </ng-container>

        <ng-container matColumnDef="sensorValue">
          <th mat-header-cell *matHeaderCellDef> {{ 'alarm.sensorValue' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.sensorValue }} </td>
        </ng-container>

        <ng-container matColumnDef="creation">
          <th mat-header-cell *matHeaderCellDef> {{ 'alarm.creation' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.creation | date:'short' }} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedAlarmColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedAlarmColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>