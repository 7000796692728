import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { RevisionFileComment } from "src/app/models/entities/files/revision.file.comment.model";

@Component({
  selector: 'add-revision-file-comment-modal',
  templateUrl: 'add.revision.file.comment.modal.html'
})
export class AddRevisionFileCommentModal {
  constructor(
    public dialogRef: MatDialogRef<AddRevisionFileCommentModal>,
    @Inject(MAT_DIALOG_DATA) public revisionFileComment: RevisionFileComment,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}