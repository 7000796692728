import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { List } from 'linqts';
import { ViewerInitializedEvent, ViewerOptions } from 'ng2-adsk-forge-viewer';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';
import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { AutoDeskServices } from 'src/app/services/autodesk/autodesk.services';
import { EditAnnotationModal } from '../modals/annotations/edit.annotation.modal';
import { MatDialog } from '@angular/material/dialog';
import { DataAccessServices } from 'src/app/services/data.access.services';
import { AddAnnotationModal } from '../modals/annotations/add.annotation.modal';
import { AnnotationsServices } from 'src/app/services/annotations/annotations.services';

declare const Autodesk: any;

@Component({
  selector: 'autodesk-viewer',
  templateUrl: './autodesk-viewer.component.html'
})
export class AutodeskViewerComponent {
  
  @ViewChild('forgeViewer', { static: true }) forgeViewer: ElementRef;

  @Output() public isGeometryLoaded = new EventEmitter<boolean>();

  @Input() public revisionFile: RevisionFile = null;
  @Input() public annotations: Array<Annotation> = null;
  @Input() public annotationTypes: Array<AnnotationType> = new Array<AnnotationType>();
  @Input() public suppliers: Array<Supplier> = new Array<Supplier>();
  
  private isViewerToInitialize: boolean = false;
  private viewer: any;
  private viewerDocument: any;
  private selectedViewIndex: number = 0;

  public defaultViewName: string = 'default';
  public views: any;
  public views2d: any;
  public views3d: any;

  constructor(
    private dialog: MatDialog,
    private dataAccessServices: DataAccessServices,
    private autoDeskServices: AutoDeskServices,
    private annotationsServices: AnnotationsServices) { }

  /*
  async ngOnChanges() {
    
  }
  */
  async ngOnChanges() {
    if(this.isViewerToInitialize == false && this.revisionFile !== null && this.revisionFile !== undefined) {
      this.isViewerToInitialize = true;
      await this.initializeViewer().then(data => {
        console.log('AutodeskViewerComponent.ngAfterViewInit: Viewer initialized', data);
      });
    }
  }

  public on2DSelectViewChanged(event) {
    console.log('AutodeskViewerComponent.on2DSelectViewChanged : View selection changed', event);
    this.selectedViewIndex = event.value;
    const view = this.views2d[this.selectedViewIndex];
    console.log('AutodeskViewerComponent.on2DSelectViewChanged : Selected view', view);
    this.viewer.loadDocumentNode(this.viewerDocument, view);
  }

  public on3DSelectViewChanged(event) {
    console.log('AutodeskViewerComponent.on3DSelectViewChanged : View selection changed', event);
    this.selectedViewIndex = event.value;
    const view = this.views3d[this.selectedViewIndex];
    console.log('AutodeskViewerComponent.on3DSelectViewChanged : Selected view', view);
    this.viewer.loadDocumentNode(this.viewerDocument, view);
  }

  public drawAnnotation(annotation: Annotation) {
    // Convertir les coordonnées 3D en coordonnées 2D
    const screenpoint = this.viewer.worldToClient(
      new THREE.Vector3(annotation.coordX, annotation.coordY, annotation.coordZ)
    );

    // Créer l'élément div container
    const divElement = document.createElement('div');
    divElement.id = 'annotation-' + annotation._id;
    
    divElement.style.cursor = 'pointer';
    divElement.style.zIndex = '9999';
    divElement.style.width = '20px';
    divElement.style.height = '20px';
    divElement.style.position = 'absolute';
    divElement.style.overflow = 'visible';
    divElement.addEventListener('click', () => {
      this.editAnnotation(annotation);
    });

    divElement.addEventListener('mouseover', (event) => {
      // Créer un élement pour afficher la première image
      const imageElement = divElement.querySelector('img');
      if (!imageElement) {
        if(annotation.annotationPictures !== null && annotation.annotationPictures !== undefined && annotation.annotationPictures.length > 0) {
          const annotationPicture = annotation.annotationPictures[0];
          // Créer l'élément SVG et dessiner un cercle
          const pictureElement = document.createElement('img') as HTMLImageElement;
          pictureElement.style.width = '100px';
          pictureElement.style.height = '100px';
          pictureElement.style.borderRadius = '5%';
          pictureElement.style.display = 'inline-block';
          pictureElement.src = annotationPicture.data;
          // Ajouter le cercle à l'élément SVG
          divElement.appendChild(pictureElement);
        }
      }
    });

    divElement.addEventListener('mouseleave', (event) => {
      const imageElement = divElement.querySelector('img');
      // Vérifier si l'élément img existe
      if (imageElement) {
          // Supprimer l'élément img de divElement
          divElement.removeChild(imageElement);
      }
    });

    // Créer l'élément SVG et dessiner un cercle
    const dotElement = document.createElement('span');
    dotElement.style.width = '20px';
    dotElement.style.height = '20px';

    if(annotation.campaign !== undefined && annotation.campaign !== null) {
      dotElement.style.backgroundColor = annotation.campaign.color;
    } else {
      if(new List(this.annotationTypes).Any(at => at._id === annotation.annotationTypeId)) {
        dotElement.style.backgroundColor = new List(this.annotationTypes).First(at => at._id === annotation.annotationTypeId).color;
      } else {
        dotElement.style.backgroundColor = 'red';
      }
    } 

    dotElement.style.borderRadius = '50%';
    dotElement.style.display = 'inline-block';

    const textElement = document.createElement('span');
    textElement.style.paddingLeft = '6px'
    textElement.textContent = annotation.number.toString();
    dotElement.appendChild(textElement);

    // Ajouter le cercle à l'élément SVG
    divElement.appendChild(dotElement);

    // Définir la position de l'élément container
    divElement.style.left = (screenpoint.x - 12 * 2) + 'px';
    divElement.style.top = (screenpoint.y - 12) + 'px';

    // Stocker les données 3D dans l'attribut data de l'élément
    //annotation.radius = 12;
    const storeData = JSON.stringify({x : annotation.coordX, y: annotation.coordY, z: annotation.coordZ, radius: 12});
    divElement.dataset['3DData'] = storeData;
    this.viewer.container.appendChild(divElement);
  }

  public removeAnnotation(annotation: Annotation) {
    console.log('AutodeskViewerComponent.onCameraChangeEvent: Camera changed', event);
    // Trouver tous les éléments avec des identifiants commençant par "annotation"
    const element = document.querySelector('div#annotation-' + annotation._id);
    if (element) {
      element.remove();
    }
  }

  private async initializeViewer() {
    console.log('AutodeskViewerComponent.initializeViewer : Start to generate token');
    
    const initializerOptions: Autodesk.Viewing.InitializerOptions = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',
      getAccessToken: function(onTokenReady) {
        this.autoDeskServices.getToken().then((data: string) => {
          console.log('AutodeskViewerComponent.initializeViewer : getAccessToken - Token generated', data);
          onTokenReady(data, 3600 * 30);
        });
      }.bind(this)
    };

    const viewerOptions: ViewerOptions = {
      initializerOptions: initializerOptions,
      onViewerInitialized: function (args: ViewerInitializedEvent): void {
        console.log('AutodeskViewerComponent.initializeViewer : OnViewerInitialized', args);
      }
    };

    Autodesk.Viewing.Initializer(viewerOptions.initializerOptions, async ()  => {
      this.viewer = new Autodesk.Viewing.GuiViewer3D(this.forgeViewer.nativeElement)
      this.viewer.start();
      this.viewer.loadExtension('Autodesk.PDF').then(() => {
        console.log('AutodeskViewerComponent.initializeViewer : Extension pdf loaded');
      });
      this.viewer.container.addEventListener('click', this.onForgeViewerClick.bind(this));
      this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChangeEvent.bind(this));
      this.loadDocument().then(data => {
        console.log('AutodeskViewerComponent.initializeViewer : Document loaded', data);
      });
      /*
      this.loadSupplier().then(data => {
        console.log('AutodeskViewerComponent.initializeViewer : Supplier loaded', data);
      });
      */
    });
    
  }

  private loadDocument(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Autodesk.Viewing.Document.load(`urn:${this.autoDeskServices.base64UrlEncode(this.revisionFile.decodedUrn)}`, (document) => {
        const defaultModel = document.getRoot().getDefaultGeometry();
        console.log('AutodeskViewerComponent.loadDocument : Default model', defaultModel);
        this.defaultViewName = defaultModel.data.name;
        this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
          console.log('AutodeskViewerComponent.loadDocument : Geometry loaded');
          this.annotations.forEach(annotation => {
            this.drawAnnotation(annotation);
          });
          this.suppliers.forEach((supplier, index) => {
            this.drawSupplier(supplier, index);
          });
          this.isGeometryLoaded.emit(true);
          resolve(true);
        });
        this.viewer.loadDocumentNode(document, defaultModel).then((data) => {
          console.log('AutodeskViewerComponent.loadDocument : Document loaded', data);
        });
        this.setViews(document);
      });
    });
  }

  private setViews(viewerDocument) {
    this.viewerDocument = viewerDocument;
    const documentRoot = this.viewerDocument.getRoot();
    this.views = documentRoot.search({type:'geometry'});
    const documents3d = documentRoot.search({type:'geometry', role: '3d'});
    this.views3d = documents3d;
    const documents2d = documentRoot.search({type:'geometry', role: '2d'});
    this.views2d = documents2d;
    this.annotations.forEach(annotation => {
      annotation.viewName = this.views[annotation.viewIndex].data.name;
    });
  }

  private onCameraChangeEvent(event) {
    console.log('AutodeskViewerComponent.onCameraChangeEvent: Camera changed', event);
    // Trouver tous les éléments avec des identifiants commençant par "annotation"
    const elements = document.querySelectorAll('div[id^="annotation"]');

    // Parcourir chaque élément
    elements.forEach(element => {
        // Convertir l'élément générique en un élément HTML
        const divElement = element as HTMLElement;

        // Obtenir les données 3D associées à l'élément
        const data3D = divElement.dataset['3DData'];
        const pushpinModelPt = JSON.parse(data3D);

        // Obtenir le point à l'écran en utilisant le viewer Forge
        const screenpoint = this.viewer.worldToClient(new THREE.Vector3(
            pushpinModelPt.x,
            pushpinModelPt.y,
            pushpinModelPt.z
        ));

        // Mettre à jour la position de l'élément
        divElement.style.left = (screenpoint.x - pushpinModelPt.radius * 2) + 'px';
        divElement.style.top = (screenpoint.y - pushpinModelPt.radius) + 'px';
    });

  }

  private onForgeViewerClick(event) {
    console.log('AutodeskViewerComponent.onForgeViewerClick : ', event);
    
    var screenPoint = {
      x: event.clientX,
      y: event.clientY
    }; 
    let viewer_pos = this.viewer.container.getBoundingClientRect();
    let hitTest = this.viewer.impl.hitTest(screenPoint.x - viewer_pos.x,
      screenPoint.y - viewer_pos.y, true); 
    if(hitTest)
    {  
      if(event.srcElement instanceof HTMLCanvasElement) {
        this.addAnnotation(hitTest.intersectPoint.x, hitTest.intersectPoint.y, hitTest.intersectPoint.z);
      } else if(event.srcElement instanceof HTMLSpanElement) {

      }
      
    }
  }

  private addAnnotation(clientX, clientY, clientZ = 0) {
    console.log('AutodeskViewerComponent.addMarker : Start to add marker', clientX, clientY);

    let annotation = new Annotation();
    annotation.revisionFileId = this.revisionFile._id;
    annotation.viewIndex = this.selectedViewIndex;
    annotation.coordX = clientX;
    annotation.coordY = clientY;
    annotation.coordZ = clientZ;
    annotation.ownerId = this.dataAccessServices.currentUser._id;
    annotation.number = this.annotations.length + 1;
    const dialogRef = this.dialog.open(AddAnnotationModal, {
      data: annotation,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== false) {
        this.drawAnnotation(annotation);
        await this.annotationsServices.addAnnotation(annotation).then((data: Annotation) => {
          console.log('AutodeskViewerComponent.addMarker: Annotation added', data);
          this.annotations.push(data);
        });
      }
    });
  }

  private drawSupplier(supplier: Supplier, index: number) {
    // Créer l'élément div container
    const divElement = document.createElement('div');
    divElement.id = 'supplier-' + supplier._id;
    
    divElement.style.cursor = 'pointer';
    divElement.style.zIndex = '9999';
    divElement.style.width = '200px';
    divElement.style.height = '40px';
    divElement.style.position = 'absolute';
    divElement.style.overflow = 'visible';

    // Créer l'élément SVG et dessiner un cercle
    const dotElement = document.createElement('span');
    dotElement.style.width = '200px';
    dotElement.style.height = '40px';
    dotElement.style.backgroundColor = 'red';

    dotElement.style.borderRadius = '30px';
    dotElement.style.display = 'inline-block';

    const iconElement = document.createElement('img');
    iconElement.src = 'assets/images/truck_black.png';
    iconElement.alt = 'truck';
    iconElement.style.width = '35px';
    iconElement.style.paddingLeft = '6px'
    dotElement.appendChild(iconElement);

    const textElement = document.createElement('span');
    textElement.style.paddingLeft = '4px'
    textElement.textContent = supplier.name.toString();
    dotElement.appendChild(textElement);

    // Ajouter le cercle à l'élément SVG
    divElement.appendChild(dotElement);

    // Définir la position de l'élément container
    divElement.style.left = '10px';
    divElement.style.top = (index * 50) + 10 + 'px';

    this.viewer.container.appendChild(divElement);
  }

  public editAnnotation(annotation: Annotation) {
    const dialogRef = this.dialog.open(EditAnnotationModal, {
      data: annotation,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
    });
  }
}
