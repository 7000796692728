<h1 mat-dialog-title>{{ 'modal.addtask.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.type' | translate }}</mat-label>
      <mat-select name="type" [(ngModel)]="task.type">
        <mat-option [value]="0">{{ 'modal.addtask.typetask' | translate }}</mat-option>
        <mat-option [value]="1">{{ 'modal.addtask.typemilestone' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.supplier' | translate }}</mat-label>
      <mat-select name="parent" [(ngModel)]="task.supplierId">
        <mat-option *ngFor="let supplier of suppliers" [value]="supplier._id">{{ supplier.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.name' | translate }}</mat-label>
      <input matInput [(ngModel)]="task.name">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.description' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="task.description"></textarea>
    </mat-form-field>
  </div>
  <div class="row" *ngIf="task.type === 1">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.date' | translate }}</mat-label>
      <input matInput [matDatepicker]="datePicker" [(ngModel)]="task.date">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="row" *ngIf="task.type === 0">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.startdate' | translate }}</mat-label>
      <input matInput [matDatepicker]="startPicker" [(ngModel)]="task.start">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="row" *ngIf="task.type === 0">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.starttime' | translate }}</mat-label>
      <input matInput type="time" [(ngModel)]="startTime">
    </mat-form-field>
  </div>
  <div class="row" *ngIf="task.type === 0">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.enddate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endPicker" [(ngModel)]="task.end">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="row" *ngIf="task.type === 0">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.endtime' | translate }}</mat-label>
      <input matInput type="time" [(ngModel)]="endTime">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.color' | translate }}</mat-label>
      <input matInput type="color" [(ngModel)]="task.color">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.addtask.parent' | translate }}</mat-label>
      <mat-select name="parent" [(ngModel)]="task.parent">
        <mat-option *ngFor="let item of data.tasks" [value]="item._id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button (click)="validTask(task)" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>