import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { Project } from '../../../models/entities/projects/project.model';
import { UserProfile } from 'src/app/models/entities/users/user.profile.enum';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { Group } from 'src/app/models/entities/users/group.model';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { ProjectGroupsServices } from 'src/app/services/projects/project.groups.services';
import { Material } from 'src/app/models/entities/suppliers/material.model';
import { MaterialsServices } from 'src/app/services/suppliers/materials.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';

@Component({
  selector: 'app-edit-material',
  templateUrl: './material.component.html'
})
export class MaterialComponent {

  public suppliers: Array<Supplier> = new Array<Supplier>();
  public selectedProject: Project = null;
  public selectedMaterial: Material = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private materialsServices: MaterialsServices) {
    }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      console.log('MaterialComponent.constructor : Retrieved params', params);
      let projectid = params['projectid'];
      let materialid = params['materialid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }

      await this.materialsServices.getMaterial(materialid).then(data => {
        console.log('MaterialsComponent.ngOnInit : Material', data);
        this.selectedMaterial = data;
      });
  
      await this.suppliersServices.getSuppliers(this.selectedProject).then(data => {
        console.log('MaterialComponent.ngOnInit : Suppliers', data);
        this.suppliers = data;
      });
    });
  }

  public async updateMaterial(): Promise<void> {
    await this.materialsServices.updateMaterial(this.selectedMaterial).then((data: Material) => {
      console.log('MaterialComponent.updateMaterial: Material updated', data);
      this.selectedMaterial = data;
    });
    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'materials']);
  }
}
