import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Project } from '../../models/entities/projects/project.model';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { Group } from 'src/app/models/entities/users/group.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectGroupsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

    public async getByGroup(group: Group): Promise<Array<ProjectGroup>> {
      console.log('ProjectGroupsServices.getByGroup: Start to get project groups by group', group);
      
      return new Promise<Array<ProjectGroup>>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectGroupsServices.getByGroup : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<ProjectGroup[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `project-groups`, filter: { 'groupId': { $eq: group._id } } }, httpOptions).toPromise().then((data: any) => {
          console.log('ProjectGroupsServices.getByGroup : Projects', data);
          resolve(data.documents);
        });
      });
    }

    public async getByProject(project: Project): Promise<Array<ProjectGroup>> {
      console.log('ProjectGroupsServices.getByProject: Start to get project groups by project', project);
      
      return new Promise<Array<ProjectGroup>>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectGroupsServices.getByProject : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<ProjectGroup[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `project-groups`, filter: { 'projectId': { $eq: project._id } } }, httpOptions).toPromise().then((data: any) => {
          console.log('ProjectGroupsServices.getByProject : Project groups', data);
          resolve(data.documents);
        });
      });
    }

    public async addProjectGroup(projectGroup: ProjectGroup): Promise<ProjectGroup> {
      console.log('ProjectGroupsServices.addProjectGroup: Start to add a project group', projectGroup);
  
      return new Promise<ProjectGroup>(async (resolve, reject) => {
        const projectGroupData = { 
          groupId: projectGroup.groupId, 
          projectId: projectGroup.projectId, 
          modification: new Date(),
          creation: new Date() 
        };

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ProjectGroupsServices.addProjectGroup : Token', data);
          token = data;
        });

        const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<ProjectGroup>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `project-groups`, document: projectGroupData }, httpOptions).toPromise().then((data: any) => {
          console.log('ProjectGroupsServices.addProjectGroup : Project group added', data);
          projectGroup._id = data.insertedId;
          resolve(projectGroup);
        });
      });
  
    }

}
