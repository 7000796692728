import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { Observable, map, startWith } from 'rxjs';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Group } from 'src/app/models/entities/users/group.model';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { User } from 'src/app/models/entities/users/user.model';
import { DataAccessServices } from 'src/app/services/data.access.services';
import { ProjectGroupsServices } from 'src/app/services/projects/project.groups.services';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { UsersServices } from 'src/app/services/users/users.services';


@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent {

  public selectedProject: Project = null;
  public group: Group = null;
  public users: Array<User> = new Array<User>();
  public displayedColumns: string[] = ['firstname', 'lastname', 'email', 'remove'];
  public dataSource = new MatTableDataSource<User>(this.users);
  public allUsers = new Array<User>();
  public filteredOptions: Array<User>;
  public filter: string;
  public selectedUser: User = null;

  private groupId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataAccessServices: DataAccessServices,
    private groupsServices: GroupsServices,
    private usersServices: UsersServices,
    private projectGroupsServices: ProjectGroupsServices,
    private userGroupsServices: UserGroupsServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('GroupComponent.constructor : Retrieved params', params);
      const projectid = params['projectid'];
      this.groupId = params['groupid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
    });
  }

  public async ngOnInit() {

    await this.groupsServices.getGroup(this.groupId).then(data => {
      console.log('GroupComponent.ngOnInit : Get groups', data);
      this.group = data;
    });

    await this.getUserGroups();

    console.log('GroupComponent.ngOnInit : All project\'s users', this.allUsers);
  }

  public addUser() {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'groups', 'group', this.group._id, 'user', 'add']);
  }

  public async addSelectedUser(): Promise<void> {
    const userGroup = new UserGroup();
    userGroup.groupId = this.group._id;
    if(this.selectedUser !== null && this.selectedUser !== undefined) {
      userGroup.userId = this.selectedUser._id;
    } else {
      console.log('GroupComponent.addSelectedUser: Search user in', this.allUsers);
      const user = new List(this.allUsers).Where(u => u.firstname.toLocaleLowerCase().includes(this.filter) || u.lastname.toLocaleLowerCase().includes(this.filter)).First();
      userGroup.userId = user._id;
    }

    if(userGroup.userId === null || userGroup.userId === undefined) {
      throw 'GroupComponent.addSelectedUser: User not found';
    }

    await this.userGroupsServices.addUserGroup(userGroup).then((data: UserGroup) => {
      console.log('GroupComponent.addSelectedUser: User group added', data);
    });

    await this.getUserGroups();
  }
  
  public async updateGroup(): Promise<void> {
    return await this.groupsServices.updateGroup(this.group).then((data: Group) => {
      console.log('GroupComponent.updateGroup: NomenclatureCodification updated', data);
      this.router.navigate(['projects', 'project', this.selectedProject._id, 'groups']);
    });
  }

  public onFilterChanged() {
    console.log('GroupComponent.onFilterChanged: ', this.filter);
    this.filter = this.filter.toLocaleLowerCase();
    if(this.filter === null || this.filter === undefined || this.filter == '') {
      this.filteredOptions = this.allUsers;
    } else {
      if(this.filter.includes(' ')) {
        this.filteredOptions = Array<User>();
        const parts = this.filter.split(' ');
        for(let i = 0; i < parts.length; i++) {
          const part = parts[i];
          this.filteredOptions = this.filteredOptions.concat(new List(this.allUsers).Where(u => u.firstname.toLocaleLowerCase().includes(part) || u.lastname.toLocaleLowerCase().includes(part)).ToArray());
          if(this.filteredOptions.length > 0) {
            break;
          }
        }
      } else {
        this.filteredOptions = new List(this.allUsers).Where(u => u.firstname.toLocaleLowerCase().includes(this.filter) || u.lastname.toLocaleLowerCase().includes(this.filter)).ToArray();
      }
    }

    if(this.filteredOptions.length == 1) {
      this.selectedUser = this.filteredOptions[0];
    }
  }

  public async removeUserGroup(user: User) {

    const userGroup = new List(this.group.userGroups).First(ug => ug.userId === user._id);
    await this.userGroupsServices.deleteUserGroup(userGroup).then((data) => {
      console.log('GroupComponent.removeUserGroup: User group deleted', data);
    });

    await this.getUserGroups();
  }

  private async getUserGroups() {

    this.users = Array<User>();
    this.allUsers = Array<User>();
    this.filteredOptions = Array<User>();

    await this.userGroupsServices.getByGroup(this.group._id).then(data => {
      console.log('GroupComponent.ngOnInit : Get user groups', data);
      this.group.userGroups = data;
    });

    for(var i = 0; i < this.group.userGroups.length; i++) {
      const userGroup = this.group.userGroups[i];
      await this.usersServices.get(userGroup.userId).then(data => {
        console.log('GroupComponent.ngOnInit : Get user', data);
        this.users.push(data);
      });
    }

    this.dataSource = new MatTableDataSource<User>(this.users);

    // Etablir le jeu des users pour la recherche de users hors de ce groupe mais dans le projet
    let projectGroups = new Array<ProjectGroup>();
    await this.projectGroupsServices.getByProject(this.selectedProject).then(data => {
      console.log('GroupComponent.ngOnInit : Get project groups', data);
      projectGroups = data;
    });

    let allUserGroups = new Array<UserGroup>();
    for(var i = 0; i < projectGroups.length; i++) {
      const projectGroup = projectGroups[i];
      await this.userGroupsServices.getByGroup(projectGroup.groupId).then(data => {
        console.log('GroupComponent.ngOnInit : Get user groups', data);
        allUserGroups = allUserGroups.concat(data);
      });
    }

    for(var i = 0; i < allUserGroups.length; i++) {
      const userGroup = allUserGroups[i];
      await this.usersServices.get(userGroup.userId).then(data => {
        console.log('GroupComponent.ngOnInit : Get users', data);
        this.allUsers = this.allUsers.concat(data);
      });
    }

    this.filteredOptions = this.allUsers.filter(user => !this.users.some(u => u._id === user._id));
  }
}
