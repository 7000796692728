<mat-tab-group>
    <mat-tab label="{{ 'settings.maininformations' | translate }}">
        <div class="row mt-2" style="padding:25px">
            <div class="row">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'annotationtype.name' | translate }}</mat-label>
                    <input matInput [(ngModel)]="annotationType.name">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'annotationtype.color' | translate }}</mat-label>
                    <input matInput type="color" [(ngModel)]="annotationType.color">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                  <mat-select placeholder="{{ 'project.phase' | translate }}" [(ngModel)]="annotationType.phase">
                    <mat-option [value]="0">{{ 'project.phases.conception' | translate }}</mat-option>
                    <mat-option [value]="1">{{ 'project.phases.construction' | translate }}</mat-option>
                    <mat-option [value]="2">{{ 'project.phases.exploitation' | translate }}</mat-option>
                    <mat-option [value]="3">{{ 'project.phases.destruction' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <button mat-raised-button class="mt-3" color="basic" [routerLink]="['/projects', 'project', this.selectedProject._id, 'settingsannotations']">{{ 'generic.cancel' | translate }}</button>
                <button mat-raised-button class="mt-3" color="primary" (click)="addAnnotationType()">{{ 'generic.validate' | translate }}</button>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'settings.groups' | translate }}">
        <div class="row mt-2" style="padding:25px">
            <table mat-table [dataSource]="groupsDataSource">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'groups.name' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
                </ng-container>

                <ng-container matColumnDef="associate">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"><mat-slide-toggle (change)="associateGroup($event, element)"></mat-slide-toggle></td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedGroupsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedGroupsColumns;"></tr>
            </table>
  
            <mat-paginator *ngIf="groups?.length > 0"
                           [pageSizeOptions]="[5, 10, 20]"
                           showFirstLastButtons
                           aria-label="Select page">
            </mat-paginator>
        </div>
        
    </mat-tab>
</mat-tab-group>