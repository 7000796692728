import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Supplier } from "src/app/models/entities/suppliers/supplier.model";
import { Task } from "src/app/models/entities/tasks/task.model";
import { SuppliersServices } from "src/app/services/suppliers/suppliers.services";
import { TasksServices } from "src/app/services/tasks/tasks.services";

@Component({
  selector: 'edit-task-modal',
  templateUrl: 'edit.task.modal.html'
})
export class EditTaskModal {

  public suppliers: Array<Supplier> = new Array<Supplier>();
  
  constructor(
    public dialogRef: MatDialogRef<EditTaskModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private suppliersServices: SuppliersServices,
    private tasksServices: TasksServices
  ) {}

  async ngOnInit() {

    this.dialogRef.updateSize('80%', '90%');

    await this.suppliersServices.getSuppliers(this.data.project).then(data => {
      console.log('AddTaskModal.ngOnInit : Suppliers', data);
      this.suppliers = data;
    });

  } 

  public async deleteTask() {

    await this.tasksServices.deleteTask(this.data.task).then(data => {
      console.log('AddTaskModal.deleteTask : Is deleted', data);
    });

    this.dialogRef.close();
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}