<div class="row form-group">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ 'settings.groups' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div class="mt-3">

          <div class="row">

            <div class="col-md-1">
              <button mat-flat-button color="primary" [routerLink]="['addgroup']"><mat-icon>add</mat-icon> {{ 'generic.add' | translate }}</button>
            </div>

            <div class="col-md-11">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                      <mat-panel-title>
                          {{ 'groups.filters' | translate }}
                      </mat-panel-title>
                  </mat-expansion-panel-header>

                  <mat-form-field>
                      <input matInput type="text" name="projectName" placeholder="{{ 'groups.name' | translate }}" [(ngModel)]="searchedGroups.name" (input)="onSearchedGroupsChanged()">
                  </mat-form-field>

                  <div class="row form-group d-flex justify-content-center">
                      <button mat-raised-button color="primary" class="btn-block" (click)="resetGroupFilters()">{{ 'generic.cancel' | translate }}</button>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>

          </div>

        </div>

        <div class="mt-3">

          <div class="col-md-4 offset-md-5">
            <mat-spinner *ngIf="groups?.length === 0"></mat-spinner>
          </div>

          <table mat-table [dataSource]="groupsDataSource">

              <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{ 'groups.name' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
              </ng-container>

              <ng-container matColumnDef="users">
                <th mat-header-cell *matHeaderCellDef> {{ 'groups.users' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.userGroups?.length }} </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['group', element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
              </ng-container>

              <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteGroup(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedGroupsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedGroupsColumns;"></tr>
          </table>

          <mat-paginator *ngIf="groups?.length > 0"
                         [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page">
          </mat-paginator>
        </div>


      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
