import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';

import { DataAccessServices } from '../../../services/data.access.services';
import { GoogleMessagingServices } from 'src/app/services/commons/google.messaging.services';
import { FilesServices } from '../../../services/files/files.services';
import { NomenclatureTypeFilesServices } from '../../../services/files/nomenclature.type.files.services';
import { RevisionFilesServices } from '../../../services/files/revision.files.services';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';

import { Project } from '../../../models/entities/projects/project.model';
import { ProjectFile } from '../../../models/entities/files/file.model';
import { NomenclatureCodification } from '../../../models/entities/projects/nomenclature.codification.model';
import { NomenclatureCategory } from '../../../models/entities/projects/nomenclature.category.model';
import { NomenclatureType } from '../../../models/entities/projects/nomenclature.type.model';
import { NomenclatureTypeFile } from '../../../models/entities/files/nomenclature.type.file.model';
import { RevisionFile } from '../../../models/entities/files/revision.file.model';
import { Group } from 'src/app/models/entities/users/group.model';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { UsersServices } from 'src/app/services/users/users.services';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent {

  @ViewChild('codificationTabs') codificationTabs: MatTabGroup;

  public files: Array<ProjectFile> = new Array<ProjectFile>();
  public documentsCount: number = 0;
  public displayedColumns: string[] = ['title', 'lastversion', 'owner', 'view', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<ProjectFile>(this.files);
  public selectedProject: Project = null;
  public nomenclatureCodifications: Array<NomenclatureCodification> = new Array<NomenclatureCodification>();
  public nomenclatureCodificationPageIndex: number = 1;
  public nomenclatureCodificationPageSize: number = 25;
  public nomenclatureCodificationTotal: number = 0;
  public nomenclatureCategories: Array<NomenclatureCategory> = new Array<NomenclatureCategory>();
  public nomenclatureCategoryPageIndex: number = 1;
  public nomenclatureCategoryPageSize: number = 25;
  public nomenclatureCategoryTotal: number = 0;
  public selectedTypes: Array<NomenclatureType> = new Array<NomenclatureType>();
  public mandatorySelectControlHasError: boolean;
  public revisionFile: RevisionFile = new RevisionFile();
  public projectFile: ProjectFile = new ProjectFile();
  public uploadingFiles: boolean = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersServices,
    private groupsServices: GroupsServices,
    private userGroupsServices: UserGroupsServices,
    public dataAccessServices: DataAccessServices,
    private filesServices: FilesServices,
    private revisionFilesServices: RevisionFilesServices,
    private nomenclatureTypeFilesServices: NomenclatureTypeFilesServices) {

    this.activatedRoute.params.subscribe(params => {
      console.log('FilesComponent.constructor : Retrieved params', params);
      let id = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }
    });
  }

  public async ngOnInit() {
    console.log('FilesComponent.ngOnInit : Start');
    await this.getProjectFiles();
  }

  public onSelectCategoryChanged(event: any): void {
    console.log("FilesComponent.onSelectCategoryChanged: ", event);
    this.selectedTypes.push(event.value);
  }

  public async uploadFiles(files: any[]): Promise<void> {
    this.uploadingFiles = true;
    if (this.selectedTypes.length === this.nomenclatureCategories.length) {
      for (let i = 0; i < files.length; i++) {
        const dataFile = files[i];
        console.log('FilesComponent.uploadFiles : ', dataFile);
        
        this.projectFile.projectId = this.selectedProject._id;
        this.projectFile.originalTitle = dataFile.name;
        this.projectFile.title = files.length > 1 ? `${i}-${this.projectFile.title}` : this.projectFile.title;
        await this.filesServices.addFile(this.projectFile).then((data: ProjectFile) => {
          console.log('FilesComponent.uploadFiles: File added', data);
          this.projectFile = data;
          this.files.unshift(data);
          this.files = new List(this.files).OrderByDescending(r => r.creation).ToArray();
          this.dataSource = new MatTableDataSource<ProjectFile>(this.files);
        });

        //Add revision file
        await this.revisionFilesServices.uploadFileProgressEvent.subscribe((progress: number) => {
          console.log('FilesComponent.uploadFiles : progress received', progress);
          this.revisionFile.progressUpload = progress;
        });

        this.revisionFile.data = dataFile;
        this.revisionFile.projectFileId = this.projectFile._id;
        this.revisionFile.ownerId = this.dataAccessServices.currentUser._id;

        await this.revisionFilesServices.uploadRevisionFile(this.selectedProject, this.projectFile, this.revisionFile).then((data: RevisionFile) => {
          console.log('FilesComponent.uploadFiles : Upload file finished', data);
          this.revisionFile = data;
        });

        await this.revisionFilesServices.getDownloadUrl(this.selectedProject, this.revisionFile).then((data: RevisionFile) => {
          console.log('FileComponent.uploadFiles : Get download url finished', data);
          this.revisionFile = data;
        });

        await this.revisionFilesServices.addRevisionFile(this.revisionFile).then((data: RevisionFile) => {
          console.log('FilesComponent.uploadFiles : RevisionFile added', data);
        });

        // Notifier tous les utilisateurs des groupes de documents techniques
        let technicalDocumentGroups = new Array<Group>();
        await this.groupsServices.getGroups(this.selectedProject).then(data => {
          technicalDocumentGroups = new List(data).Where(g => g.isDocumentsValidators === true).ToArray();
        });
        
        for(let i = 0; i < technicalDocumentGroups.length; i++) {
          const group = technicalDocumentGroups[i];
          await this.userGroupsServices.getByGroup(group._id).then((data: Array<UserGroup>) => {
            for(let j = 0; j < data.length; j++) {
              const userGroup = data[j];
              //this.googleMessagingServices.sendMessage(this.translateService.instant('notification.addtechnicaldocumenttitle'), this.translateService.instant('notification.addtechnicaldocumentbody'), userGroup.userId);
            }
          });
        }
      }
      
      this.selectedTypes = new Array<NomenclatureType>();
    } else {
      console.warn('FilesComponent.uploadFiles : Mandatory fields are required');
      this.mandatorySelectControlHasError = true;
    }
    this.uploadingFiles = false;
  }

  public async viewFile(projectFile: ProjectFile) {
    let revisionFiles = new Array<RevisionFile>();
    await this.revisionFilesServices.getRevisionFiles(projectFile).then((data: Array<RevisionFile>) => {
      revisionFiles = new List(data).OrderByDescending(r => r.creation).ToArray();
    });
    const lastRevisionFile = new List(revisionFiles).First();
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'viewfile', 'construction', lastRevisionFile._id]);
  }

  public editFile(file: ProjectFile): void {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'files', 'file', file._id]);
  }

  public deleteFile(file: ProjectFile): void {
    this.filesServices.deleteFile(file).then((isDeleted: boolean) => {
      console.log('FilesComponent.deleteFile : The file is deleted');
      new List(this.files).Remove(file);
      this.files = new List(this.files).OrderByDescending(r => r.creation).ToArray();
      this.dataSource = new MatTableDataSource<ProjectFile>(this.files);
    });
  }

  public getNomenclatureType(file: ProjectFile, nomenclatureCategory: NomenclatureCategory): string {
    //console.log('FilesComponent.getNomenclatureType: Start to get NomenclatureType from File and NomenclatureCategory', file, nomenclatureCategory, file.nomenclatureTypes);
    let result = '';
    if (file.nomenclatureTypes !== null && file.nomenclatureTypes !== undefined) {
      const nomenclatureType = new List(file.nomenclatureTypes).FirstOrDefault(t => t.nomenclatureCategory._id === nomenclatureCategory._id);
      if (nomenclatureType !== null && nomenclatureType !== undefined) {
        result = nomenclatureType?.name;
      }
    }
    return result;
  }

  private async getProjectFiles(): Promise<void> {
    await this.filesServices.getFiles(this.selectedProject).then((files: Array<ProjectFile>) => {
      console.log('FilesComponent.getProjectFiles: Files', files);
      this.files = files;
      this.files = new List(this.files).OrderByDescending(r => r.creation).ToArray();
      this.dataSource = new MatTableDataSource<ProjectFile>(this.files);
    });

    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      await this.revisionFilesServices.getRevisionFiles(file).then(async (revisionFilesData: Array<RevisionFile>) => {
        console.log('FilesComponent.getProjectFiles: RevisionFiles', revisionFilesData);
        for (let j = 0; j < revisionFilesData.length; j++) { 
          const revisionFile = revisionFilesData[j];
          await this.usersServices.get(revisionFile.ownerId).then(userData => {
            console.log('FilesComponent.getProjectFiles: User', userData);
            revisionFile.owner = `${userData.firstname} ${userData.lastname}`;
          });
        }
        file.revisionFiles = new List(revisionFilesData).OrderByDescending(r => r.creation).ToArray();
      });
    }

    let nomenclatureTypeFiles = new Array<NomenclatureTypeFile>();

    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      await this.nomenclatureTypeFilesServices.getNomenclatureTypeFiles(file).then((data: Array<NomenclatureTypeFile>) => {
        console.log('FilesComponent.getProjectFiles: NomenclatureTypeFiles', data);
        nomenclatureTypeFiles = data;
      });
    }
  }

}
