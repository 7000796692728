<div class="row form-group">
    <div class="col-md-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ 'settings.annotationtypes' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
  
          <div class="mt-3">
  
            <div class="row">
  
              <div class="col-md-1">
                <button mat-flat-button color="primary" [routerLink]="['add']"><mat-icon>add</mat-icon> {{ 'generic.add' | translate }}</button>
              </div>
  
              <div class="col-md-11">
                <mat-accordion>
                  <mat-expansion-panel (opened)="panelOpenState = true"
                                       (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'groups.filters' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
  
                    <mat-form-field>
                        <input matInput type="text" name="projectName" placeholder="{{ 'groups.name' | translate }}" [(ngModel)]="searchedAnnotationTypes.name" (input)="onSearchedAnnotationTypesChanged()">
                    </mat-form-field>
  
                    <div class="row form-group d-flex justify-content-center">
                        <button mat-raised-button color="primary" class="btn-block" (click)="resetAnnotationTypeFilters()">{{ 'generic.cancel' | translate }}</button>
                    </div>
  
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
  
            </div>
  
          </div>
  
          <div class="mt-3">
  
            <table mat-table [dataSource]="annotationTypesDataSource">
  
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'annotationtype.name' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
                </ng-container>

                <ng-container matColumnDef="color">
                    <th mat-header-cell *matHeaderCellDef> {{ 'annotationtype.color' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.color }} </td>
                </ng-container>

                <ng-container matColumnDef="phase">
                  <th mat-header-cell *matHeaderCellDef> {{ 'projects.phase' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                      <span [ngSwitch]="element.phase">
                        <span *ngSwitchCase="0" class="badge-warning text-center"> {{ 'project.phases.conception' | translate }} </span>
                        <span *ngSwitchCase="1" class="badge-success text-center"> {{ 'project.phases.construction' | translate }} </span>
                        <span *ngSwitchCase="2" class="badge-warning text-center"> {{ 'project.phases.exploitation' | translate }} </span>
                        <span *ngSwitchCase="3" class="badge-danger text-center"> {{ 'project.phases.destruction' | translate }} </span>
                      </span>
                  </td>
              </ng-container>

                <ng-container matColumnDef="modification">
                    <th mat-header-cell *matHeaderCellDef> {{ 'annotationtype.modification' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.modification | date:'short' }} </td>
                </ng-container>

                <ng-container matColumnDef="creation">
                    <th mat-header-cell *matHeaderCellDef> {{ 'annotationtype.creation' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.creation | date:'short' }} </td>
                </ng-container>
  
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['annotationtype', element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
                </ng-container>
  
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteAnnotationType(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="annotationTypesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: annotationTypesDisplayedColumns;"></tr>
            </table>
  
            <mat-paginator *ngIf="annotationTypes?.length > 0"
                           [pageSizeOptions]="[5, 10, 20]"
                           showFirstLastButtons
                           aria-label="Select page">
            </mat-paginator>
          </div>
  
  
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
    </div>
</div>
  