import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { List } from 'linqts';

import { DataAccessServices } from './../../services/data.access.services';
import { UsersServices } from './../../services/users/users.services';
import { SettingsServices } from './../../services/commons/settings.services';
import { MatTableDataSource } from '@angular/material/table';
import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';
import { ProjectFile } from 'src/app/models/entities/files/file.model';
import { RevisionFilesServices } from 'src/app/services/files/revision.files.services';
import { FilesServices } from 'src/app/services/files/files.services';
import { RevisionFileStatus } from 'src/app/models/entities/files/revision.file.status.enum';
import { Alarm } from 'src/app/models/entities/alarms/alarm.model';
import { GoogleMessagingServices } from 'src/app/services/commons/google.messaging.services';
import { AlarmsServices } from 'src/app/services/alarms/alarms.services';
import { Material } from 'src/app/models/entities/suppliers/material.model';
import { MaterialsServices } from 'src/app/services/suppliers/materials.services';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Project } from 'src/app/models/entities/projects/project.model';
import { environment } from 'src/environments/environment';
import { BaseChartDirective } from 'ng2-charts';

declare const google: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public email: string = null;
  public password: string = null;
  public hidePassword: boolean = true;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public graphData: any[] = [];

  private projectFiles: Array<ProjectFile> = new Array<ProjectFile>();
  public displayedFileColumns: string[] = ['title', 'lastversion', 'ownerId', 'creation'];
  public fileDataSource = new MatTableDataSource<ProjectFile>(this.projectFiles);
  public revisionFilesToReview: number;

  public displayedAlarmColumns: string[] = ['projectName', 'slotName', 'sensorType', 'topic', 'sensorValue', 'creation'];
  public alarmDataSource = new MatTableDataSource<Alarm>(this.dataAccessServices.alarms);

  private materials: Array<Material> = new Array<Material>();

  constructor(
    private httpClient: HttpClient,
    public settingsServices: SettingsServices,
    private googleMessagingServices: GoogleMessagingServices,
    private alarmsServices: AlarmsServices,
    public dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private revisionFilesServices: RevisionFilesServices,
    private filesServices: FilesServices,
    private materialsServices: MaterialsServices
  ) {

    console.log(`DashboardComponent.constructor : Cache refreshed ${this.dataAccessServices.iscacherefreshed}`);

    this.googleMessagingServices.messageReceived.subscribe(async message => {
      console.log(`DashboardComponent.constructor : messageReceived ${JSON.stringify(message)}`);
      const alarm = await this.alarmsServices.getAlarm(message.data._id);
      this.dataAccessServices.alarms.unshift(alarm);
      this.alarmDataSource = new MatTableDataSource<Alarm>(this.dataAccessServices.alarms);
    });
   }

  public async ngOnInit() {
    
  }

  public async ngAfterViewInit() {
    console.log('DashboardComponent.ngAfterViewInit: Start to initialize dashboard');

    for(let i = 0; i < this.dataAccessServices.projects.length; i++) {
      const project = this.dataAccessServices.projects[i];
      await this.filesServices.getFiles(project).then(data => {
        console.log('DashboardComponent.ngAfterViewInit: Project files found', project, data);
        this.projectFiles = this.projectFiles.concat(data);
      });

      await this.materialsServices.getMaterials(project).then(data => {
        console.log('DashboardComponent.ngAfterViewInit: Materials', project, data);
        this.materials = this.materials.concat(data);
      });
    }

    const lastFiles = new List(this.projectFiles).OrderByDescending(f => f.modification).Take(10).ToArray();
    for(let i = 0; i < lastFiles.length; i++) {
      const projectFile = lastFiles[i];
      let firstRevisionFile = null;
      await this.revisionFilesServices.getRevisionFiles(projectFile).then(data => {
        firstRevisionFile = new List(data).OrderByDescending(f => f.modification).First();
      });
      projectFile.lastVersion = firstRevisionFile.version;
      await this.usersServices.get(firstRevisionFile.ownerId).then(data => {
        projectFile.owner = `${data.firstname} ${data.lastname}`;
      });
    }
    this.fileDataSource = new MatTableDataSource<ProjectFile>(lastFiles);

    let revisionFiles = new Array<RevisionFile>();
    for(let i = 0; i < this.projectFiles.length; i++) {
      const projectFile = this.projectFiles[i];
      await this.revisionFilesServices.getRevisionFiles(projectFile).then(data => {
        console.log('DashboardComponent.ngAfterViewInit: Revision files found', projectFile, data);
        revisionFiles = revisionFiles.concat(data);
      });
    }

    console.log('DashboardComponent.ngAfterViewInit: Revisionfiles found', revisionFiles);
    this.revisionFilesToReview = new List(revisionFiles).Where(rf => rf.status === RevisionFileStatus.proofread).Count();

    let data: any = [];
    for(let i = 0; i < this.materials.length; i++) {
      const material = this.materials[i];
      const project = new List(this.dataAccessServices.projects).First(p => p._id === material.projectId);

      const distance = await this.computeMaterialDistance(project, material); // Distance juste en retour de chargement, prend pas en compte l'allez
      const totalDistanceInKm = distance / 1000; // Convertir la distance en mètres en kilomètres
      console.log('DashboardComponent.ngAfterViewInit: Distance (Km)', totalDistanceInKm);
      const consumedGas = (environment.ecology.truckConsumption / 100) * totalDistanceInKm;
      console.log('DashboardComponent.ngAfterViewInit: Gas consumed (L)', consumedGas);
      const rejectedCO2 = consumedGas * environment.ecology.kgCO2PerGasLiter;
      console.log('DashboardComponent.ngAfterViewInit: CO2 rejected (Kg)', rejectedCO2);

      // Nombre de chargement
      if(material.quantity !== undefined && material.quantity !== null && material.quantity > 0) {
        const numberOfTrucks = Math.ceil(material.quantity / environment.ecology.truckCapacity);
        console.log('DashboardComponent.ngAfterViewInit: numberOfTrucks', numberOfTrucks);
        const rejectedCO2Total = rejectedCO2 * numberOfTrucks;
        console.log('DashboardComponent.ngAfterViewInit: Total CO2 rejected (Kg)', rejectedCO2Total);
        data.push({
          name: material.name,
          value: rejectedCO2Total,
        });
        if (this.chart) {
          console.log('DashboardComponent.ngAfterViewInit: Chart is not null');
          this.chart.ngOnChanges({});
        }
      }
    }

    this.graphData = data;
  }

  private computeMaterialDistance(project: Project, material: Material): Promise<number> {
    console.log('DashboardComponent.computeMaterialDistance: Compute distance for', project, material);

    return new Promise<number>((resolve, reject) => {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [project.address],
                destinations: [material.originalAddress],
                travelMode: 'DRIVING',
                unitSystem: google.maps.UnitSystem.METRIC
            }, (response, status) => {
                if (status == 'OK') {
                    const distance = response.rows[0].elements[0].distance.value;
                    resolve(distance);
                } else {
                    console.log('Erreur: ' + status);
                    reject(status);
                }
            });
    });
  }
}
