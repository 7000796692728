import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { AddProjectStatusComponent } from './add/add.project.status.component';

const routes: Routes = [
  { path: 'addprojectstatus', component: AddProjectStatusComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ProjectStatusRoutingModule { }
