import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';

import { ProjectStatusRoutingModule } from './project.status.routing.module';

import { AddProjectStatusComponent } from './add/add.project.status.component';


@NgModule({
  declarations: [
    AddProjectStatusComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),

    MaterialModule,
    SharedModule,

    ProjectStatusRoutingModule,
  ],
  providers: [],
  bootstrap: []
})
export class ProjectStatusModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
