import { EntityBase } from "../entity.base.model";
import { RevisionFileStatus } from "./revision.file.status.enum";

export class RevisionFile extends EntityBase {
  projectFileId: string;
  name: string;
  comment: string;
  decodedUrn: string;
  urn: string;
  downloadURL: string;
  ownerId: string;
  version: string;
  status: RevisionFileStatus;
  userValidatedId: string;
  signature: string;

  owner: string;
  data: File;
  isAutoDeskComputing: boolean = false;
  progressUpload: number = 0;
  progressAutoDesk: string;
}
