import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { Sensor } from 'src/app/models/entities/iot/sensors/sensor.model';
import { BoxSensor } from 'src/app/models/entities/iot/sensors/box.sensor.model';
import { SensorTrace } from 'src/app/models/entities/iot/sensors/sensor.trace.model';

@Injectable({
  providedIn: 'root'
})
export class SensorsTracesServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getSensorTraces(sensor: Sensor): Promise<Array<SensorTrace>> {
    console.log('SensorsTracesServices.getSensorTraces: Start to get sensor traces', sensor);

    return new Promise<Array<SensorTrace>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SensorsTracesServices.getSensorTraces : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<SensorTrace[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `sensor-traces`, filter: { 'sensorId': sensor._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('SensorsTracesServices.getSensorTraces : Sensor traces', data);
        resolve(data.documents);
      });
      
    });
  }
}
