import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { Project } from '../../../models/entities/projects/project.model';
import { UserProfile } from 'src/app/models/entities/users/user.profile.enum';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { Group } from 'src/app/models/entities/users/group.model';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { ProjectGroupsServices } from 'src/app/services/projects/project.groups.services';
import { Material } from 'src/app/models/entities/suppliers/material.model';
import { MaterialsServices } from 'src/app/services/suppliers/materials.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';

@Component({
  selector: 'app-add-material',
  templateUrl: './add.material.component.html',
  styleUrls: ['./add.material.component.scss']
})
export class AddMaterialComponent {

  public selectedProject: Project = null;
  public suppliers: Array<Supplier> = new Array<Supplier>();
  public material: Material = new Material();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private materialsServices: MaterialsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('AddMaterialComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
    }

  async ngOnInit() {
    await this.suppliersServices.getSuppliers(this.selectedProject).then(data => {
      console.log('AddMaterialComponent.ngOnInit : Suppliers', data);
      this.suppliers = data;
    });
  }

  public async addMaterial(): Promise<void> {
    this.material.projectId = this.selectedProject._id;
    await this.materialsServices.addMaterial(this.material).then((data: Material) => {
      console.log('AddMaterialComponent.addMaterial: Material added', data);
      this.material = data;
    });

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'materials']);
  }
}
