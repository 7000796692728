import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { FilesServices } from '../../../services/files/files.services';
import { RevisionFilesServices } from '../../../services/files/revision.files.services';

import { Project } from '../../../models/entities/projects/project.model';
import { ProjectFile } from '../../../models/entities/files/file.model';
import { NomenclatureCategory } from '../../../models/entities/projects/nomenclature.category.model';
import { RevisionFile } from '../../../models/entities/files/revision.file.model';
import { UsersServices } from 'src/app/services/users/users.services';
import { RevisionFileStatus } from 'src/app/models/entities/files/revision.file.status.enum';
import { MatDialog } from '@angular/material/dialog';
import { AddRevisionFileCommentModal } from 'src/app/shared/components/modals/revisionfiles/add.revision.file.comment.modal';
import { RevisionFileComment } from 'src/app/models/entities/files/revision.file.comment.model';
import { RevisionFileCommentsServices } from 'src/app/services/files/revision.file.comments.services';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent {

  public files: Array<ProjectFile> = new Array<ProjectFile>();
  public selectedProject: Project = null;
  public revisionFileComments: Array<RevisionFileComment> = new Array<RevisionFileComment>(); 

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private filesServices: FilesServices,
    private revisionFilesServices: RevisionFilesServices,
    private revisionFileCommentsServices: RevisionFileCommentsServices) {

    this.activatedRoute.params.subscribe(params => {
      console.log('FilesComponent.constructor : Retrieved params', params);
      let id = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }
    });
  }

  public async ngOnInit() {
    console.log('FilesComponent.ngOnInit : Start');
    await this.getProjectFiles();
  }

  public editRevisionFile(revisionFile: RevisionFile) {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'edit', revisionFile._id]);
  }

  public async viewFile(revisionFile: RevisionFile) {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'viewfile', revisionFile._id]);
  }

  public editFile(file: ProjectFile): void {
    this.router.navigate(['projects', 'project', this.selectedProject._id, 'files', 'file', file._id]);
  }

  public deleteFile(file: ProjectFile): void {
    this.filesServices.deleteFile(file).then((isDeleted: boolean) => {
      console.log('FilesComponent.deleteFile : The file is deleted');
      new List(this.files).Remove(file);
      this.files = new List(this.files).OrderBy(r => r.creation).ToArray();
    });
  }

  private async getProjectFiles(): Promise<void> {
    await this.filesServices.getFiles(this.selectedProject).then((files: Array<ProjectFile>) => {
      console.log('FilesComponent.getProjectFiles: Files', files);
      this.files = files;
      this.files = new List(this.files).OrderByDescending(r => r.creation).ToArray();
    });

    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      let revisionFiles: Array<RevisionFile> = Array<RevisionFile>();
      await this.revisionFilesServices.getRevisionFiles(file).then((data: Array<RevisionFile>) => {
        console.log('FilesComponent.getProjectFiles: RevisionFiles', data);
        revisionFiles = new List(data)
        .Where(rf => rf.status === RevisionFileStatus.proofread || rf.status === RevisionFileStatus.changerequest)
        .OrderBy(r => r.modification).ToArray();
      });

      for (let j = 0; j < revisionFiles.length; j++) { 
        const revisionFile = revisionFiles[j];
        await this.usersServices.get(revisionFile.ownerId).then(data => {
          revisionFile.owner = `${data.firstname} ${data.lastname}`;
        });
      }

      file.revisionFiles = revisionFiles;
    }
  }

}
