import { EntityBase } from "../../entity.base.model";
import { BoxConfiguration } from "./box.configuration.model";

export class Box extends EntityBase {
  serialNumber: string;
  macAddress: string;
  ipAddress: string;
  version: string;

  configuration: BoxConfiguration;
}
