import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Task } from 'src/app/models/entities/tasks/task.model';

@Injectable({
  providedIn: 'root'
})
export class TasksServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getTasks(project: Project): Promise<Array<Task>> {
    console.log('TasksServices.getTasks: Start to get tasks', project);

    return new Promise<Array<Task>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('TasksServices.getTasks : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Task[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `tasks`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('TasksServices.getTasks : Tasks', data);
        resolve(data.documents);
      });
    });
  }

  public async addTask(task: Task): Promise<Task> {
    console.log('TasksServices.addTask: Start to add task', task);

    return new Promise<Task>(async (resolve, reject) => {

      const taskData = { 
        projectId: task.projectId,
        ownerId: task.ownerId, 
        supplierId: task.supplierId,
        name: task.name,
        description: task.description,
        type: task.type, 
        date: task.date, 
        start: task.start, 
        end: task.end, 
        color: task.color, 
        parent: task.parent, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('TasksServices.addTask : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `tasks`, document: taskData }, httpOptions).toPromise().then((data: any) => {
        console.log('TasksServices.addTask : Task added', data);
        task._id = data.insertedId;
        resolve(task);
      });

    });
  }

  public async updateTask(task: Task): Promise<Task> {
    console.log('TasksServices.updateTask: Start to update task', task);

    return new Promise<Task>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('TasksServices.updateTask : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const taskData = { 
        name: task.name, 
        description: task.description, 
        color: task.color, 
        start: task.start, 
        end: task.end, 
        modification: new Date()
      };
        
      await this.httpClient.post<Task>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `tasks`, filter: { '_id': { $eq: { $oid: task._id } } }, update: { $set: taskData } }, httpOptions).toPromise().then((data: any) => {
        console.log('TasksServices.updateTask : Task updated', data);
        if(data.matchedCount > 0) {
          resolve(task);
         } else {
          reject(data);
        }
      });
    });
  }

  public async deleteTask(task: Task): Promise<boolean> {
    console.log('TasksServices.deleteTask: Start to delete task', task);

    return new Promise<boolean>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('TasksServices.deleteTask : Token', data);
        token = data;
      });

      const deleteUrl = `${environment.mongoapi.apiDeleteOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Task>(deleteUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `tasks`, filter: { '_id': { $oid: task._id } } }, httpOptions).toPromise().then((data: any) => {
        console.log('TasksServices.deleteTask : Task deleted', data);
        if(data.deletedCount > 0) {
          resolve(true);
         } else {
          reject(data);
        }
      });
    });

  }
}
