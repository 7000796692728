import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { ItemsPaginationResponse } from '../../models/contracts/items.pagination.response.contract';
import { Project } from '../../models/entities/projects/project.model';
import { Group } from '../../models/entities/users/group.model';
import { RealmTokenServices } from '../commons/realm.token.services';

@Injectable({
  providedIn: 'root'
})
export class GroupsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getGroups(project: Project): Promise<Array<Group>> {
    console.log('GroupsServices.getGroups: Start to get NomenclatureCategories', project);

    return new Promise<Array<Group>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('GroupsServices.getGroups : Token', data);
        token = data;
      });
  
      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Group>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `groups`, filter: { 'projectId': { $eq: project._id } } }, httpOptions).toPromise().then((data: any) => {
        console.log('GroupsServices.getGroups : Groups', data);
        resolve(data.documents);
      });
    });

  }

  public async getGroup(groupId: string): Promise<Group> {
    console.log('GroupsServices.getGroup: Start to get Group', groupId);

    return new Promise<Group>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('GroupsServices.getGroup : Token', data);
        token = data;
      });
  
      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Group>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `groups`, filter: { '_id': { $eq: { $oid: groupId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('GroupsServices.getGroup : Group', data);
        resolve(data.document);
      });
    });
  }

  public async addGroup(group: Group): Promise<Group> {
    console.log('GroupsServices.addGroup: Start to add NomenclatureCategory', group);

    return new Promise<Group>(async (resolve, reject) => {
      const groupData = { 
        name: group.name, 
        projectId: group.projectId, 
        isDocumentsValidators: group.isDocumentsValidators,
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('GroupsServices.addGroup : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Group>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `groups`, document: groupData }, httpOptions).toPromise().then((data: any) => {
        console.log('GroupsServices.addGroup : Group added', data);
        group._id = data.insertedId;
        resolve(group);
      });
    });

  }

  public async updateGroup(group: Group): Promise<Group> {
    console.log('GroupsServices.updateGroup: Start to update group', group);

    return new Promise<Group>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('GroupsServices.updateGroup : Token', data);
        token = data;
      });

      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const groupData = { 
        name: group.name,
        isDocumentsValidators: group.isDocumentsValidators,  
        modification: new Date()
      };
      
      await this.httpClient.post<Group>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `groups`, filter: { '_id': { $eq: { $oid: group._id } } }, update: { $set: groupData } }, httpOptions).toPromise().then((data: any) => {
        console.log('GroupsServices.updateGroup : Group updated', data);
        if(data.matchedCount > 0) {
          resolve(group);
         } else {
          reject(data);
        }
      });
    });

  }

  public async deleteGroup(group: Group): Promise<boolean> {
    console.log('GroupsServices.deleteGroup: Start to delete Group', group);

    return new Promise<boolean>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('GroupsServices.deleteGroup : Token', data);
        token = data;
      });

      const deleteUrl = `${environment.mongoapi.apiDeleteOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Group>(deleteUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `groups`, filter: { '_id': { $oid: group._id } } }, httpOptions).toPromise().then((data: any) => {
        console.log('GroupsServices.deleteGroup : Group deleted', data);
        if(data.deletedCount > 0) {
          resolve(true);
         } else {
          reject(data);
        }
      });
    });

  }

}
