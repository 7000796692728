import { EntityBase } from "../entity.base.model";
import { QuoteStatus } from "./quote.status.enum";

export class Quote extends EntityBase {
  supplierId: string;
  originalTitle: string;
  documentTitle: string;
  name: string;
  comment: string;
  downloadURL: string;
  ownerId: string;
  status: QuoteStatus;
  amount: number;

  supplier: string;
  owner: string;
  progressUpload: number;
  data: File;
}
