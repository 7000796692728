<h1 mat-dialog-title>{{ 'modal.edittask.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.supplier' | translate }}</mat-label>
      <mat-select name="parent" [(ngModel)]="data.task.supplierId">
        <mat-option *ngFor="let supplier of suppliers" [value]="supplier._id">{{ supplier.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.name' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.task.name">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.description' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="data.task.description"></textarea>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.durationvalue' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.task.durationDayValue">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.start' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.task.start">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.end' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.task.end">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.color' | translate }}</mat-label>
      <input matInput type="color" [(ngModel)]="data.task.color">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'modal.edittask.parent' | translate }}</mat-label>
      <mat-select name="parent" [(ngModel)]="data.task.parent">
        <mat-option *ngFor="let item of data.tasks" [value]="item._id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button (click)="deleteTask()">{{ 'generic.delete' | translate }}</button>
  <button mat-button [mat-dialog-close]="data.task" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>