import { EntityBase } from "../entity.base.model";
import { NomenclatureType } from "../projects/nomenclature.type.model";
import { RevisionFile } from "./revision.file.model";

export class ProjectFile extends EntityBase {
  originalTitle: string;
  title: string;
  description: string;
  projectId: string;
  nomenclatureCodificationId: number;

  nomenclatureTypes: Array<NomenclatureType>;
  revisionFiles: Array<RevisionFile>;
  owner: string;
  lastVersion: string;
}
