import { EntityBase } from "../entity.base.model";

export class Campaign extends EntityBase {
  projectId: string;
  name: string;
  description: string;
  color: string;
  startDate: Date;
  endDate: Date;

  visible: boolean = true;
}
