import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { List } from 'linqts';

@Component({
  selector: 'upload-files',
  templateUrl: './upload.files.component.html',
  styleUrls: ['./upload.files.component.scss'],
})
export class UploadFilesComponent {

  @Input() public uploadingFiles: boolean = false;

  @Output() public uploadDocumentsClicked: EventEmitter<any[]> = new EventEmitter();
  @Output() public documentsCountchanged: EventEmitter<number> = new EventEmitter();

  public documentsCount: number = 0;
  public originalFiles: any[] = [];

  public onClicked(event: any): void {

  }

  public onFileDropped(event: any): void {
    console.log("UploadFilesComponent.onFileDropped: ", event);
    this.prepareFilesList(event);
  }

  public fileBrowseHandler(event: any) {
    console.log("UploadFilesComponent.fileBrowseHandler: ", event);
    this.prepareFilesList(event.target.files);
  }

  public removeFile(index: number) {
    this.originalFiles.splice(index, 1);
    this.documentsCount = this.originalFiles.length;
    this.documentsCountchanged.emit(this.documentsCount);
  }

  public formatBytes(bytes, decimals = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private prepareFilesList(files: Array<any>) {
    for (const file of files) {
      file.uploading = false;
      file.progress = 0;
      this.originalFiles.push(file);
    }
    this.documentsCount = this.originalFiles.length;
    this.documentsCountchanged.emit(this.documentsCount);
  }

  public uploadFiles(): void {
    console.log("UploadFilesComponent.uploadFiles: Start to upload", this.originalFiles);
    this.uploadDocumentsClicked.emit(this.originalFiles);
  }
}
