import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataAccessServices } from 'src/app/services/data.access.services';

import { BoxesServices } from 'src/app/services/iot/boxes.services';

import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { SensorsServices } from 'src/app/services/iot/sensors.services';
import { Sensor } from 'src/app/models/entities/iot/sensors/sensor.model';
import { SensorsTracesServices } from 'src/app/services/iot/sensors.traces.services';
import { SensorTrace } from 'src/app/models/entities/iot/sensors/sensor.trace.model';
import { List } from 'linqts';
import { Project } from 'src/app/models/entities/projects/project.model';
import { BoxConfigurationsServices } from 'src/app/services/iot/box.configurations.services';
import { BoxConfiguration } from 'src/app/models/entities/iot/boxes/box.configuration.model';
import { SlotsServices } from 'src/app/services/projects/slots.services';
import { Slot } from 'src/app/models/entities/projects/slot.model';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent {

  public selectedProject: Project;
  public selectedBox: Box = null;
  public selectedBoxConfiguration: BoxConfiguration = null;
  public sensors: Array<Sensor> = new Array<Sensor>();
  public tracesData: any[] = [];

  private traces = new Map<Sensor, Array<SensorTrace>>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataAccessServices: DataAccessServices,
    private boxesServices: BoxesServices,
    private sensorsServices: SensorsServices,
    private sensorsTracesServices: SensorsTracesServices,
    private boxConfigurationsServices: BoxConfigurationsServices,
    private slotsServices: SlotsServices) {
  }

  public async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      console.log('BoxComponent.ngOnInit : Retrieved params', params);
      const projectId = params['projectid'];
      const boxId = params['boxid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
      await this.boxesServices.getBox(boxId).then(data => {
        console.log('BoxComponent.ngOnInit : Box', data);
        this.selectedBox = data;
      });

      await this.boxConfigurationsServices.getBoxConfigurationByBoxId(boxId).then((data: BoxConfiguration) => {
        this.selectedBoxConfiguration = data;
      });

      await this.slotsServices.getSlot(this.selectedBoxConfiguration.slotId).then((data: Slot) => {
        this.selectedBoxConfiguration.slot = data;
      });

      await this.sensorsServices.getSensors(this.selectedBox).then(data => {
        console.log('BoxComponent.ngOnInit : Sensors', data);
        this.sensors = data;
      });

      for(let i = 0; i < this.sensors.length; i++) {
        const sensor = this.sensors[i];
        await this.sensorsTracesServices.getSensorTraces(sensor).then(data => {
          console.log('BoxComponent.ngOnInit : Sensor traces', data);
          this.traces.set(sensor, data);
        });
      }

      let temp = [];
      for (const [key, value] of this.traces.entries()) {
        const sensorTraceTopics = new List(value).DistinctBy(t => t.topic).ToArray();
        for(let i = 0; i < sensorTraceTopics.length; i++) {
          const sensorTraceTopic = sensorTraceTopics[i];
          console.log('BoxComponent.ngOnInit : Sensor traces topic', sensorTraceTopic);
          let valuesData = [];
          const valuesForTopic = new List(value).Where(v => v.topic === sensorTraceTopic.topic).ToArray();
          for(let j = 0; j < valuesForTopic.length; j++) {
            const valueItem = parseFloat(valuesForTopic[j].sensorValue);
            valuesData.push({ "value": valueItem, "name": valuesForTopic[j].creation });
          }
          temp.push({ "name": sensorTraceTopic.topic, "series": valuesData });
        }
        
      }
      this.tracesData = temp;
    });
    
  }

  public updateBox() {

  }
}
