import { EntityBase } from "../entity.base.model";
import { Supplier } from "../suppliers/supplier.model";
import { TaskType } from "./task.type.enum";

export class Task extends EntityBase {
  projectId: string;
  ownerId: string;
  supplierId: string;
  type: TaskType;
  name: string;
  description: string;
  date: Date;
  start: Date;
  end: Date;
  color: string;
  parent: string;

  supplier: Supplier;
}
