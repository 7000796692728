import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { List } from 'linqts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectStatusServices } from '../../../services/projects/project.status.services';

import { ProjectStatus } from '../../../models/entities/projects/project.status.model';

@Component({
  selector: 'app-add-project-status',
  templateUrl: './add.project.status.component.html',
  styleUrls: ['./add.project.status.component.scss']
})
export class AddProjectStatusComponent {

  public projectStatus: ProjectStatus = new ProjectStatus();

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    public dataAccessServices: DataAccessServices,
    private projectStatusServices: ProjectStatusServices) {
    if (this.dataAccessServices.iscacherefreshed == false) {
      this.dataAccessServices.initialize().then(() => {
        console.log('AddProjectStatusComponent.constructor : Application is initialized');
      });
    }
  }
  
  public async addProjectStatus(): Promise<void> {
    if (this.checkProjectStatusNameExists() === false) {
      await this.projectStatusServices.addProjectStatus(this.projectStatus).then((data: ProjectStatus) => {
        console.log('AddProjectStatusComponent.addProjectStatus: Project status added', data);
        this.dataAccessServices.projectStatus.push(data);
        this.router.navigate(['/addproject']);
      });
    } else {
      console.log('AddProjectStatusComponent.addProjectStatus: Project status already exists', this.projectStatus);
    }
  }

  public onProjectStatusChanged(): void {
    this.checkProjectStatusNameExists();
  }

  private checkProjectStatusNameExists(): boolean {
    let result = false;
    if (this.projectStatus.name !== undefined && this.projectStatus.name !== null && this.projectStatus.name !== '') {
      const alreadyExists = new List(this.dataAccessServices.projectStatus).Any(ps => ps.name.toLowerCase() === this.projectStatus.name.toLowerCase());
      if (alreadyExists) {
        this.snackBar.open(this.translateService.instant('projectstatus.projectstatusnamealreadyexists'), 'Ok');
        result = true;
      }
    }
    return result;
  }
}
