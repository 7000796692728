import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { ScoringsServices } from 'src/app/services/suppliers/scorings.services';
import { Scoring } from 'src/app/models/entities/suppliers/scoring.model';
import { SuppliersScoringsServices } from 'src/app/services/suppliers/supplier.scorings.services';
import { SupplierScoring } from 'src/app/models/entities/suppliers/supplier.scoring.model';

@Component({
  selector: 'app-supplier-scoring',
  templateUrl: './supplier-scoring.component.html'
})
export class SupplierScoringComponent {

  public selectedProject: Project = null;
  public scorings: Array<Scoring> = new Array<Scoring>();
  public supplierScoring: SupplierScoring = new SupplierScoring();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private scoringsServices: ScoringsServices,
    private suppliersScoringsServices: SuppliersScoringsServices) {
      this.activatedRoute.params.subscribe(async params => {
        console.log('SuppliersScoringComponent.constructor : Retrieved params', params);
        let projectId = params['projectid'];
        let supplierScoringId = params['supplierscoringid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
        await this.scoringsServices.getScorings(this.selectedProject).then(data => {
          console.log('SuppliersScoringComponent.ngOnInit : Scorings', data);
          this.scorings = data;
        });    
        await this.suppliersScoringsServices.getSupplierScoring(supplierScoringId).then(data => {
          console.log('SuppliersScoringComponent.ngOnInit : Suppliers scoring', data);
          data.scoring = new List(this.scorings).First(s => s._id == data.scoringId);
          this.supplierScoring = data;
        });
        await this.suppliersServices.getSupplier(this.supplierScoring.supplierId).then(data => {
          console.log('SuppliersScoringComponent.ngOnInit : Supplier', data);
          this.supplierScoring.supplier = data;
        });
      });
  }

  public async ngOnInit() {

  }

  public async updateSupplierScoring() {
    await this.suppliersScoringsServices.updateSupplierScoring(this.supplierScoring).then(data => {
      console.log('SuppliersScoringComponent.updateSupplierScoring : Supplier scoring updated', data);
      this.router.navigate(['/projects', 'project', this.selectedProject._id, 'suppliersscorings']);
    });
  }

}
