import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Supplier } from "src/app/models/entities/suppliers/supplier.model";

import { Task } from "src/app/models/entities/tasks/task.model";
import { SuppliersServices } from "src/app/services/suppliers/suppliers.services";

@Component({
  selector: 'add-task-modal',
  templateUrl: 'add.task.modal.html'
})
export class AddTaskModal {

  public task: Task = new Task();
  public suppliers: Array<Supplier> = new Array<Supplier>();

  public startTime: any;
  public endTime: any;

  constructor(
    public dialogRef: MatDialogRef<AddTaskModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private suppliersServices: SuppliersServices
  ) {
  }

  async ngOnInit() {

    this.dialogRef.updateSize('80%', '90%');

    await this.suppliersServices.getSuppliers(this.data.project._id).then(data => {
      console.log('AddTaskModal.ngOnInit : Suppliers', data);
      this.suppliers = data;
    });

  } 

  public validTask(task: Task) {
    const startTimeParts = this.startTime.split(':');
    task.start.setHours(parseInt(startTimeParts[0], 10), parseInt(startTimeParts[1], 10));
    const endTimeParts = this.endTime.split(':');
    task.end.setHours(parseInt(endTimeParts[0], 10), parseInt(endTimeParts[1], 10));
    this.dialogRef.close(task);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}