<div class="row form-group">

  <mat-accordion *ngFor="let file of files">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ file.title }}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="row" style="padding:20px" *ngFor="let revisionFile of file.revisionFiles">
        <div class="row mt-3">
          <div class="col-md-4">
            {{ revisionFile.name }}
          </div>
          <div class="col-md-2">
            <span [ngSwitch]="revisionFile.status">
              <span *ngSwitchCase="0" class="badge-warning text-center"> {{ 'file.statusenum.proofread' | translate }} </span>
              <span *ngSwitchCase="1" class="badge-success text-center"> {{ 'file.statusenum.accepted' | translate }} </span>
              <span *ngSwitchCase="2" class="badge-warning text-center"> {{ 'file.statusenum.changerequest' | translate }} </span>
              <span *ngSwitchCase="3" class="badge-danger text-center"> {{ 'file.statusenum.denied' | translate }} </span>
            </span>
          </div>
          <div class="col-md-2">
            {{ revisionFile.owner }}
          </div>
          <div class="col-md-2">
            {{ revisionFile.modification | date:'short' }}
          </div>
          <div class="col-md-1">
            <button mat-flat-button color="basic" (click)="viewFile(revisionFile)">
              {{ 'file.fileview' | translate }}
            </button>
          </div>
          <div class="col-md-1">
            <button mat-flat-button color="basic" (click)="editRevisionFile(revisionFile)">
              {{ 'generic.edit' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
