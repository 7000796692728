<h1 mat-dialog-title>{{ 'modal.editannotation.title' | translate }}</h1>
<div mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="{{ 'modal.editannotation.informations' | translate }}">
      <div class="row mt-3">
        <mat-form-field>
          <mat-label>{{ 'modal.editannotation.annotationtype' | translate }}</mat-label>
          <mat-select name="category" [(ngModel)]="annotation.annotationTypeId">
            <mat-option *ngFor="let annotationType of annotationTypes" [value]="annotationType._id">{{ annotationType.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>{{ 'modal.editannotation.campaign' | translate }}</mat-label>
          <mat-select name="category" [(ngModel)]="annotation.campaignId">
            <mat-option *ngFor="let campaign of campaigns" [value]="campaign._id">{{ campaign.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>{{ 'modal.editannotation.subject' | translate }}</mat-label>
          <input matInput [(ngModel)]="annotation.subject">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>{{ 'modal.editannotation.comment' | translate }}</mat-label>
          <textarea matInput rows="8" [(ngModel)]="annotation.comment"></textarea>
        </mat-form-field>
      </div>
      <div class="row">
        <button mat-button (click)="onNoClick()">{{ 'modal.editannotation.takeasnapshot' | translate }}</button>
      </div>
      <div class="row" *ngIf="annotation.annotationType.phase == 2">
        <button mat-button (click)="onNoClick()">{{ 'modal.editannotation.editiotobject' | translate }}</button>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'modal.editannotation.pictures' | translate }}">
      <div class="row mt-3 px-5">
        <div *ngFor="let annotationPicture of annotation.annotationPictures" class="col-md-6">
          <img *ngIf="annotationPicture.data" [src]="annotationPicture.data" alt="Captured Photo">
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="annotation.subject" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>