import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { GroupsComponent } from './groups/list/groups.component';
import { AddGroupComponent } from './groups/add/add.group.component';
import { GroupComponent } from './groups/details/group.component';
import { AddUserComponent } from './users/add/add.user.component';
import { DocumentsComponent } from './documents/documents.component';
import { AnnotationsComponent } from './annotations/list/annotations.component';
import { AddAnnotationTypeComponent } from './annotations/add/add.annotation.type.component';
import { AnnotationTypeComponent } from './annotations/details/annotation.type.component';
import { BoxesComponent } from './iot/list/boxes.component';
import { BoxComponent } from './iot/details/box.component';
import { ScoringsComponent } from './scorings/scorings.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/groups', component: GroupsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/groups/addgroup', component: AddGroupComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/groups/group/:groupid', component: GroupComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/groups/group/:groupid/user/add', component: AddUserComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsdocuments', component: DocumentsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsannotations', component: AnnotationsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsannotations/add', component: AddAnnotationTypeComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsannotations/annotationtype/:annotationtypeid', component: AnnotationTypeComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsiot', component: BoxesComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsiot/:boxid', component: BoxComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/settingsscorings', component: ScoringsComponent, canActivate: [AuthGuardServices] }
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
