<div class="row form-group">
  <div class="image-container" customDropFiles (filesDropped)="onFileDropped($event)" (click)="onClicked($event)">
  </div>
</div>
<div class="row form-group">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let file of originalFiles; let i = index">
      <div class="row form-group">
        <mat-label class="col-md-6">{{ file.name }}</mat-label>
        <mat-label class="col-md-2">{{ formatBytes(file.size) }}</mat-label>
        <div class="col-md-4">
          <div class="row form-group">
            <button mat-raised-button color="warn" *ngIf="file.uploading === false && file.progress !== 100 && uploadingFiles === false" (click)="removeFile(i)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button>
            <mat-label *ngIf="file.uploading === true"><i>{{ file.progress }} %</i></mat-label>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <mat-label *ngIf="file.uploading === false && file.progress === 100"><i>{{ 'project.fileuploaded' | translate }}</i></mat-label>
      </div>
    </mat-list-item>
  </mat-list>
</div>
<div class="row form-group" *ngIf="documentsCount > 0 && uploadingFiles === false">
  <button mat-raised-button color="primary" (click)="uploadFiles()"> {{ 'project.uploaddocuments' | translate }}</button>
</div>
