import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';
import { AnnotationTypeGroup } from 'src/app/models/entities/annotations/annotation.type.group.model';

@Injectable({
  providedIn: 'root'
})
export class AnnotationTypeGroupsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getAnnotationTypeGroups(annotationType: AnnotationType): Promise<Array<AnnotationTypeGroup>> {
    console.log('AnnotationTypeGroupsServices.getAnnotationTypeGroups: Start to get annotation type groups', annotationType);

    return new Promise<Array<AnnotationTypeGroup>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationTypeGroupsServices.getAnnotationTypeGroups : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<AnnotationTypeGroup[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-type-groups`, filter: { 'annotationTypeId': annotationType._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationTypeGroupsServices.getAnnotationTypeGroups : Annotation type groups', data);
        resolve(data.documents);
      });
    });
  }

  public async addAnnotationTypeGroup(annotationTypeGroup: AnnotationTypeGroup): Promise<AnnotationTypeGroup> {
    console.log('AnnotationTypeGroupsServices.addAnnotationTypeGroup: Start to add annotation type group', annotationTypeGroup);

    return new Promise<AnnotationTypeGroup>(async (resolve, reject) => {

      const annotationTypeGroupData = { 
        annotationTypeId: annotationTypeGroup.annotationTypeId, 
        groupId: annotationTypeGroup.groupId, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationTypeGroupsServices.addAnnotationTypeGroup : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-type-groups`, document: annotationTypeGroupData }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationTypeGroupsServices.addAnnotationTypeGroup : Annotation type group added', data);
        annotationTypeGroup._id = data.insertedId;
        resolve(annotationTypeGroup);
      });

    });
  }
}
