import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from '../../models/entities/files/file.model';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Material } from 'src/app/models/entities/suppliers/material.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getMaterials(project: Project): Promise<Array<Material>> {
    console.log('MaterialsServices.getMaterials: Start to get materials', project);

    return new Promise<Array<Material>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('MaterialsServices.getMaterials : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Material[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `materials`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('MaterialsServices.getMaterials : Materials', data);
        resolve(data.documents);
      });
    });
  }

  public async getMaterial(materialId: string): Promise<Material> {
    console.log('MaterialsServices.getMaterial: Start to get material', materialId);
      
    return new Promise<Material>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('MaterialsServices.getMaterial : Token', data);
          token = data;
        });

        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Material>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `materials`, filter: { '_id': { $eq: { $oid: materialId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('MaterialsServices.getMaterial : Material', data);
        resolve(data.document);
      });
    });
  }

  public async addMaterial(material: Material): Promise<Material> {
    console.log('MaterialsServices.addMaterial: Start to add material', material);

    return new Promise<Material>(async (resolve, reject) => {

      const materialData = { 
        projectId: material.projectId,
        supplierId: material.supplierId, 
        name: material.name, 
        originalAddress: material.originalAddress, 
        description: material.description, 
        quantity: material.quantity, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('MaterialsServices.addMaterial : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `materials`, document: materialData }, httpOptions).toPromise().then((data: any) => {
        console.log('MaterialsServices.addMaterial : Material added', data);
        material._id = data.insertedId;
        resolve(material);
      });

    });
  }

  public async updateMaterial(material: Material): Promise<Material> {
    console.log('MaterialsServices.updateMaterial: Start to update material', material);

    return new Promise<Material>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('MaterialsServices.updateMaterial : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const materialData = { 
        name: material.name, 
        description: material.description, 
        quantity: material.quantity, 
        originalAddress: material.originalAddress, 
        modification: new Date()
      };
        
      await this.httpClient.post<Material>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `materials`, filter: { '_id': { $eq: { $oid: material._id } } }, update: { $set: materialData } }, httpOptions).toPromise().then((data: any) => {
        console.log('MaterialsServices.updateMaterial : Material updated', data);
        if(data.matchedCount > 0) {
          resolve(material);
         } else {
          reject(data);
        }
      });
    });
  }
}
