import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { ViewConceptionFilesComponent } from './view-conception/view.conception.files.component';
import { ViewConstructionFilesComponent } from './view-construction/view.construction.files.component';
import { FilesComponent } from './list/files.component';
import { FileComponent } from './details/file.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/files', component: FilesComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/files/file/:fileid', component: FileComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/viewfile/conception/:revisionfileid', component: ViewConceptionFilesComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/viewfile/construction/:revisionfileid', component: ViewConstructionFilesComponent, canActivate: [AuthGuardServices] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FilesRoutingModule { }
