import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { QuotesServices } from 'src/app/services/quotes/quotes.services';

import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { Contract } from 'src/app/models/entities/contracts/contract.model';
import { ContractsServices } from 'src/app/services/contracts/contracts.services';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent {
  
  private contractId: string;

  public selectedProject: Project = null;
  public contract: Contract = new Contract();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private quotesServices: QuotesServices,
    private contractsServices: ContractsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('ContractComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.contractId = params['contractid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
     }

  async ngOnInit() {
    let suppliers = new Array<Supplier>();
    await this.contractsServices.getContract(this.contractId).then(data => {
      console.log('ContractComponent.ngOnInit : Quote', data);
      this.contract = data;
    });
  }

  public async updateQuote(): Promise<void> {
    await this.contractsServices.updateContract(this.contract).then((data: Contract) => {
      console.log('ContractComponent.updateSupplier: Contract updated', data);
      this.contract = data;
    });

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'contracts']);
  }
}
