import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';

import { DataAccessServices } from 'src/app/services/data.access.services';
import { GroupsServices } from 'src/app/services/users/groups.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Group } from 'src/app/models/entities/users/group.model';
import { MatDialog } from '@angular/material/dialog';
import { DeleteEntityModal } from 'src/app/shared/components/modals/delete-entity/delete.entity.modal';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { UsersServices } from 'src/app/services/users/users.services';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent {

  public panelOpenState: boolean;
  public selectedProject: Project = null;
  public groups: Array<Group> = new Array<Group>();
  public groupPageIndex: number = 1;
  public groupPageSize: number = 25;
  public groupTotal: number = 0;
  public displayedGroupsColumns: string[] = ['name', 'users', 'edit', 'delete'];
  public groupsDataSource = new MatTableDataSource<Group>(this.groups);
  public searchedGroups: Group = new Group();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dataAccessServices: DataAccessServices,
    private groupsServices: GroupsServices,
    private userGroupsServices: UserGroupsServices,
    private usersServices: UsersServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('GroupsComponent.constructor : Retrieved params', params);
      const projectId = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
    });
  }

  public async ngOnInit() {
    await this.groupsServices.getGroups(this.selectedProject).then(data => {
      console.log('GroupsComponent.constructor : Groups', data);
      this.groups = data;
      this.groupsDataSource = new MatTableDataSource<Group>(this.groups);
    });

    for(let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];
      await this.userGroupsServices.getByGroup(group._id).then(data => {
        console.log('GroupComponent.ngOnInit : Get user groups', data);
        group.userGroups = data;
      });
    }
  }

  public resetGroupFilters(): void {

  }

  public onSearchedGroupsChanged(): void {

  }

  public deleteGroup(group: Group): void {

    const signatureDialogRef = this.dialog.open(DeleteEntityModal, {
      data: group.name,
    });
    signatureDialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result !== undefined && result !== null) {
        this.groupsServices.deleteGroup(group).then(data => {
          console.log('GroupsComponent.deleteGroup : group deleted', data);
          const index = new List(this.groups).IndexOf(group);
          if (index > -1) {
            this.groups.splice(index, 1);
          }
          this.groupsDataSource = new MatTableDataSource<Group>(this.groups);
        });
      }
    });
  }
}
