export abstract class EntityBase {
  _id: string;
  modification: Date;
  creation: Date;

  constructor() {
    this.modification = new Date();
    this.creation = new Date();
  } 
}
