import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from 'src/app/services/commons/auth.guard.services';

import { SchedulerComponent } from './scheduler.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/scheduler', component: SchedulerComponent,  canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SchedulerRoutingModule { }
