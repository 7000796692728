import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Material } from 'src/app/models/entities/suppliers/material.model';
import { MaterialsServices } from 'src/app/services/suppliers/materials.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html'
})
export class MaterialsComponent {

  public selectedProject: Project = null;
  public materials: Array<Material> = new Array<Material>(); 
  public displayedColumns: string[] = ['name', 'supplier', 'address', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Material>(this.materials);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    public dataAccessServices: DataAccessServices,
    private materialsServices: MaterialsServices,
    private suppliersServices: SuppliersServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('MaterialsComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  async ngOnInit() {
    await this.materialsServices.getMaterials(this.selectedProject).then(data => {
      console.log('MaterialsComponent.ngOnInit : Materials', data);
      this.materials = data;
    });

    for(let i = 0; i < this.materials.length; i++) {
      const material = this.materials[i];
      await this.suppliersServices.getSupplier(material.supplierId).then(data => {
        console.log('MaterialsComponent.ngOnInit : Supplier', data);
        material.supplier = data;
      });
    }

    this.dataSource = new MatTableDataSource<Material>(this.materials);
  }
  
  public deleteMaterial(material: Material) {

  }


}
