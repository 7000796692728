import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { List } from "linqts";

import { Annotation } from "src/app/models/entities/annotations/annotation.model";
import { AnnotationType } from "src/app/models/entities/annotations/annotation.type.model";
import { Campaign } from "src/app/models/entities/campaigns/campaign.model";
import { AnnotationTypesServices } from "src/app/services/annotations/annotation.types.services";
import { CampaignsServices } from "src/app/services/campaigns/campaigns.services";
import { FilesServices } from "src/app/services/files/files.services";
import { RevisionFilesServices } from "src/app/services/files/revision.files.services";

@Component({
  selector: 'add-annotation-modal',
  templateUrl: 'add.annotation.modal.html'
})
export class AddAnnotationModal {

  public annotationTypes: Array<AnnotationType> = new Array<AnnotationType>();
  public campaigns: Array<Campaign> = new Array<Campaign>();

  constructor(
    public dialogRef: MatDialogRef<AddAnnotationModal>,
    @Inject(MAT_DIALOG_DATA) public annotation: Annotation,
    private projectFilesServices: FilesServices,
    private revisionFilesServices: RevisionFilesServices,
    private annotationTypesServices: AnnotationTypesServices,
    private campaignsServices: CampaignsServices
  ) {}

  async ngOnInit() {

    this.dialogRef.updateSize('80%', '90%');
    
    let revisionFile = null;
    await this.revisionFilesServices.getRevisionFile(this.annotation.revisionFileId).then(data => {
      console.log('AddAnnotationModal.ngOnInit : Revision file', data);
      revisionFile = data;
    });

    let projectFile = null;
    await this.projectFilesServices.getFile(revisionFile.projectFileId).then(data => {
      console.log('AddAnnotationModal.ngOnInit : Get file', data);
      projectFile = data;
    });

    await this.annotationTypesServices.getAnnotationTypes(projectFile.projectId).then(data => {
      console.log('AddAnnotationModal.ngOnInit : Annotation types', data);
      this.annotationTypes = data;
    });

    await this.campaignsServices.getCampaigns(projectFile.projectId).then(data => {
      console.log('AddAnnotationModal.ngOnInit : Campaigns', data);
      this.campaigns = new List(data).Where(c => new Date(c.endDate) >= new Date()).ToArray();
    });

  } 

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}