import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { Project } from '../../../models/entities/projects/project.model';
import { UserProfile } from 'src/app/models/entities/users/user.profile.enum';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { Group } from 'src/app/models/entities/users/group.model';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';
import { UserGroup } from 'src/app/models/entities/users/user.group.model';
import { ProjectGroup } from 'src/app/models/entities/projects/project.group.model';
import { ProjectGroupsServices } from 'src/app/services/projects/project.groups.services';
import { SlotsServices } from 'src/app/services/projects/slots.services';
import { Slot } from 'src/app/models/entities/projects/slot.model';

@Component({
  selector: 'app-add-slot',
  templateUrl: './add.slot.component.html',
  styleUrls: ['./add.slot.component.scss']
})
export class AddSlotComponent {

  public projectStatusFormControl: FormControl = new FormControl();
  public filteredProjectStatusOptions: Observable<string[]>;
  public slot: Slot = new Slot();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private slotsServices: SlotsServices) { 
      this.activatedRoute.params.subscribe(params => {
        console.log('AddSlotComponent.constructor : Retrieved params', params);
        const projectId = params['projectid'];
        const selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
        this.slot.projectId = selectedProject._id;
      });
    }

  public async addSlot(): Promise<void> {
    await this.slotsServices.addSlot(this.slot).then((data: Slot) => {
      console.log('AddSlotComponent.addSlot: Slot added', data);
      this.router.navigate(['/projects']);
    });
  }
}
