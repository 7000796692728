<div class="form-container">

    <mat-form-field>
        <input matInput placeholder="{{ 'group.namesample' | translate }}" [(ngModel)]="group.name">
    </mat-form-field>

    <mat-slide-toggle [checked]="group.isDocumentsValidators">{{ 'group.isdocumentsvalidators' | translate }}</mat-slide-toggle>

    <button mat-raised-button class="mt-3" color="basic" [routerLink]="'/groups'">{{ 'generic.cancel' | translate }}</button>

    <button mat-raised-button class="mt-3" color="primary" (click)="addGroup()">{{ 'generic.validate' | translate }}</button>

</div>
