import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { MaterialModule } from './../material.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { AddItemButtonComponent } from './../shared/components/add-item-button/add-item-button.component';
import { AvatarComponent } from './../shared/components/avatar/avatar.component';
import { UploadFilesComponent } from './../shared/components/upload-files/upload.files.component';
import { GanttComponent } from './../shared/components/gantt/gantt.component';

import { AddAnnotationModal } from './../shared/components/modals/annotations/add.annotation.modal';
import { EditAnnotationModal } from './../shared/components/modals/annotations/edit.annotation.modal';
import { AddRevisionFileCommentModal } from './components/modals/revisionfiles/add.revision.file.comment.modal';
import { SignatureModal } from './components/modals/signature/signature.modal';
import { DeleteEntityModal } from './components/modals/delete-entity/delete.entity.modal';
import { EditTaskModal } from './components/modals/edit-task/edit.task.modal';
import { AddTaskModal } from './components/modals/add-task/add.task.modal';
import { SnapshotModal } from './components/modals/snapshot/snapshot.modal';
import { AnnotationsTypesFiltersModal } from './components/modals/annotations/annotations.types.filters.modal';
import { AnnotationsCampaignsFiltersModal } from './components/modals/annotations/annotations.campaigns.filters.modal';

import { CustomDropFilesDirective } from './../shared/directives/drop-files/drop-files.directive';
import { TruncateTextDirective } from './directives/truncate-text/truncate-text.directive';
import { AutodeskViewerComponent } from './components/autodesk-viewer/autodesk-viewer.component';
import { TimesheetComponent } from './components/timesheet/timesheet.component';



@NgModule({
  declarations: [
    AddItemButtonComponent,
    AvatarComponent,
    UploadFilesComponent,
    GanttComponent,
    TimesheetComponent,
    AutodeskViewerComponent,

    AddAnnotationModal,
    EditAnnotationModal,
    AddRevisionFileCommentModal,
    SignatureModal,
    DeleteEntityModal,
    EditTaskModal,
    AddTaskModal,
    SnapshotModal,
    AnnotationsTypesFiltersModal,
    AnnotationsCampaignsFiltersModal,

    CustomDropFilesDirective,
    TruncateTextDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    AngularSignaturePadModule,
    MaterialModule
  ],
  exports: [
    AddItemButtonComponent,
    AvatarComponent,
    UploadFilesComponent,
    GanttComponent,
    TimesheetComponent,
    AutodeskViewerComponent,

    CustomDropFilesDirective,
    TruncateTextDirective
  ],
  providers: [],
  bootstrap: []
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
