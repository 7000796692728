import { EntityBase } from "../entity.base.model";
import { Scoring } from "./scoring.model";
import { Supplier } from "./supplier.model";

export class SupplierScoring extends EntityBase {
  supplierId: string;
  scoringId: string;
  description: string;
  score: number;

  scoring: Scoring;
  supplier: Supplier;
}
