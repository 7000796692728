import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import jwt_decode from "jwt-decode";

import { LocalStorageServices } from './local.storage.services';

@Injectable({
  providedIn: 'root'
})
export class AutoDeskTokenServices {

  private token: string = null;

  constructor(
    private httpClient: HttpClient,
    private localStorageServices: LocalStorageServices
  ) { }

  public async getToken(): Promise<string> {
    console.log('AutoDeskTokenServices.getToken : Start to get token');
    
    let cachedToken = this.localStorageServices.get('autodesk_token');
    if(cachedToken !== undefined && cachedToken !== null) {
      this.token = cachedToken;
    }

    return new Promise<string>(async (resolve, reject) => {
      if(this.token === null || this.token === undefined) {
        console.debug('AutoDeskTokenServices.getToken : The token is not initialized');
        await this.generateToken().then(data => {
          console.log('AutoDeskTokenServices.getToken : The generated token', data);
          this.token = data;
          this.localStorageServices.set('autodesk_token', data);
          resolve(this.token);
        }).catch(error => reject(error));
      } else {
        console.debug('AutoDeskTokenServices.getToken : The token is already initialized');
        const decodedToken: any = jwt_decode(this.token);
        console.debug('AutoDeskTokenServices.getToken : The decoded token', decodedToken);
        if(decodedToken) {
          const expirationTimestamp = decodedToken.exp;
          const expirationDate = new Date(expirationTimestamp * 1000);
          const currentDate = new Date();
          if (currentDate < expirationDate) {
            console.log('AutoDeskTokenServices.getToken : The token is valid');
            resolve(this.token);
          } else {
            console.log('AutoDeskTokenServices.getToken : The token is expired');
            await this.generateToken().then(data => {
              console.log('AutoDeskTokenServices.getToken : The generated token', data);
              this.token = data;
              this.localStorageServices.set('autodesk_token', data);
              resolve(this.token);
            });
          }
        }
      }
    });
  }

  private async generateToken(): Promise<string> {
    console.log('AutoDeskTokenServices.generateToken : Start to generate token');
    return new Promise<string>(async (resolve, reject) => {

      const body = new URLSearchParams();
      body.set('client_id', environment.autodesk.authentication.clientId);
      body.set('client_secret', environment.autodesk.authentication.clientSecret);
      body.set('grant_type', environment.autodesk.authentication.grantType);
      body.set('scope', 'data:read data:write viewables:read bucket:create bucket:read');

      await this.httpClient.post<any>(environment.autodesk.authentication.url, body.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).toPromise().then(data => {
        console.log('AutoDeskServices.generateToken: Generated token', data);
        resolve(data.access_token);
      });
    });
  }
}
