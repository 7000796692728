<div class="form-container">

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'supplier.search' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <mat-form-field>
          <mat-label>{{ 'supplier.name' | translate }}</mat-label>
          <input matInput [(ngModel)]="searchedSupplier.name">
        </mat-form-field>
      </div>
      <div class="row">
        <button mat-raised-button class="mt-3" color="primary" (click)="searchSupplier()">{{ 'generic.search' | translate }}</button>
      </div>
      <div class="row" *ngIf="suppliersFound.length > 0">
        <table mat-table [dataSource]="dataSource" *ngIf="this.dataAccessServices.projects.length > 0">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.name' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
          </ng-container>
      
          <ng-container matColumnDef="licenceNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.licencenumber' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.licenceNumber }} </td>
          </ng-container>
      
          <ng-container matColumnDef="deliveredLicenceDate">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.deliveredlicencedate' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.deliveredLicenceDate | date:'short' }} </td>
          </ng-container>
      
          <ng-container matColumnDef="companyDigitalIdentifier">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.companydigitalidentifier' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.companyDigitalIdentifier }} </td>
          </ng-container>
          
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.status' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
          </ng-container>

          <ng-container matColumnDef="insuranceCompany">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.insurancecompany' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.insuranceCompany }} </td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> {{ 'suppliers.city' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.city }} </td>
          </ng-container>

          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" (click)="selectSupplier(element)"><mat-icon>edit</mat-icon> {{ 'generic.select' | translate }}</button> </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator
                       *ngIf="suppliersFound.length > 0"
                       [pageSizeOptions]="[5, 10, 20]"
                       showFirstLastButtons
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="row mt-3">
    <mat-form-field>
      <mat-label>{{ 'supplier.name' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.licencenumber' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.licenceNumber">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.deliveredlicencedate' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.deliveredLicenceDate">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.companydigitalidentifier' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.companyDigitalIdentifier">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.status' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.status">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.insurancecompany' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.insuranceCompany">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.city' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.city">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.licencetype' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.licenceType">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.insuranceamount' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.insuranceAmount">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.annualpaymentdate' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.annualPaymentDate">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.email' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.email">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>{{ 'supplier.address' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.address">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.phonenumber' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.phoneNumber">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.legalstatus' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.legalStatus">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.administrativeregioncode' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.administrativeRegionCode">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'supplier.administrativeregion' | translate }}</mat-label>
      <input matInput [(ngModel)]="supplier.administrativeRegion">
    </mat-form-field>

  </div>

  <div class="row">
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="['/projects', 'project', this.selectedProject._id, 'suppliers']">{{ 'generic.cancel' | translate }}</button>
    <button mat-raised-button class="mt-3" color="primary" (click)="addSupplier()">{{ 'generic.validate' | translate }}</button>
  </div>
</div>
