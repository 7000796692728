import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import * as uuid from 'uuid';

import { environment } from '../../../environments/environment';

import { ProjectFile } from '../../models/entities/files/file.model';
import { NomenclatureTypeFile } from '../../models/entities/files/nomenclature.type.file.model';

@Injectable({
  providedIn: 'root'
})
export class NomenclatureTypeFilesServices {

  constructor(private httpClient: HttpClient) { }

  public async getNomenclatureTypeFiles(file: ProjectFile): Promise<Array<NomenclatureTypeFile>> {
    console.log('NomenclatureTypeFilesServices.getNomenclatureTypeFiles: Start to get file\'s NomenclatureTypeFile', file);

    return new Promise<Array<NomenclatureTypeFile>>((resolve, reject) => {
      
    });
  }

  public async addNomenclatureTypeFile(nomenclatureTypeFile: NomenclatureTypeFile): Promise<NomenclatureTypeFile> {
    console.log('NomenclatureTypeFilesServices.addNomenclatureTypeFile: Start to add file\'s NomenclatureTypeFile', nomenclatureTypeFile);

    return new Promise<NomenclatureTypeFile>(async (resolve, reject) => {
    
    });
  }

  public async deleteNomenclatureTypeFile(nomenclatureTypeFile: NomenclatureTypeFile): Promise<boolean> {
    console.log('NomenclatureTypeFilesServices.deleteNomenclatureTypeFile: Start to delete NomenclatureTypeFile', nomenclatureTypeFile);

    return new Promise<boolean>((resolve, reject) => {
      
    });
  }
}
