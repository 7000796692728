import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { CampaignsComponent } from './list/campaigns.component';
import { AddCampaignComponent } from './add/add.campaign.component';
import { CampaignComponent } from './details/campaign.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/campaigns', component: CampaignsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/campaigns/add', component: AddCampaignComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/campaigns/campaign/:campaignid', component: CampaignComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CampaignsRoutingModule { }
