import { EntityBase } from "../entity.base.model";
import { BIMPhase } from "../projects/bim.phase.enum";

export class AnnotationType extends EntityBase {
  projectId: string;
  name: string;
  color: string;
  phase: BIMPhase;

  visible: boolean = true;
}
