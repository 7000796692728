import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from '../../models/entities/files/file.model';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';

@Injectable({
  providedIn: 'root'
})
export class AnnotationsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getAnnotations(revisionFile: RevisionFile): Promise<Array<Annotation>> {
    console.log('AnnotationsServices.getAnnotations: Start to get annotations', revisionFile);

    return new Promise<Array<Annotation>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationsServices.getAnnotations : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Annotation[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotations`, filter: { 'revisionFileId': revisionFile._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationsServices.getAnnotations : Annotations', data);
        resolve(data.documents);
      });
    });
  }

  public async addAnnotation(annotation: Annotation): Promise<Annotation> {
    console.log('AnnotationsServices.addAnnotation: Start to add annotation', annotation);

    return new Promise<Annotation>(async (resolve, reject) => {

      const annotationData = { 
        revisionFileId: annotation.revisionFileId, 
        annotationTypeId: annotation.annotationTypeId, 
        campaignId: annotation.campaignId, 
        viewIndex: annotation.viewIndex, 
        number: annotation.number, 
        coordX: annotation.coordX,
        coordY: annotation.coordY,
        subject: annotation.subject,
        comment: annotation.comment,
        ownerId: annotation.ownerId,
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationsServices.addAnnotation : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotations`, document: annotationData }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationsServices.addAnnotation : Annotation added', data);
        annotation._id = data.insertedId;
        resolve(annotation);
      });

    });
  }
}
