import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as uuid from 'uuid';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from '../../models/entities/files/file.model';
import { RevisionFile } from '../../models/entities/files/revision.file.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { StringHelpers } from 'src/app/helpers/string.helpers';

@Injectable({
  providedIn: 'root'
})
export class RevisionFilesServices {

  @Output() uploadFileProgressEvent: EventEmitter<number> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private stringHelpers: StringHelpers,
    private storage: AngularFireStorage,
    private realmTokenServices: RealmTokenServices) { }

  public async uploadRevisionFile(project: Project, projectFile: ProjectFile, revisionFile: RevisionFile): Promise<RevisionFile> {
    return new Promise<RevisionFile>(async (resolve, reject) => {
      const fileGuid = uuid.v4();
      revisionFile.name = `${fileGuid}-${projectFile.originalTitle}`;

      const filePath = `uploads/${project._id}/${revisionFile.name}`;

      const task = this.storage.upload(filePath, revisionFile.data).snapshotChanges().subscribe(uploadTask => {
        console.log('FilesServices.uploadRevisionFile : Uploading file', Math.round(100 * uploadTask.bytesTransferred / uploadTask.totalBytes), '%');
        this.uploadFileProgressEvent.emit(Math.round(100 * uploadTask.bytesTransferred / uploadTask.totalBytes));
        if(uploadTask.bytesTransferred === uploadTask.totalBytes) {
          console.log('FilesServices.uploadRevisionFile : File transfered', revisionFile);
        } 
        if(uploadTask.state === 'success') {
          console.log('FilesServices.uploadRevisionFile : Upload task success', uploadTask, revisionFile);
          resolve(revisionFile);
        }
      });
    });
  }

  public async getDownloadUrl(project: Project, revisionFile: RevisionFile): Promise<RevisionFile> {
    console.log('FilesServices.getDownloadUrl : Get download url', project, revisionFile);
    return new Promise<RevisionFile>(async (resolve, reject) => {

      const filePath = `uploads/${project._id}/${revisionFile.name}`;
      const fileRef = this.storage.ref(filePath);

      await fileRef.getDownloadURL().toPromise().then(data => {
        console.log('FilesServices.getDownloadUrl : Download url', data);
        revisionFile.downloadURL = data;
      });

      resolve(revisionFile);
    });
  };

  public async loadDataFile(revisionFile: RevisionFile): Promise<RevisionFile> {
    return new Promise<RevisionFile>(async (resolve, reject) => {
  
      const storageRef = this.storage.refFromURL(revisionFile.downloadURL);
      console.log('FilesServices.loadDataFile: storageRef', storageRef);
  
      let url: string = null;
      await storageRef.getDownloadURL().toPromise().then(data => {
        console.log('FilesServices.loadDataFile: url', data);
        url = data;
      });
      
      console.debug('FilesServices.loadDataFile: Try to download from url', url);
      await this.httpClient.get(url, { responseType: 'blob' }).toPromise().then(data => {
        console.log('FilesServices.loadDataFile: blob', data);
        const blob = new Blob([data]);
        revisionFile.data = new File([blob], revisionFile.name, { type: blob.type });
      });

      resolve(revisionFile);
    });
  }

  public async getRevisionFile(revisionFileId: string): Promise<RevisionFile> {
    console.log('RevisionFilesServices.getRevisionFile: Start to get revision file', revisionFileId);

    return new Promise<RevisionFile>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFilesServices.getRevisionFile : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<RevisionFile>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-files`, filter: { '_id': { $oid: revisionFileId } } }, httpOptions).toPromise().then((data: any) => {
        console.log('RevisionFilesServices.getRevisionFile : Revision file', data);
        resolve(data.document);
      });
    });
  }

  public async getRevisionFiles(projectFile: ProjectFile): Promise<Array<RevisionFile>> {
    console.log('RevisionFilesServices.getRevisionFiles: Start to get file\'s revisions', projectFile);

    return new Promise<Array<RevisionFile>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFilesServices.getRevisionFiles : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<RevisionFile[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-files`, filter: { 'projectFileId': projectFile._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('RevisionFilesServices.getRevisionFiles : Revision files', data);
        resolve(data.documents);
      });
    });
  }

  public async addRevisionFile(revisionFile: RevisionFile): Promise<RevisionFile> {
    console.log('RevisionFilesServices.addRevisionFile: Start to add revisionFile', revisionFile);

    return new Promise<RevisionFile>(async (resolve, reject) => {

      const revisionFileData = { 
        name: revisionFile.name,
        comment: revisionFile.comment,
        downloadURL: revisionFile.downloadURL,
        ownerId: revisionFile.ownerId, 
        projectFileId: revisionFile.projectFileId, 
        version: revisionFile.version, 
        status: revisionFile.status,
        modification: new Date(),
        creation: new Date() 
      };
      
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFilesServices.addRevisionFile : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<RevisionFile>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-files`, document: revisionFileData }, httpOptions).toPromise().then((data: any) => {
        console.log('RevisionFilesServices.addRevisionFile : Revision file added', data);
        revisionFile._id = data.insertedId;
        resolve(revisionFile);
      });

    });
  }

  public async updateRevisionFile(revisionFile: RevisionFile): Promise<RevisionFile> {
    console.log('RevisionFilesServices.updateRevisionFile: Start to update revision file', revisionFile);

    return new Promise<RevisionFile>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFilesServices.updateRevisionFile : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const revisionFileData = { 
        comment: revisionFile.comment, 
        decodedUrn: revisionFile.decodedUrn, 
        downloadURL: revisionFile.downloadURL, 
        name: revisionFile.name, 
        status: revisionFile.status, 
        urn: revisionFile.urn, 
        version: revisionFile.version, 
        userValidatedId: revisionFile.userValidatedId,
        signature: revisionFile.signature,
        modification: new Date()
      };
        
      await this.httpClient.post<RevisionFile>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-files`, filter: { '_id': { $eq: { $oid: revisionFile._id } } }, update: { $set: revisionFileData } }, httpOptions).toPromise().then((data: any) => {
        console.log('FilesServices.updateFile : ProjectFile updated', data);
        if(data.matchedCount > 0) {
          resolve(revisionFile);
         } else {
          reject(data);
        }
      });
    });
  }
}
