import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { List } from 'linqts';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';


@Injectable({
  providedIn: 'root'
})
export class RbqServices {

  constructor(
    private httpClient: HttpClient) {}

  public async getCompany(name: string = null, neq: string = null): Promise<Array<Supplier>> {
    console.log('RbqServices.getCompany: Start get company', name, neq);
    return new Promise<Array<Supplier>>(async (resolve, reject) => {
      const activeLicencesUrl = 'https://www.donneesquebec.ca/recherche/api/3/action/package_search?q=licencesactives';

      let companiesUrl = null;
      await this.httpClient.get<any>(activeLicencesUrl)
      .toPromise().then(data => {
        console.log('RbqServices.getCompany: Active licences package result', data);
        companiesUrl = data.result.results[0].resources[0].url;
      });

      let result = new Array<Supplier>();
      let dataSet = null;
      await this.httpClient.get<any>(companiesUrl)
      .toPromise().then(data => {
        console.log('RbqServices.getCompany: Active licences data', data);
        dataSet = data;
      });

      for(let i = 0; i < dataSet['Liste Licence'].length; i++) {
        const licence = dataSet['Liste Licence'][i].Licence;
        console.log('RbqServices.getCompany: Compute licence', licence);
        let supplier = new Supplier();
        if(name !== undefined && name !== null && name !== '') {
          if(licence['Nom de l\'intervenant']?.toLowerCase().includes(name.toLowerCase())) {
            console.log('RbqServices.getCompany: Company searched', licence);
            supplier.name = licence['Nom de l\'intervenant'];
            supplier.licenceNumber = licence['Numéro de licence'];
            supplier.deliveredLicenceDate = licence['Date de délivrance'];
            supplier.companyDigitalIdentifier = licence['NEQ'];
            supplier.status = licence['Statut de la licence'];
            supplier.licenceType = licence['Type de licence'];
            supplier.insuranceCompany = licence['Association ou compagnie fournissant le cautionnement'];
            supplier.insuranceAmount = licence['Montant de la caution'];
            supplier.annualPaymentDate = licence['Date du paiement annuel'];
            supplier.email = licence['Courriel'];
            supplier.address = licence['Adresse'];
            supplier.phoneNumber = licence['Numéro de téléphone'];
            supplier.city = licence['Municipalité'];
            supplier.legalStatus = licence['Statut juridique'];
            supplier.administrativeRegionCode = licence['Code de région administrative'];
            supplier.administrativeRegion = licence['Région administrative'];
            result.push(supplier);
          }
        }
        if(neq !== undefined && neq !== null && neq !== '') {
          if(licence['NEQ']?.toLowerCase().includes(neq.toLowerCase())) {
            console.log('RbqServices.getCompany: Company searched', licence);
            supplier.name = licence['Nom de l\'intervenant'];
            supplier.licenceNumber = licence['Numéro de licence'];
            supplier.deliveredLicenceDate = licence['Date de délivrance'];
            supplier.companyDigitalIdentifier = licence['NEQ'];
            supplier.status = licence['Statut de la licence'];
            supplier.licenceType = licence['Type de licence'];
            supplier.insuranceCompany = licence['Association ou compagnie fournissant le cautionnement'];
            supplier.insuranceAmount = licence['Montant de la caution'];
            supplier.annualPaymentDate = licence['Date du paiement annuel'];
            supplier.email = licence['Courriel'];
            supplier.address = licence['Adresse'];
            supplier.phoneNumber = licence['Numéro de téléphone'];
            supplier.city = licence['Municipalité'];
            supplier.legalStatus = licence['Statut juridique'];
            supplier.administrativeRegionCode = licence['Code de région administrative'];
            supplier.administrativeRegion = licence['Région administrative'];
            result.push(licence);
          }
        }
      }

      resolve(result);
    });
  }
}
