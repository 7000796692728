<div class="form-container">

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'campaign.name' | translate }}</mat-label>
      <input matInput [(ngModel)]="selectedCampaign.name">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'campaign.description' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="selectedCampaign.description"></textarea>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'campaign.color' | translate }}</mat-label>
      <input matInput type="color" [(ngModel)]="selectedCampaign.color">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'campaign.startDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="selectedCampaign.startDate">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'campaign.endDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="selectedCampaign.endDate">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="row">
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="['/projects', 'project', this.selectedCampaign.projectId, 'campaigns']">{{ 'generic.cancel' | translate }}</button>
    <button mat-raised-button class="mt-3" color="primary" (click)="updateCampaign()">{{ 'generic.validate' | translate }}</button>
  </div>
</div>
