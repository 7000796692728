<div class="row">
  <div class="row">
    <div class="col-md-2">
      <mat-label>{{ 'viewfile.defaultviewname' | translate }}</mat-label>
    </div>
    <div class="col-md-2">
      <b>{{ defaultViewName }}</b>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'viewfile.2dviews' | translate }}</mat-label>
        <mat-select name="view" (selectionChange)="on2DSelectViewChanged($event)">
          <mat-option *ngFor="let view of views2d; let i = index" [value]="i">{{ view.data.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'viewfile.3dviews' | translate }}</mat-label>
        <mat-select name="view" (selectionChange)="on3DSelectViewChanged($event)">
          <mat-option *ngFor="let view of views3d; let i = index" [value]="i">{{ view.data.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  
  <div class="row">
    <div #forgeViewer id="forgeViewer" style="position:relative;min-height:75vh"></div>
  </div>
</div>
