<mat-toolbar color="primary" *ngIf="dataAccessServices.currentUser !== null">
  <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-label>SG BIM</mat-label>
  <span class="toolbar-item-spacer"></span>
  <mat-label>{{ selectedProject?.name }}</mat-label>
  <span class="toolbar-item-spacer"></span>
  <button mat-icon-button matTooltip="Notifications">
    <mat-icon>notifications</mat-icon>
  </button>
  <avatar mat-icon-button [matMenuTriggerFor]="userAccountMenu" matTooltip="{{ 'menu.myaccount' | translate }}" [isEditable]="false"
          [size]="'40px'"
          [name]="this.dataAccessServices.currentUser.firstname + ' ' + this.dataAccessServices.currentUser.lastname"
          [imageSource]="this.dataAccessServices.currentUser.picture"></avatar>
  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
    <button mat-menu-item routerLink="/profile">
      <mat-icon>person</mat-icon><span>{{ 'menu.myaccount' | translate }}</span>
    </button>
    <button mat-menu-item routerLink="#">
      <mat-icon>help</mat-icon><span> {{ 'menu.help' | translate }}</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon> {{ 'menu.logout' | translate }}
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" [ngClass]="{'app-sidenav-cell-phone': settingsServices.isCellPhone, 'app-sidenav': !settingsServices.isCellPhone}">
    <mat-nav-list>
      <mat-list-item [routerLink]="'/dashboard'" style="padding-left: 22px;">
        <mat-icon>dashboard</mat-icon><span>{{ 'menu.dashboard' | translate }}</span>
      </mat-list-item>
      <mat-list-item [routerLink]="'/projects'" style="padding-left: 22px;">
        <mat-icon>list</mat-icon><span>{{ 'menu.projects' | translate }}</span>
      </mat-list-item>
      <mat-divider *ngIf="selectedProject !== null"></mat-divider>
      <mat-list-item [routerLink]="['projects', 'project', this.selectedProject._id]" *ngIf="selectedProject !== null" style="padding-left: 22px;">
        <mat-icon>assignment</mat-icon><span>{{ 'menu.maininformations' | translate }}</span>
      </mat-list-item>
      <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="selectedProject !== null">
        <mat-expansion-panel-header>
          <mat-icon>map</mat-icon> <span style="padding-left: 10px;">{{ 'menu.technicaldocuments' | translate }}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'files']" style="padding-left: 22px;">
            <mat-icon>list</mat-icon><span style="padding-left: 10px;">{{ 'menu.planbox' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'reviewfiles']" style="padding-left: 22px;">
            <mat-icon>playlist_add_check</mat-icon><span style="padding-left: 10px;">{{ 'menu.reviewfiles' | translate }}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="selectedProject !== null">
        <mat-expansion-panel-header>
          <mat-icon>collections_bookmark</mat-icon> <span style="padding-left: 10px;">{{ 'menu.administrativedocuments' | translate }}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'suppliers']" style="padding-left: 22px;">
            <mat-icon>group</mat-icon><span style="padding-left: 10px;">{{ 'menu.suppliermanagement' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'quotes']" style="padding-left: 22px;">
            <mat-icon>receipt</mat-icon><span style="padding-left: 10px;">{{ 'menu.quotes' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'contracts']" style="padding-left: 22px;">
            <mat-icon>assignment</mat-icon><span style="padding-left: 10px;">{{ 'menu.contracts' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'orders']" style="padding-left: 22px;">
            <mat-icon>book</mat-icon><span style="padding-left: 10px;">{{ 'menu.orders' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'suppliersscorings']" style="padding-left: 22px;">
            <mat-icon>stars</mat-icon><span style="padding-left: 10px;">{{ 'menu.supplierscoring' | translate }}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="selectedProject !== null">
        <mat-expansion-panel-header>
          <mat-icon>business</mat-icon> <span style="padding-left: 10px;">{{ 'menu.constructionsitesupervision' | translate }}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'files']" style="padding-left: 22px;">
            <mat-icon>list</mat-icon><span style="padding-left: 10px;">{{ 'menu.planbox' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'requireddocuments']" style="padding-left: 22px;">
            <mat-icon>playlist_add_check</mat-icon><span style="padding-left: 10px;">{{ 'menu.requireddocuments' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'campaigns']" style="padding-left: 22px;">
            <mat-icon>flag</mat-icon><span style="padding-left: 10px;">{{ 'menu.campaigns' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'scheduler']" style="padding-left: 22px;">
            <mat-icon>calendar_today</mat-icon><span style="padding-left: 10px;">{{ 'menu.scheduler' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'contracts']" style="padding-left: 22px;">
            <mat-icon>report_problem</mat-icon><span style="padding-left: 10px;">{{ 'menu.accidents' | translate }}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="selectedProject !== null">
        <mat-expansion-panel-header>
          <mat-icon>cloud_queue</mat-icon> <span style="padding-left: 10px;">{{ 'menu.ecology' | translate }}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'materials']">
                <span>{{ 'menu.materials' | translate }}</span>
            </a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="selectedProject !== null">
        <mat-expansion-panel-header>
          <mat-icon>settings</mat-icon> <span style="padding-left: 10px;">{{ 'menu.settings' | translate }}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'groups']">
            <mat-icon>group</mat-icon> <span style="padding-left: 10px;"></span><span>{{ 'menu.groups' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'settingsdocuments']">
            <mat-icon>collections_bookmark</mat-icon><span style="padding-left: 10px;"></span><span>{{ 'menu.settingsfiles' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'settingsannotations']">
            <mat-icon>place</mat-icon><span style="padding-left: 10px;"></span><span>{{ 'menu.settingsannotations' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'settingsscorings']">
            <mat-icon>stars</mat-icon><span style="padding-left: 10px;"></span><span>{{ 'menu.scorings' | translate }}</span>
          </a>
          <a mat-list-item [routerLink]="['projects', 'project', selectedProject._id, 'settingsiot']">
            <mat-icon>cast</mat-icon><span style="padding-left: 10px;"></span><span>{{ 'menu.settingsiot' | translate }}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="height: auto" [ngStyle]="{'height': (dataAccessServices.currentUser === null) ? 'auto' : '100%'}">

    <div *ngIf="dataAccessServices.currentUser === null">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="dataAccessServices.currentUser !== null">
      <div class="app-sidenav-content">
        <div class="container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
