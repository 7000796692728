<div class="form-container">

  <div class="row">
    <mat-form-field>
      <input matInput placeholder="{{ 'slot.namesample' | translate }}" [(ngModel)]="slot.name">
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="{{ 'slot.descriptionsample' | translate }}" rows="8" [(ngModel)]="slot.description"></textarea>
    </mat-form-field>
  </div>

  <div class="row">
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="'/projects'">{{ 'generic.cancel' | translate }}</button>

    <button mat-raised-button class="mt-3" color="primary" (click)="addSlot()">{{ 'generic.validate' | translate }}</button>
  </div>
</div>
