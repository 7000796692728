import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Scoring } from 'src/app/models/entities/suppliers/scoring.model';
import { SupplierScoring } from 'src/app/models/entities/suppliers/supplier.scoring.model';

@Injectable({
  providedIn: 'root'
})
export class SuppliersScoringsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getSupplierScorings(supplier: Supplier): Promise<Array<SupplierScoring>> {
    console.log('SuppliersScoringsServices.getSupplierScorings: Start to get supplier scorings', supplier);

    return new Promise<Array<SupplierScoring>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersScoringsServices.getSupplierScorings : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<SupplierScoring[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `supplier-scorings`, filter: { 'supplierId': supplier._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersScoringsServices.getSupplierScorings : Scorings', data);
        resolve(data.documents);
      });
    });
  }

  public async getSupplierScoring(supplierScoringId: string): Promise<SupplierScoring> {
    console.log('SuppliersScoringsServices.getSupplierScoring: Start to get supplier scoring', supplierScoringId);

    return new Promise<SupplierScoring>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersScoringsServices.getSupplierScoring : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      await this.httpClient.post<Project>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `supplier-scorings`, filter: { '_id': { $eq: { $oid: supplierScoringId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersScoringsServices.getSupplierScoring : Alarm', data);
        resolve(data.document);
      });
    });
  }

  public async addSupplierScoring(supplierScoring: SupplierScoring): Promise<SupplierScoring> {
    console.log('SuppliersScoringsServices.addSupplierScoring: Start to add supplier scoring', supplierScoring);

    return new Promise<SupplierScoring>(async (resolve, reject) => {

      const supplierScoringData = { 
        supplierId: supplierScoring.supplierId, 
        scoringId: supplierScoring.scoringId, 
        description: supplierScoring.description, 
        score: supplierScoring.score, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersScoringsServices.addSupplierScoring : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<SupplierScoring>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `supplier-scorings`, document: supplierScoringData }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersScoringsServices.addSupplierScoring : Supplier scoring added', data);
        supplierScoring._id = data.insertedId;
        resolve(supplierScoring);
      });

    });
  }

  public async updateSupplierScoring(supplierScoring: SupplierScoring): Promise<SupplierScoring> {
    console.log('SuppliersScoringsServices.updateSupplierScoring: Start to update supplierScoring', supplierScoring);

    return new Promise<SupplierScoring>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersScoringsServices.updateSupplierScoring : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const supplierScoringData = { 
        description: supplierScoring.description, 
        score: supplierScoring.score, 
        modification: new Date()
      };
        
      await this.httpClient.post<SupplierScoring>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `supplier-scorings`, filter: { '_id': { $eq: { $oid: supplierScoring._id } } }, update: { $set: supplierScoringData } }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersScoringsServices.updateSupplierScoring : SupplierScoring updated', data);
        if(data.matchedCount > 0) {
          resolve(supplierScoring);
         } else {
          reject(data);
        }
      });
    });
  }

  public async deleteSupplierScoring(supplierScoring: SupplierScoring): Promise<boolean> {
    console.log('SuppliersScoringsServices.deleteSupplierScoring: Start to delete supplier scoring', supplierScoring);

    return new Promise<boolean>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SuppliersScoringsServices.deleteSupplierScoring : Token', data);
        token = data;
      });

      const deleteUrl = `${environment.mongoapi.apiDeleteOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<SupplierScoring>(deleteUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `supplier-scorings`, filter: { '_id': { $oid: supplierScoring._id } } }, httpOptions).toPromise().then((data: any) => {
        console.log('SuppliersScoringsServices.deleteSupplierScoring : Task deleted', data);
        if(data.deletedCount > 0) {
          resolve(true);
         } else {
          reject(data);
        }
      });
    });

  }
}
