<div *ngIf="isEditable" class="circle" customDropFiles (filesDropped)="onFileDropped($event)" [ngStyle]="{'background-color':  circleColor, 'width': size, 'height': size }">
  <img *ngIf="!showInitials" [src]="avatar" [ngStyle]="{'width': size, 'height': size }">
  <div *ngIf="showInitials" class="initials" [ngStyle]="{'font-size': fontsize }">
    {{ initials }}
  </div>
</div>
<div *ngIf="!isEditable" class="circle" [ngStyle]="{'background-color':  circleColor, 'width': size, 'height': size }">
  <img *ngIf="!showInitials" [src]="avatar" [ngStyle]="{'width': size, 'height': size }">
  <div *ngIf="showInitials" class="initials" [ngStyle]="{'font-size': fontsize }">
    {{ initials }}
  </div>
</div>
