import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';

import { Project } from '../../../models/entities/projects/project.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html'
})
export class ProjectsComponent implements AfterViewInit {

  public displayedColumns: string[] = ['phase', 'name', 'description', 'status', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Project>(this.dataAccessServices.projects);
  public panelOpenState = false;
  public searchedProjects: Project = new Project();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    public dataAccessServices: DataAccessServices,
    private projectsServices: ProjectsServices) {
    if (this.dataAccessServices.iscacherefreshed == false) {
      this.dataAccessServices.initialize().then(() => {
        console.log('ProjectsComponent.constructor : Application is initialized');
        this.dataSource = new MatTableDataSource<Project>(this.dataAccessServices.projects);
      });
    }
  }

  public ngAfterViewInit(): void {
    this.displayProjectMarkers(this.dataAccessServices.projects);
  }

  public onSearchedProjectsChanged(): void {
    if (this.searchedProjects.name !== undefined && this.searchedProjects.name !== null && this.searchedProjects.name !== '') {
      const filteredProjects = new List(this.dataAccessServices.projects).Where(p => p.name.toLowerCase().includes(this.searchedProjects.name.toLowerCase())).ToArray();
      this.dataSource = new MatTableDataSource<Project>(filteredProjects);
    }
  }

  public resetFilters(): void {
    this.searchedProjects = new Project();
    this.dataSource = new MatTableDataSource<Project>(this.dataAccessServices.projects);
  }

  public getStreamedImage(project: Project): SafeUrl {
    if (project.image === undefined || project.image === null) {
      return this.sanitizer.bypassSecurityTrustUrl('assets/images/empty-picture.jpg');
    } else {
      let objectURL = 'data:image/png;base64,' + project.image;
      return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }

  public addProject(): void {
    this.router.navigate(['addproject']);
  }

  public deleteProject(project: Project): void {
    this.projectsServices.deleteProject(project).then((isDeleted: boolean) => {
      console.log('ProjectsComponent.deleteProject : The project is deleted');
      this.dataSource = new MatTableDataSource<Project>(this.dataAccessServices.projects);
    });
  }

  private displayProjectMarkers(projects: Array<Project>): void {
    
  }

}
