import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { NgChartsModule } from 'ng2-charts';

import { MaterialModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { LoginModule } from './pages/login/login.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { ProjectsModule } from './pages/projects/projects.module';
import { ProjectStatusModule } from './pages/projectstatus/project.status.module';
import { FilesModule } from './pages/files/files.module';
import { SettingsModule } from './pages/settings/settings.module';
import { ProfileModule } from './pages/profile/profile.module';
import { FileReviewsModule } from './pages/filereviews/filereviews.module';
import { SuppliersModule } from './pages/suppliers/suppliers.module';
import { QuotesModule } from './pages/quotes/quotes.module';
import { ContractsModule } from './pages/contracts/contracts.module';
import { MaterialsModule } from './pages/materials/materials.module';
import { SchedulerModule } from './pages/scheduler/scheduler.module';
import { OrdersModule } from './pages/orders/orders.module';
import { AsyncPipe } from '@angular/common';
import { GoogleMessagingServices } from './services/commons/google.messaging.services';
import { CampaignsModule } from './pages/campaigns/campaigns.module';
import { SuppliersScoringModule } from './pages/suppliers-scoring/suppliers-scoring.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,

    MaterialModule,

    AppRoutingModule,

    SharedModule,
    LoginModule,
    DashboardModule,
    ProjectsModule,
    ProjectStatusModule,
    FilesModule,
    SettingsModule,
    ProfileModule,
    FileReviewsModule,
    SuppliersModule,
    QuotesModule,
    ContractsModule,
    MaterialsModule,
    SchedulerModule,
    OrdersModule,
    CampaignsModule,
    SuppliersScoringModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    NgChartsModule,
    /*
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage())
    */
  ],
  providers: [GoogleMessagingServices, AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
