<div class="mt-3">

  <div class="row">
    <mat-label>{{ 'supplierscoring.supplier' | translate }}</mat-label>
    {{ supplierScoring.supplier.name }}
  </div>

  <div class="row mt-3">
    <mat-label>{{ 'supplierscoring.scoring' | translate }}</mat-label>
    {{ supplierScoring.scoring.title }}
  </div>

  <div class="row mt-3">
    <mat-form-field>
      <mat-label>{{ 'supplierscoring.description' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="supplierScoring.description"></textarea>
    </mat-form-field>
  </div>

  <div class="row mt-3">
    <mat-form-field>
      <mat-label>{{ 'supplierscoring.score' | translate }}</mat-label>
      <input matInput type="number" name="projectName" [(ngModel)]="supplierScoring.score">
    </mat-form-field>
  </div>

  <div class="row form-group d-flex justify-content-center">
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="['/projects', 'project', this.selectedProject._id, 'suppliersscorings']">{{ 'generic.cancel' | translate }}</button>
    <button mat-raised-button color="primary" class="btn-block" (click)="updateSupplierScoring()">{{ 'generic.update' | translate }}</button>
  </div>
</div>
