import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';

@Injectable({
  providedIn: 'root'
})
export class AnnotationTypesServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getAnnotationType(annotationTypeId: string): Promise<AnnotationType> {
      console.log('AnnotationTypesServices.getAnnotationType: Start to get annotation type', annotationTypeId);
  
      return new Promise<AnnotationType>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('AnnotationTypesServices.getAnnotationType : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<AnnotationType>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-types`, filter: { '_id': { $oid: annotationTypeId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('AnnotationTypesServices.getAnnotationType : Annotation type', data);
          resolve(data.document);
        });
      });
  }
  
  public async getAnnotationTypes(project: Project): Promise<Array<AnnotationType>> {
    console.log('AnnotationTypesServices.getAnnotationTypes: Start to get annotation types', project);

    return new Promise<Array<AnnotationType>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationTypesServices.getAnnotationTypes : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<AnnotationType[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-types`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationTypesServices.getAnnotationTypes : Annotation types', data);
        resolve(data.documents);
      });
    });
  }

  public async addAnnotationType(annotationType: AnnotationType): Promise<AnnotationType> {
    console.log('AnnotationTypesServices.addAnnotationType: Start to add annotation type', annotationType);

    return new Promise<AnnotationType>(async (resolve, reject) => {

      const annotationTypeData = { 
        projectId: annotationType.projectId, 
        color: annotationType.color, 
        name: annotationType.name, 
        phase: annotationType.phase, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationTypesServices.addAnnotationType : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-types`, document: annotationTypeData }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationTypesServices.addAnnotationType : Annotation type added', data);
        annotationType._id = data.insertedId;
        resolve(annotationType);
      });

    });
  }

  public async updateAnnotationType(annotationType: AnnotationType): Promise<AnnotationType> {
    console.log('AnnotationTypesServices.updateAnnotationType: Start to update annotationType', annotationType);

    return new Promise<AnnotationType>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AnnotationTypesServices.updateAnnotationType : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const annotationTypeData = { 
        color: annotationType.color, 
        name: annotationType.name, 
        phase: annotationType.phase, 
        modification: new Date()
      };
        
      await this.httpClient.post<AnnotationType>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `annotation-types`, filter: { '_id': { $eq: { $oid: annotationType._id } } }, update: { $set: annotationTypeData } }, httpOptions).toPromise().then((data: any) => {
        console.log('AnnotationTypesServices.updateAnnotationType : updateAnnotationType updated', data);
        if(data.matchedCount > 0) {
          resolve(annotationType);
         } else {
          reject(data);
        }
      });
    });
  }
}
