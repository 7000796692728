import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from 'src/app/services/data.access.services';
import { AnnotationTypesServices } from 'src/app/services/annotations/annotation.types.services';
import { GroupsServices } from 'src/app/services/users/groups.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { UserGroupsServices } from 'src/app/services/users/user.groups.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss']
})
export class AnnotationsComponent {

  public panelOpenState: boolean;
  public selectedProject: Project = null;
  public searchedAnnotationTypes = new AnnotationType();
  public annotationTypes: Array<AnnotationType> = new Array<AnnotationType>();
  public annotationTypesDisplayedColumns: string[] = ['name', 'color', 'phase', 'modification', 'creation', 'edit', 'delete'];
  public annotationTypesDataSource = new MatTableDataSource<AnnotationType>(this.annotationTypes);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersServices,
    private dataAccessServices: DataAccessServices,
    private userGroupsServices: UserGroupsServices,
    private groupsServices: GroupsServices,
    private annotationTypesServices: AnnotationTypesServices) {
    this.activatedRoute.params.subscribe(params => {
      console.log('AnnotationsComponent.constructor : Retrieved params', params);
      const projectid = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectid);
    });
  }

  async ngOnInit() {
    await this.annotationTypesServices.getAnnotationTypes(this.selectedProject).then(data => {
      console.log('AnnotationsComponent.ngOnInit : Annotation types', data);
      this.annotationTypes = data;
      this.annotationTypesDataSource = new MatTableDataSource<AnnotationType>(this.annotationTypes);
    });
  } 

  public resetAnnotationTypeFilters() {

  }

  public onSearchedAnnotationTypesChanged() {

  }

  public deleteAnnotationType(annotationType: AnnotationType) {

  }
  
}
