import { Component, ElementRef, Inject, Renderer2, ViewChild } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';

import { RevisionFile } from "src/app/models/entities/files/revision.file.model";

@Component({
  selector: 'signature-modal',
  templateUrl: 'signature.modal.html'
})
export class SignatureModal {
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;
  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300
  };

  constructor(
    public dialogRef: MatDialogRef<SignatureModal>,
    @Inject(MAT_DIALOG_DATA) public revisionFile: RevisionFile,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear();
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    console.log(this.signaturePad.toDataURL());
    this.revisionFile.signature = this.signaturePad.toDataURL();
  }

  drawStart(event: MouseEvent | Touch) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearPad() {
    this.signaturePad.clear();
  }
}