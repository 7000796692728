import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { Sensor } from 'src/app/models/entities/iot/sensors/sensor.model';
import { BoxSensor } from 'src/app/models/entities/iot/sensors/box.sensor.model';
import { SensorType } from 'src/app/models/entities/iot/sensors/sensor.type.model';

@Injectable({
  providedIn: 'root'
})
export class SensorTypesServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getSensorType(sensorTypeId: string): Promise<SensorType> {
      console.log('SensorTypesServices.getSensorType: Start to get sensor type', sensorTypeId);
  
      return new Promise<SensorType>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('SensorTypesServices.getSensorType : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<SensorType>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `sensor-types`, filter: { '_id': { $oid: sensorTypeId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('SensorTypesServices.getSensorType : Sensor type', data);
          resolve(data.document);
        });
      });
  }
}
