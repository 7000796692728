<div class="row">

  <div class="col-md-1">
    <button mat-flat-button color="primary" [routerLink]="['/projects', 'project', this.selectedProject._id, 'materials', 'add']"><mat-icon>add</mat-icon> {{ 'generic.add' | translate }}</button>
  </div>

</div>

<div class="mt-3">
  
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'material.name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>

    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef> {{ 'material.supplier' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.supplier.name }} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> {{ 'material.address' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.originalAddress }} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="[element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteMaterial(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
                 *ngIf="this.materials.length > 0"
                 [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</div>
