import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { AnnotationType } from 'src/app/models/entities/annotations/annotation.type.model';
import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { Slot } from 'src/app/models/entities/projects/slot.model';

@Injectable({
  providedIn: 'root'
})
export class BoxesServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getBox(boxId: string): Promise<Box> {
      console.log('BoxesServices.getBox: Start to get box', boxId);
  
      return new Promise<Box>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('BoxesServices.getBox : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<Box>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `boxes`, filter: { '_id': { $oid: boxId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('BoxesServices.getBox : Box', data);
          resolve(data.document);
        });
      });
  }

  public async getBoxBySlot(slot: Slot): Promise<Box> {
    console.log('BoxesServices.getBox: Start to get box', slot);

    return new Promise<Box>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('BoxesServices.getBox : Token', data);
        token = data;
      });
      
      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let boxConfiguration = null;
      await this.httpClient.post<Box[]>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `box-configurations`, filter: { 'slotId': slot._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('BoxesServices.getBoxes : Box configurations', data);
        boxConfiguration = data.document;
      });
        
      await this.httpClient.post<Box>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `boxes`, filter: { '_id': { $oid: boxConfiguration.boxId } } }, httpOptions).toPromise().then((data: any) => {
        console.log('BoxesServices.getBox : Box', data);
        resolve(data.document);
      });
    });
}
  
  public async getBoxes(project: Project): Promise<Array<Box>> {
    console.log('BoxesServices.getBoxes: Start to get boxes', project);

    return new Promise<Array<Box>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('BoxesServices.getBoxes : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      let boxConfigurations = null;
      await this.httpClient.post<Box[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `box-configurations`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('BoxesServices.getBoxes : Box configurations', data);
        boxConfigurations = data.documents
        //resolve(data.documents);
      });

      let boxes = [];
      for(let i = 0; i < boxConfigurations.length; i++) {
        let boxConfiguration = boxConfigurations[i];
        await this.httpClient.post<Box[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `boxes`, filter: { '_id': { $eq: { $oid: boxConfiguration.boxId } } } }, httpOptions).toPromise().then((data: any) => {
          console.log('BoxesServices.getBoxes : Boxes', data);
          boxes = boxes.concat(data.documents);
        });
      }
      resolve(boxes);
    });
  }

  public async updateBox(box: Box): Promise<Box> {
    console.log('BoxesServices.updateBox: Start to update box', box);

    return new Promise<Box>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('BoxesServices.updateBox : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const boxData = { 
        modification: new Date()
      };
        
      await this.httpClient.post<Box>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `boxes`, filter: { '_id': { $eq: { $oid: box._id } } }, update: { $set: boxData } }, httpOptions).toPromise().then((data: any) => {
        console.log('BoxesServices.updateBox : Box updated', data);
        if(data.matchedCount > 0) {
          resolve(box);
         } else {
          reject(data);
        }
      });
    });
  }
}
