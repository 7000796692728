export const environment = {
  production: false,
  mongoapi: {
    appId: 'data-hkpta',
    userApplicationKey: '2CWU31vqr1ZPGmDUXA9priyzbGCscfK3Ibx7UwviINJIW20JFvXuyhcbvwDL5cD0',
    authenticationUrl: 'https://services.cloud.mongodb.com/api/client/v2.0/app',
    applicationKey: '2CWU31vqr1ZPGmDUXA9priyzbGCscfK3Ibx7UwviINJIW20JFvXuyhcbvwDL5cD0',
    apiFindUrl: 'https://us-east-1.aws.data.mongodb-api.com/app/data-hkpta/endpoint/data/v1/action/find',
    apiInsertOneUrl: 'https://us-east-1.aws.data.mongodb-api.com/app/data-hkpta/endpoint/data/v1/action/insertOne',
    apiFindOneUrl: 'https://us-east-1.aws.data.mongodb-api.com/app/data-hkpta/endpoint/data/v1/action/findOne',
    apiUpdateOneUrl: 'https://us-east-1.aws.data.mongodb-api.com/app/data-hkpta/endpoint/data/v1/action/updateOne',
    apiDeleteOneUrl: 'https://us-east-1.aws.data.mongodb-api.com/app/data-hkpta/endpoint/data/v1/action/deleteOne',
    dataSource: 'SGBIMCluster',
    database: 'prd-sg-bim',
  },
  chunkSize: 500000,
  firebase: {
    apiKey: "AIzaSyDq_4xo-0oMX5tEn8Y4_oID_r-v9qJktTI",
    authDomain: "bimosoft-2f86c.firebaseapp.com",
    projectId: "bimosoft-2f86c",
    storageBucket: "bimosoft-2f86c.appspot.com",
    messagingSenderId: "148533469075",
    appId: "1:148533469075:web:140acb5b99f0bc6affd266",
    measurementId: "G-PL6E6KNWWT"
  },
  autodesk: {
    authentication: {
      url: 'https://developer.api.autodesk.com/authentication/v1/authenticate',
      clientId: 'm0SvgCZqIbYc9XUlm0OiwUrLY3g1MvHm',
      clientSecret: 'ssM9aRHiInRw1hmq',
      grantType: 'client_credentials'
    },
    bucket: {
      url: 'https://developer.api.autodesk.com/oss/v2/buckets'
    }
  },
  ecology: {
    truckCapacity: 15,
    truckConsumption: 30,
    kgCO2PerGasLiter: 2.4
  }
};
