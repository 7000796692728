<div class="form-container">

  <div class="row mt-3">
    <mat-form-field>
      <mat-label>{{ 'quote.name' | translate }}</mat-label>
      <input matInput [(ngModel)]="quote.name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'quote.comment' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="quote.comment"></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'quote.amount' | translate }}</mat-label>
      <input matInput name="quoteAmount" [(ngModel)]="quote.amount">
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="{{ 'quote.status' | translate }}" [(ngModel)]="quote.status">
        <mat-option [value]="0">{{ 'quote.status.proofread' | translate }}</mat-option>
        <mat-option [value]="1">{{ 'quote.status.accepted' | translate }}</mat-option>
        <mat-option [value]="2">{{ 'quote.status.changerequest' | translate }}</mat-option>
        <mat-option [value]="3">{{ 'quote.status.denied' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="row">
    <button mat-raised-button class="mt-3" color="primary" (click)="convertToContract()">{{ 'quote.converttocontract' | translate }}</button>
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="['/projects', 'project', this.selectedProject._id, 'quotes']">{{ 'generic.cancel' | translate }}</button>
    <button mat-raised-button class="mt-3" color="primary" (click)="updateQuote()">{{ 'generic.validate' | translate }}</button>
  </div>
</div>
