<div class="row">

  <div class="col-md-1">
    <button mat-flat-button color="primary" [routerLink]="['/projects', 'project', this.selectedProject._id, 'campaigns', 'add']"><mat-icon>add</mat-icon> {{ 'generic.add' | translate }}</button>
  </div>

</div>

<div class="mt-3">
  
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'campaign.name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef> {{ 'campaign.color' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.color }} </td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef> {{ 'campaign.startDate' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.startDate | date:'shortDate' }} </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef> {{ 'campaign.endDate' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.endDate | date:'shortDate' }} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['campaign', element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteCampaign(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
                 *ngIf="campaigns.length > 0"
                 [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</div>
