import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';

import { MatSidenav } from '@angular/material/sidenav';
import { List } from 'linqts';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SettingsServices } from './services/commons/settings.services';
import { LocalStorageServices } from './services/commons/local.storage.services';
import { DataAccessServices } from './services/data.access.services';
import { GoogleMessagingServices } from './services/commons/google.messaging.services';

import { Project } from './models/entities/projects/project.model';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('sidenav') sidenav: MatSidenav;
  public selectedProject: Project = null;
  

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    public settingsServices: SettingsServices,
    private localStorageServices: LocalStorageServices,
    public dataAccessServices: DataAccessServices,
    private googleMessagingServices: GoogleMessagingServices
  ) {
    this.settingsServices.initialize();
  }

  public async ngOnInit() {
    
    this.googleMessagingServices.messageReceived.subscribe(message => {
      console.log(`AppComponent.ngOnInit : messageReceived - ${JSON.stringify(message)}`);
      console.dir(message);
      this.snackBar.open(`Project ${message.data.projectId}`,  message.data.sensorValue,  {
        duration: 10000,
      });
    });
    this.router.events.subscribe((value: any) => {
      if (value.urlAfterRedirects?.includes('/projects/project/')
        && this.dataAccessServices.projects !== null
        && this.dataAccessServices.projects !== undefined
        && this.dataAccessServices.projects.length > 0) {
        const projectId = value.urlAfterRedirects.split('/projects/project/')[1].split('/')[0];
        console.log('AppComponent.ngOnInit :', projectId);
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
      }
    });
  }

  public logout(): void {
    if (this.sidenav.opened) {
      this.sidenav.toggle();
    }
    this.localStorageServices.remove('access_token');
    this.localStorageServices.remove('currentuser');
    this.dataAccessServices.currentUser = null;
    this.router.navigate(['login']);
  }
}
