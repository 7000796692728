<h1 mat-dialog-title>{{ 'modal.annotationsfilters.title' | translate }}</h1>
<div mat-dialog-content>
  <div class="row mt-3">
    <mat-checkbox [(ngModel)]="isAllChecked" (ngModelChange)="updateAllChecked()">{{ 'modal.annotationsfilters.allselected' | translate }}</mat-checkbox>
  </div>
  <div *ngFor="let annotationType of annotationTypes" class="row mt-3">
    <mat-checkbox [(ngModel)]="annotationType.visible">{{ annotationType.name  }}</mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="annotationTypes" cdkFocusInitial>{{ 'generic.validate' | translate }}</button>
</div>