import { EntityBase } from "../entity.base.model";
import { UserProfile } from "./user.profile.enum";

export class User extends EntityBase {
  static readonly prefix_salt: string = 'gl0b4l_';
  static readonly suffix_salt: string = '_v3st4';

  login: string;
  password: string;
  firstname: string;
  lastname: string;
  email: string;
  picture: string;
  profile: UserProfile;
}
