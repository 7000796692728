<div class="row form-group">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
          <mat-card-title>{{ 'group.group' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div class="form-container">
          <div class="row">
            <div class="col-md-12">
              <div class="row" *ngIf="group !== null && group !== undefined">
                <mat-form-field>
                  <input matInput placeholder="{{ 'group.namesample' | translate }}" [(ngModel)]="group.name">
                </mat-form-field>
              </div>
              <div class="row">
                <button mat-raised-button class="mt-3" color="basic" [routerLink]="['groups']">{{ 'generic.cancel' | translate }}</button>
                <button mat-raised-button class="mt-3" color="primary" (click)="updateGroup()">{{ 'generic.validate' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <form class="form">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'group.adduser' | translate }}</mat-label>
                  <input type="text" 
                  matInput
                  [matAutocomplete]="auto"
                  [(ngModel)]="filter"
                  (ngModelChange)="onFilterChanged()"
                  [ngModelOptions]="{standalone: true}">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions" [value]="option.firstname + ' ' + option.lastname">
                      {{ option.firstname }} {{ option.lastname }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
            <div class="col-md-2">
              <button mat-raised-button class="mt-3" color="primary" (click)="addSelectedUser()">{{ 'generic.add' | translate }}</button>
            </div>
            <div class="col-md-4">
              <button mat-raised-button class="mt-3" color="primary" (click)="addUser()">{{ 'group.adduser' | translate }}</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <table mat-table [dataSource]="dataSource">
            
                <ng-container matColumnDef="firstname">
                  <th mat-header-cell *matHeaderCellDef> {{ 'user.firstname' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.firstname }} </td>
                </ng-container>
            
                <ng-container matColumnDef="lastname">
                  <th mat-header-cell *matHeaderCellDef> {{ 'user.lastname' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.lastname }} </td>
                </ng-container>
            
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef> {{ 'user.email' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
                </ng-container>

                <ng-container matColumnDef="remove">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element"> 
                    <button mat-flat-button color="warn" (click)="removeUserGroup(element)">
                      {{ 'generic.remove' | translate }}
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator
                             [pageSizeOptions]="[5, 10, 20]"
                             showFirstLastButtons
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

