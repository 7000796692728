import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { MaterialsComponent } from './list/materials.component';
import { AddMaterialComponent } from './add/add.material.component';
import { MaterialComponent } from './details/material.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/materials', component: MaterialsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/materials/:materialid', component: MaterialComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/materials/add', component: AddMaterialComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MaterialsRoutingModule { }
