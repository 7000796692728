import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { Project } from '../../../models/entities/projects/project.model';
import { CampaignsServices } from 'src/app/services/campaigns/campaigns.services';
import { Campaign } from 'src/app/models/entities/campaigns/campaign.model';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html'
})
export class CampaignComponent {

  public selectedCampaign: Campaign = new Campaign();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private campaignsServices: CampaignsServices) {
      
    }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      console.log('CampaignComponent.ngOnInit : Retrieved params', params);
      const projectId = params['projectid'];
      const project = new List(this.dataAccessServices.projects).First(a => a._id == projectId);
      if (project === null || project === undefined) {
        this.router.navigate(['/projects']);
      }

      const campaignid = params['campaignid'];
      await this.campaignsServices.getCampaign(campaignid).then((data: Campaign) => {
        console.log('CampaignComponent.ngOnInit: Campaign', data);
        this.selectedCampaign = data;
      });
    });
  }

  public async updateCampaign(): Promise<void> {
    await this.campaignsServices.updateCampaign(this.selectedCampaign).then((data: Campaign) => {
      console.log('AddCampaignComponent.updateCampaign: Campaign updated', data);
      this.selectedCampaign = data;
    });

    this.router.navigate(['/projects', 'project', this.selectedCampaign.projectId, 'campaigns']);
  }
}
