import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as uuid from 'uuid';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from '../../models/entities/files/file.model';
import { RevisionFile } from '../../models/entities/files/revision.file.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { RevisionFileComment } from 'src/app/models/entities/files/revision.file.comment.model';

@Injectable({
  providedIn: 'root'
})
export class RevisionFileCommentsServices {

  constructor(
    private httpClient: HttpClient,
    private storage: AngularFireStorage,
    private realmTokenServices: RealmTokenServices) { }

  public async getRevisionFileComments(revisionFile: RevisionFile): Promise<Array<RevisionFileComment>> {
    console.log('RevisionFileCommentsServices.getRevisionFileComments: Start to get revisions files comments', revisionFile);

    return new Promise<Array<RevisionFileComment>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFileCommentsServices.getRevisionFileComments : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<RevisionFileComment[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-file-comments`, filter: { 'revisionFileId': revisionFile._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('RevisionFileCommentsServices.getRevisionFileComments : Revision file comments', data);
        resolve(data.documents);
      });
    });
  }

  public async addRevisionFileComment(revisionFileComment: RevisionFileComment): Promise<RevisionFileComment> {
    console.log('RevisionFileCommentsServices.addRevisionFileComment: Start to add revisionFileComment', revisionFileComment);

    return new Promise<RevisionFileComment>(async (resolve, reject) => {

      const revisionFileCommentData = { 
        revisionFileId: revisionFileComment.revisionFileId,
        comment: revisionFileComment.comment,
        ownerId: revisionFileComment.ownerId, 
        modification: new Date(),
        creation: new Date() 
      };
      
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFileCommentsServices.addRevisionFileComment : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<RevisionFile>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-file-comments`, document: revisionFileCommentData }, httpOptions).toPromise().then((data: any) => {
        console.log('RevisionFileCommentsServices.addRevisionFileComment : Revision file comment added', data);
        revisionFileComment._id = data.insertedId;
        resolve(revisionFileComment);
      });

    });
  }

  public async updateRevisionFileComment(revisionFileComment: RevisionFileComment): Promise<RevisionFileComment> {
    console.log('RevisionFileCommentsServices.updateRevisionFileComment: Start to update revision file comment', revisionFileComment);

    return new Promise<RevisionFileComment>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('RevisionFileCommentsServices.updateRevisionFileComment : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const revisionFileCommentData = { 
        comment: revisionFileComment.comment,
        modification: new Date()
      };
        
      await this.httpClient.post<RevisionFile>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `revision-file-comments`, filter: { '_id': { $eq: { $oid: revisionFileComment._id } } }, update: { $set: revisionFileCommentData } }, httpOptions).toPromise().then((data: any) => {
        console.log('FilesServices.updateFile : ProjectFile updated', data);
        if(data.matchedCount > 0) {
          resolve(revisionFileComment);
         } else {
          reject(data);
        }
      });
    });
  }
}
