<div class="form-container">

  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div customDropFiles (filesDropped)="onImageDropped($event)" (clicked)="onClicked($event)">
          <img mat-card-image [src]="getStreamedImage()" width="250" class="image-container" />
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <mat-form-field>
          <input matInput placeholder="{{ 'project.namesample' | translate }}" [(ngModel)]="project.name">
        </mat-form-field>

        <mat-form-field>
          <textarea matInput placeholder="{{ 'project.descriptionsample' | translate }}" rows="8" [(ngModel)]="project.description"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="row">
        <mat-form-field>
          <textarea matInput placeholder="{{ 'project.addresssample' | translate }}" [(ngModel)]="project.address"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-11">
          <div class="row">
            <mat-form-field>
              <input type="text" placeholder="{{ 'project.statussample' | translate }}" matInput [formControl]="projectStatusFormControl" [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredProjectStatusOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-1">
          <button mat-raised-button color="primary" (click)="addProjectStatus()">{{ 'generic.add' | translate }}</button>
        </div>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-select placeholder="{{ 'project.phase' | translate }}" [(ngModel)]="project.phase">
            <mat-option [value]="0">{{ 'project.phases.conception' | translate }}</mat-option>
            <mat-option [value]="1">{{ 'project.phases.construction' | translate }}</mat-option>
            <mat-option [value]="2">{{ 'project.phases.exploitation' | translate }}</mat-option>
            <mat-option [value]="3">{{ 'project.phases.destruction' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="'/projects'">{{ 'generic.cancel' | translate }}</button>

    <button mat-raised-button class="mt-3" color="primary" (click)="addProject()">{{ 'generic.validate' | translate }}</button>
  </div>
</div>
