import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { List } from 'linqts';
import { Task } from 'src/app/models/entities/tasks/task.model';
import { TaskType } from 'src/app/models/entities/tasks/task.type.enum';

@Component({
  selector: 'timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent {

  @ViewChild('dates', { static: false }) datesColumn: ElementRef;
  @ViewChild('hours', { static: false }) hoursColumn: ElementRef;
  @ViewChild('quarthours', { static: false }) quartHoursColumn: ElementRef;
  @ViewChild('grid', { static: false }) gridPart: ElementRef;
  //@ViewChild('diagram', { static: false }) diagramPart: ElementRef;
  
  @Output() public selectedTaskChanged = new EventEmitter<Task>();
  
  @Input() tasks: Array<Task>;
  @Input() public type: string = 'days'; // days or tasks : lignes en jours ou en taches
  @Input() public viewType: string = 'week';
  @Input() public selectedDate: Date = new Date();
  @Input() public startTime: string;
  @Input() public endTime: string;
  @Input() public hoursDisplayed: number;
  @Input() public isCellPhone: boolean;
  
  private currentDate: Date = new Date();
  private schedulerStartDate: Date = new Date();
  private schedulerEndDate: Date = new Date();
  private hourCellWidth: number = 200;
  private cellWidth: number = 50;
  private cellHeight: number = 40;

  constructor() {
    this.schedulerStartDate = this.getFirstDayOfWeek();
    this.schedulerEndDate = this.getLastDayOfWeek();
  }

  async ngOnChanges() {
    console.log('TimesheetComponent.ngOnChanges : Change detected', this.tasks, this.startTime, this.endTime);
    if(this.tasks.length > 0) {
      this.removeDates();
      this.drawDatesList();
      this.removeHours();
      this.drawHoursList();
      this.removeQuartHours();
      this.drawQuartHoursList();
      this.removeGrid();
      this.drawGridList();

      this.removeTasks();
      this.tasks = new List(this.tasks).Where(t => new Date(t.start) >= this.schedulerStartDate && new Date(t.end) <= this.schedulerEndDate).ToArray();
      this.tasks.forEach(task => {
        this.computeTask(task);
      });
    }
  }

  async ngAfterViewInit() {
    console.log('TimesheetComponent.ngAfterViewInit: After gantt view init');
  }

  private isWeekEnd(date: Date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 pour dimanche, 6 pour samedi
  }

  private getFirstDayOfWeek() {
    const currentDate = new Date(this.selectedDate);
    const dayOfWeek = currentDate.getDay(); // Récupérez le jour de la semaine (0 pour dimanche, 1 pour lundi, ..., 6 pour samedi)
    const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Calculer la différence pour obtenir le premier jour de la semaine
    const firstDayOfWeek = new Date(currentDate.setDate(diff));
    // Réinitialiser les heures, minutes, secondes et millisecondes à minuit
    firstDayOfWeek.setHours(0);
    firstDayOfWeek.setMinutes(0);
    firstDayOfWeek.setSeconds(0);
    firstDayOfWeek.setMilliseconds(0);
    return firstDayOfWeek;
  }

  private getLastDayOfWeek() {
    const currentDate = new Date(this.selectedDate);
    const dayOfWeek = currentDate.getDay(); // Récupérez le jour de la semaine (0 pour dimanche, 1 pour lundi, ..., 6 pour samedi)
    const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 7); // Calculer la différence pour obtenir le dernier jour de la semaine
    const lastDayOfWeek = new Date(currentDate.setDate(diff));
    // Réinitialiser les heures, minutes, secondes et millisecondes à minuit
    lastDayOfWeek.setHours(0);
    lastDayOfWeek.setMinutes(0);
    lastDayOfWeek.setSeconds(0);
    lastDayOfWeek.setMilliseconds(0);
    return lastDayOfWeek;
  }

  private drawDatesList() {
    let numberOfLines = 0;
    if(this.viewType === 'week') {
      numberOfLines = 7;
    } else if(this.viewType === 'month') {
      numberOfLines = 31;
    }
    for(let i = 0; i < numberOfLines; i++) {
      const dateLine = document.createElement('div');
      dateLine.id = 'dates-list-' + i;
      const date = new Date(this.schedulerStartDate);
      date.setDate(this.schedulerStartDate.getDate() + i);
      if(this.isWeekEnd(date)) {
        dateLine.style.backgroundColor = 'silver';
      }
      dateLine.textContent = `${date.toLocaleDateString()}`;
      dateLine.style.fontWeight = 'normal';
      dateLine.style.fontSize  = '15px';
      dateLine.style.paddingTop = `8px`;
      dateLine.style.paddingLeft = `8px`;
      if(i === 0) {
        dateLine.style.borderTop = `1px solid #E3E3E3`;
        dateLine.style.marginTop = `80px`;
      }
      dateLine.style.borderBottom = `1px solid #E3E3E3`;
      dateLine.style.borderLeft = `1px solid #E3E3E3`;
      dateLine.style.borderRight = `1px solid #E3E3E3`;
      dateLine.style.height = `${this.cellHeight}px`;
      if(date.getFullYear() === this.currentDate.getFullYear() && 
      date.getMonth() === this.currentDate.getMonth() && 
      date.getDay() === this.currentDate.getDay()) {
        dateLine.style.color = `orange`;
      }
      this.datesColumn.nativeElement.appendChild(dateLine);
    }
  }

  private drawHoursList() {
    const hoursRow = document.createElement('div');
    hoursRow.id = 'hours-list';
    hoursRow.style.display = 'flex';
    const startTimeHour = parseInt(this.startTime.split(':')[0]);
    for (let i = 0; i < this.hoursDisplayed; i++) {
      const hourLine = document.createElement('div');
      const hour = i + startTimeHour;
      const displayHour = hour % 12 || 12;
      const AmPm = hour < 12 ? 'AM' : 'PM';
      hourLine.textContent = `${displayHour} ${AmPm}`; // Affichez les heures en ligne
      hourLine.style.display = 'flex';
      hourLine.style.fontWeight = 'normal';
      hourLine.style.fontSize = '15px';
      hourLine.style.borderTop = `1px solid #E3E3E3`;
      hourLine.style.borderBottom = `1px solid #E3E3E3`;
      hourLine.style.borderLeft = `1px solid #E3E3E3`;
      hourLine.style.borderRight = `1px solid #E3E3E3`;
      hourLine.style.height = `${this.cellHeight}px`;
      hourLine.style.width = `${this.hourCellWidth}px`;
      hourLine.style.padding = `8px`;
      hourLine.style.justifyContent = 'center';
      hoursRow.appendChild(hourLine);
    }

    this.hoursColumn.nativeElement.appendChild(hoursRow);
  }

  private drawQuartHoursList() {
    const quartHoursRow = document.createElement('div');
    quartHoursRow.id = 'quart-hours-list';
    quartHoursRow.style.display = 'flex';
    const numberOfItems = this.isCellPhone ? 0 : this.hoursDisplayed * 4;
    for (let i = 0; i < numberOfItems; i++) {
      const hourLine = document.createElement('div');
      const quart = i * 15 % 60;
      hourLine.textContent = `${quart}`;
      hourLine.style.display = 'flex';
      hourLine.style.overflow = 'hidden';
      hourLine.style.fontWeight = 'normal';
      hourLine.style.fontSize = '15px';
      hourLine.style.borderTop = `1px solid #E3E3E3`;
      hourLine.style.borderBottom = `1px solid #E3E3E3`;
      hourLine.style.borderLeft = `1px solid #E3E3E3`;
      hourLine.style.borderRight = `1px solid #E3E3E3`;
      hourLine.style.height = `${this.cellHeight}px`;
      hourLine.style.width = `${this.cellWidth}px`;
      hourLine.style.padding = `8px`;
      hourLine.style.justifyContent = 'center';

      quartHoursRow.appendChild(hourLine);
    }

    this.quartHoursColumn.nativeElement.appendChild(quartHoursRow);
  }

  private drawGridList() {
    let numberOfLines = 0;
    if(this.viewType === 'week') {
      numberOfLines = 7;
    } else if(this.viewType === 'month') {
      numberOfLines = 31;
    }
    for (let i = 0; i < numberOfLines; i++) {
      const lineRow = document.createElement('div');
      lineRow.id = 'grid-line-' + i;
      lineRow.style.display = 'flex';
      const date = new Date(this.schedulerStartDate);
      date.setDate(this.schedulerStartDate.getDate() + i);
      if(this.isWeekEnd(date)) {
        lineRow.style.backgroundColor = 'silver';
      }
      for (let j = 0; j < this.hoursDisplayed * 4; j++) {
        const cell = document.createElement('div');
        cell.id = `cell-${i}-${j}`;
        cell.style.position = 'relative';
        //cell.style.border = `0px solid #E3E3E3`;
        cell.style.height = `${this.cellHeight}px`;
        cell.style.width = `${this.cellWidth}px`;
        lineRow.appendChild(cell);
      }
      this.gridPart.nativeElement.appendChild(lineRow);
    }
  }

  private computeTask(task: Task) {
    console.log('TimesheetComponent.computeTask: Compute task', task);

    // cas 1 : A ignorer
    // l'heure de fin de la tache est plus petite que l'heure de début du tableau 
    // ou l'heure de début de la tache est plus grande que l'heure de fin du tableau 
    // cas 2 : A tracer
    // l'heure de début de la tache est plus grande ou égale que l'heure de début du tableau
    // et l'heure de fin de la tache est plus petite ou égale que l'heure de fin du tableau 
    // cas 3 : A tronquer à gauche
    // l'heure de début de la tache est plus petite que l'heure de début du tableau
    // et l'heure de fin de la tache est plus grande que l'heure de début du tableau
    // cas 4 : A tronquer à droite
    // l'heure de fin de la tache est plus grande que l'heure de fin du tableau
    // et l'heure de début de la tache est plus petite que l'heure de fin du tableau

    const startTimeHour = parseInt(this.startTime.split(':')[0]);
    const endTimeHour = parseInt(this.endTime.split(':')[0]);
    const taskStartDateTime = new Date(task.start);
    const taskEndDateTime = new Date(task.end);

    // TODO gérer les cas au les taches dépasse dans la journée ex : 22pm -> 2 AM et donc change de date
    if (taskEndDateTime.getHours() < startTimeHour || taskStartDateTime.getHours() > endTimeHour) {
      console.log('TimesheetComponent.computeTask: Case 1', task);
    } else if (taskStartDateTime.getHours() >= startTimeHour && taskEndDateTime.getHours() <= endTimeHour) {
      console.log('TimesheetComponent.computeTask: Case 2', task);
      const indexRow = taskStartDateTime.getDay() - 1;
      const indexColumn = (taskStartDateTime.getHours() - startTimeHour) * 4 + Math.floor(taskStartDateTime.getMinutes() / 15);
      const cell = document.querySelector(`div#cell-${indexRow}-${indexColumn}`) as HTMLElement;
      const totalMilliseconds = taskEndDateTime.getTime() - taskStartDateTime.getTime();
      const totalQuarts = totalMilliseconds / (1000 * 60 * 15);
      this.drawTask(task, cell.offsetTop, cell.offsetLeft, totalQuarts * 0.67); // pourquoi réduire de 33% ?
    } else if (taskStartDateTime.getHours() < startTimeHour && taskEndDateTime.getHours() > startTimeHour) {
      console.log('TimesheetComponent.computeTask: Case 3', task);
      const indexRow = taskStartDateTime.getDay() - 1;
      const indexColumn = (taskStartDateTime.getHours() - startTimeHour) * 4 + Math.floor(taskStartDateTime.getMinutes() / 15);
      const cell = document.querySelector(`div#cell-${indexRow}-${indexColumn}`) as HTMLElement;
      const totalMilliseconds = taskEndDateTime.getTime() - taskStartDateTime.getTime();
      let totalQuarts = totalMilliseconds / (1000 * 60 * 15);
      if (taskStartDateTime.getHours() < startTimeHour || (taskStartDateTime.getHours() === startTimeHour && taskStartDateTime.getMinutes() > 0)) {
        const additionalQuarts = (taskStartDateTime.getHours() - startTimeHour) * 4 + Math.ceil(taskStartDateTime.getMinutes() / 15);
        totalQuarts -= additionalQuarts;
      }
      this.drawTask(task, cell.offsetTop, cell.offsetLeft, totalQuarts);
    } else if (taskEndDateTime.getHours() > endTimeHour && taskStartDateTime.getHours() < endTimeHour) {
      console.log('TimesheetComponent.computeTask: Case 4', task);
      const indexRow = taskStartDateTime.getDay() - 1;
      const indexColumn = (taskStartDateTime.getHours() - startTimeHour) * 4 + Math.floor(taskStartDateTime.getMinutes() / 15);
      const cell = document.querySelector(`div#cell-${indexRow}-${indexColumn}`) as HTMLElement;
      const totalMilliseconds = taskEndDateTime.getTime() - taskStartDateTime.getTime();
      let totalQuarts = totalMilliseconds / (1000 * 60 * 15);
      if (taskEndDateTime.getHours() > endTimeHour || (taskEndDateTime.getHours() === endTimeHour && taskEndDateTime.getMinutes() > 0)) {
        const additionalQuarts = (taskEndDateTime.getHours() - endTimeHour) * 4 + Math.ceil(taskEndDateTime.getMinutes() / 15);
        totalQuarts -= additionalQuarts;
      }
      this.drawTask(task, cell.offsetTop, cell.offsetLeft, totalQuarts);
    }
  }

  private drawTask(task: Task, top: number, left, totalQuarts: number) {
    console.log('TimesheetComponent.drawTask: ', task, top, left, totalQuarts);
    const taskLine = document.createElement('div');
    taskLine.id = `task-${task._id}`;
    taskLine.style.overflow = 'hidden';
    taskLine.style.position = 'absolute';
    taskLine.style.height = `${this.cellHeight}px`;
    taskLine.style.width = `${this.cellWidth * totalQuarts}px`;
    taskLine.style.left = `${ left }px`;
    taskLine.style.top = `${ top }px`;
    taskLine.textContent = `${task.name}`;
    taskLine.style.fontWeight = 'normal';
    taskLine.style.fontSize = '12px';
    taskLine.style.cursor = 'pointer';
    taskLine.style.paddingLeft = `8px`;
    taskLine.style.backgroundColor = `#E3E3E3`;
    taskLine.style.borderTop = `4px solid ${task.color}`;
    taskLine.style.borderBottom = `1px solid ##B1B1B1`;
    taskLine.style.borderLeft = `1px solid ##B1B1B1`;
    taskLine.style.borderRight = `1px solid ##B1B1B1`;

    taskLine.addEventListener('click', () => {
      this.selectedTaskChanged.emit(task);
    });

    this.gridPart.nativeElement.appendChild(taskLine);
  }

  private removeGrid(){
    console.log('TimesheetComponent.removeGrid: Grid line');
    const elements = document.querySelectorAll('div[id^="grid-line-"]');
    elements.forEach(element => {
      element.remove();
    });
  }

  private removeQuartHours(){
    console.log('TimesheetComponent.removeDates: Quart hours');
    const element = document.querySelector(`div#quart-hours-list`);
    if (element) {
      element.remove();
    }
  }

  private removeDates() {
    console.log('TimesheetComponent.removeDates: Remove dates');
    const elements = document.querySelectorAll('div[id^="dates-list-"]');
    elements.forEach(element => {
      element.remove();
    });
  }

  private removeTask(task: Task) {
    console.log('TimesheetComponent.removeTask: Remove task', task._id);
    const element = document.querySelector(`div#task-${task._id}`);
    if (element) {
      element.remove();
    }
  }

  private removeTasks() {
    console.log('TimesheetComponent.removeTasks: Remove tasks');
    const elements = document.querySelectorAll('div[id^="task-"]');
    elements.forEach(element => {
      element.remove();
    });
  }

  private removeHours() {
    console.log('TimesheetComponent.removeHours: Remove hours');
    const element = document.querySelector('div#hours-list');
    if (element) {
      element.remove();
    }
  }
}
