import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Campaign } from 'src/app/models/entities/campaigns/campaign.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

    public async getCampaign(campaignId: string): Promise<Campaign> {
      console.log('CampaignsServices.getCampaign: Start to get campaign', campaignId);
  
      return new Promise<Campaign>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('CampaignsServices.getCampaign : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<Campaign>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `campaigns`, filter: { '_id': { $oid: campaignId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('CampaignsServices.getCampaign : Campaign', data);
          resolve(data.document);
        });
      });
    }  

  public async getCampaigns(project: Project): Promise<Array<Campaign>> {
    console.log('CampaignsServices.getCampaigns: Start to get campaigns', project);

    return new Promise<Array<Campaign>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('CampaignsServices.getCampaigns : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Campaign[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `campaigns`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('CampaignsServices.getCampaigns : Campaigns', data);
        resolve(data.documents);
      });
    });
  }

  public async addCampaign(campaign: Campaign): Promise<Campaign> {
    console.log('CampaignsServices.addCampaign: Start to add campaign', campaign);

    return new Promise<Campaign>(async (resolve, reject) => {

      const campaignData = { 
        projectId: campaign.projectId,
        name: campaign.name,
        description: campaign.description, 
        color: campaign.color, 
        startDate: campaign.startDate, 
        endDate: campaign.endDate, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('CampaignsServices.addCampaign : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Campaign>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `campaigns`, document: campaignData }, httpOptions).toPromise().then((data: any) => {
        console.log('CampaignsServices.addCampaign : Campaign added', data);
        campaign._id = data.insertedId;
        resolve(campaign);
      });

    });
  }

  public async updateCampaign(campaign: Campaign): Promise<Campaign> {
    console.log('CampaignsServices.updateCampaign: Start to update campaign', campaign);

    return new Promise<Campaign>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('CampaignsServices.updateCampaign : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const campaignData = { 
        name: campaign.name,
        description: campaign.description, 
        color: campaign.color, 
        startDate: campaign.startDate, 
        endDate: campaign.endDate, 
        modification: new Date()
      };
        
      await this.httpClient.post<Campaign>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `campaigns`, filter: { '_id': { $eq: { $oid: campaign._id } } }, update: { $set: campaignData } }, httpOptions).toPromise().then((data: any) => {
        console.log('CampaignsServices.updateCampaign : Campaign updated', data);
        if(data.matchedCount > 0) {
          resolve(campaign);
         } else {
          reject(data);
        }
      });
    });
  }
}
