import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFireStorage } from '@angular/fire/compat/storage';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { ProjectFile } from 'src/app/models/entities/files/file.model';
import { Project } from '../../models/entities/projects/project.model';

@Injectable({
  providedIn: 'root'
})
export class FilesServices {
  
  constructor(
    private httpClient: HttpClient,
    private storage: AngularFireStorage,
    private realmTokenServices: RealmTokenServices) { }

  public async getFile(fileId: string): Promise<ProjectFile> {
    console.log('FilesServices.getFile: Start to get file', fileId);

    return new Promise<ProjectFile>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('FilesServices.getFile : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<ProjectFile>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `files`, filter: { '_id': { $oid: fileId } } }, httpOptions).toPromise().then((data: any) => {
        console.log('FilesServices.getFile : File', data);
        resolve(data.document);
      });
    });
  }

  public async getFiles(project: Project): Promise<Array<ProjectFile>> {
    console.log('FilesServices.getFiles: Start to get projects\'s files', project);

    return new Promise<Array<ProjectFile>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('FilesServices.getFiles : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<ProjectFile[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `files`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('FilesServices.getFiles : Files', data);
        resolve(data.documents);
      });
    });
  }

  public async addFile(projectFile: ProjectFile): Promise<ProjectFile> {
    console.log('FilesServices.addFile: Start to add project\'s file', projectFile);

    return new Promise<ProjectFile>(async (resolve, reject) => {

      const projectFileData = { 
        originalTitle: projectFile.originalTitle,
        title: projectFile.title, 
        description: projectFile.description, 
        projectId: projectFile.projectId, 
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ProjectsServices.addProject : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<ProjectFile>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `files`, document: projectFileData }, httpOptions).toPromise().then((data: any) => {
        console.log('PaymentsServices.addPayment : Project added', data);
        projectFile._id = data.insertedId;
        resolve(projectFile);
      });
    });
  }

  public async updateFile(projectFile: ProjectFile): Promise<ProjectFile> {
    console.log('FilesServices.updateFile: Start to update file', projectFile);

    return new Promise<ProjectFile>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('FilesServices.updateFile : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const projectFileData = { 
        description: projectFile.description, 
        title: projectFile.title, 
        modification: new Date()
      };
        
      await this.httpClient.post<ProjectFile>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `files`, filter: { '_id': { $eq: { $oid: projectFile._id } } }, update: { $set: projectFileData } }, httpOptions).toPromise().then((data: any) => {
        console.log('FilesServices.updateFile : ProjectFile updated', data);
        if(data.matchedCount > 0) {
          resolve(projectFile);
         } else {
          reject(data);
        }
      });
    });
  }

  public async deleteFile(file: ProjectFile): Promise<boolean> {
    console.log('FilesServices.deleteFile: Start to delete file', file);

    return new Promise<boolean>((resolve, reject) => {
      
    });
  }
}
