<div class="row">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ 'box.informations' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="mt-3">
          <div class="row">
            <b>{{ 'box.ipaddress' | translate }}</b>
            <label>{{ selectedBox.ipAddress }}</label>
          </div>
          <div class="row mt-3">
            <b>{{ 'box.macaddress' | translate }}</b>
            <label>{{ selectedBox.macAddress }}</label>
          </div>
          <div class="row mt-3">
            <b>{{ 'box.slot' | translate }}</b>
            <label>{{ selectedBoxConfiguration.slot.name }}</label>
          </div>
          <div class="row">
              <button mat-raised-button class="mt-3" color="basic" [routerLink]="['/projects', 'project', this.selectedProject._id, 'settingsiot']">{{ 'generic.cancel' | translate }}</button>
              <button mat-raised-button class="mt-3" color="primary" (click)="updateBox()">{{ 'generic.validate' | translate }}</button>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ 'box.traces' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="mt-3">

          <ngx-charts-line-chart
          [results]="tracesData"
          [gradient]="false"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="''"
          [yAxisLabel]="''"
          >

          </ngx-charts-line-chart>

        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
