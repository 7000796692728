import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Box } from 'src/app/models/entities/iot/boxes/box.model';
import { Slot } from 'src/app/models/entities/projects/slot.model';
import { Sensor } from 'src/app/models/entities/iot/sensors/sensor.model';
import { BoxSensor } from 'src/app/models/entities/iot/sensors/box.sensor.model';

@Injectable({
  providedIn: 'root'
})
export class SensorsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getSensor(sensorId: string): Promise<Sensor> {
      console.log('SensorsServices.getSensor: Start to get sensor', sensorId);
  
      return new Promise<Sensor>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('SensorsServices.getSensor : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
          
        await this.httpClient.post<Sensor>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `sensors`, filter: { '_id': { $oid: sensorId } } }, httpOptions).toPromise().then((data: any) => {
          console.log('SensorsServices.getSensor : Sensor', data);
          resolve(data.document);
        });
      });
  }
  
  public async getSensors(box: Box): Promise<Array<Sensor>> {
    console.log('SensorsServices.getSensors: Start to get sensors', box);

    return new Promise<Array<Sensor>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('SensorsServices.getSensors : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      let boxSensors = new Array<BoxSensor>();
      await this.httpClient.post<BoxSensor[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `box-sensors`, filter: { 'boxId': box._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('SensorsServices.getSensors : Box sensors', data);
        boxSensors = data.documents;
      });

      let sensors = new Array<Sensor>();
      for(let i = 0; i < boxSensors.length; i++) {
        let boxSensor = boxSensors[i];
        await this.httpClient.post<Sensor[]>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `sensors`, filter: { '_id': { $eq: { $oid: boxSensor.sensorId } } } }, httpOptions).toPromise().then((data: any) => {
          console.log('SensorsServices.getSensors : Sensor', data);
          sensors = sensors.concat(data.document);
        });
      }
      resolve(sensors);
    });
  }
}
