import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'delete-entity-modal',
  templateUrl: 'delete.entity.modal.html'
})
export class DeleteEntityModal {
  constructor(
    public dialogRef: MatDialogRef<DeleteEntityModal>,
    @Inject(MAT_DIALOG_DATA) public entityName: string,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}