import { EntityBase } from "../entity.base.model";
import { Supplier } from "./supplier.model";

export class Material extends EntityBase {
  projectId: string;
  supplierId: string;
  originalAddress: string;
  name: string;
  description: string;
  quantity: number;

  supplier: Supplier;
}
