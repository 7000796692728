import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from '../../material.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { SharedModule } from './../../shared/shared.module';

import { SchedulerRoutingModule } from './scheduler.routing.module';

import { SchedulerComponent } from './scheduler.component';

@NgModule({
  declarations: [
    SchedulerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NgxChartsModule,

    SharedModule,

    MaterialModule,

    SchedulerRoutingModule,

  ],
  providers: [
  ],
  bootstrap: []
})
export class SchedulerModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
