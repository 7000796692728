<div class="row">
  <div class="col-md-4">
    <div class="row">
      <mat-card *ngIf="selectedRevisionFile">
        <mat-card-header>
          {{ selectedRevisionFile.name }}
        </mat-card-header>
        <mat-card-content>
          <div class="row mt-3">
            <span [ngSwitch]="selectedRevisionFile.status">
              <span *ngSwitchCase="0" class="badge-warning text-center"> {{ 'file.statusenum.proofread' | translate }} </span>
              <span *ngSwitchCase="1" class="badge-success text-center"> {{ 'file.statusenum.accepted' | translate }} </span>
              <span *ngSwitchCase="2" class="badge-warning text-center"> {{ 'file.statusenum.changerequest' | translate }} </span>
              <span *ngSwitchCase="3" class="badge-danger text-center"> {{ 'file.statusenum.denied' | translate }} </span>
            </span>
          </div>
          <div class="row mt-3">
            {{ selectedRevisionFile.owner }}
          </div>
          <div class="row mt-3">
            {{ selectedRevisionFile.modification | date:'short' }}
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="row mt-3" *ngIf="!selectedRevisionFile?.signature">
      <mat-card>
        <mat-card-header>
        </mat-card-header>
        <mat-card-content>
          <div class="row mt-3">
            <button mat-flat-button color="primary" (click)="approveFile()">
              {{ 'file.approve' | translate }}
            </button>
          </div>
          <div class="row mt-3">
            <button mat-flat-button color="accent" (click)="changeRequestFile()">
              {{ 'file.statusenum.changerequest' | translate }}
            </button>
          </div>
          <div class="row mt-3">
            <button mat-flat-button color="warn" (click)="rejectFile()">
              {{ 'file.reject' | translate }}
            </button>
          </div>

        </mat-card-content>
      </mat-card>
    </div>

    <div class="row mt-3" *ngIf="selectedRevisionFile?.signature">
      <mat-card>
        <mat-card-header>
        </mat-card-header>
        <mat-card-content>
          <img mat-card-image [src]="selectedRevisionFile.signature" width="250" class="image-container" />
        </mat-card-content>
      </mat-card>
    </div>

    <div class="row mt-3">
      <mat-card>
        <mat-card-header>
        </mat-card-header>
        <mat-card-content>

          <div class="row mt-3" *ngFor="let revisionFileComment of revisionFileComments">
            <div class="col-md-2">
              <avatar 
              *ngIf="revisionFileComment.ownerData"
              mat-icon-button 
              matTooltip="{{ revisionFileComment?.owner }}" 
              [isEditable]="false"
              [size]="'40px'"
              [name]="revisionFileComment.ownerData.firstname + ' ' + revisionFileComment.ownerData.lastname"
              [imageSource]="revisionFileComment.ownerData.picture"></avatar>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="row">
                <small><i>({{ revisionFileComment.creation | date:'short' }})</i></small>
              </div>
              <div class="row">
                {{ revisionFileComment.comment }}
              </div>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <div class="col-md-8">
    <div class="row">
      <div class="col-2">
        <mat-label>{{ 'viewfile.defaultviewname' | translate }}</mat-label>
      </div>
      <div class="col-2">
        <b>{{ defaultViewName }}</b>
      </div>
      <div class="col-4">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'viewfile.2dviews' | translate }}</mat-label>
          <mat-select name="view" (selectionChange)="on2DSelectViewChanged($event)">
            <mat-option *ngFor="let view of views2d; let i = index" [value]="i">{{ view.data.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'viewfile.3dviews' | translate }}</mat-label>
          <mat-select name="view" (selectionChange)="on3DSelectViewChanged($event)">
            <mat-option *ngFor="let view of views3d; let i = index" [value]="i">{{ view.data.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div id="forgeViewer" style="position:relative;min-height:75vh"></div>
    </div>
    
  </div>

</div>
