<div class="mt-3">

  <div class="row">

    <div class="col-md-1">
      <button mat-flat-button color="primary" (click)="addProject()"><mat-icon>add</mat-icon> {{ 'generic.add' | translate }}</button>
    </div>

    <div class="col-md-11">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'projects.filters' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field>
            <input matInput type="text" name="projectName" placeholder="{{ 'projects.name' | translate }}" [(ngModel)]="searchedProjects.name" (input)="onSearchedProjectsChanged()">
          </mat-form-field>

          <div class="row form-group d-flex justify-content-center">
            <button mat-raised-button color="primary" class="btn-block" (click)="resetFilters()">{{ 'generic.cancel' | translate }}</button>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>

  <div class="row">

    <!--<google-map></google-map>-->

  </div>
  

  
</div>

<div class="mt-3">

  <div class="col-md-4 offset-md-5">
    <mat-spinner *ngIf="this.dataAccessServices.projects.length === 0"></mat-spinner>
  </div>
  
  <table mat-table [dataSource]="dataSource" *ngIf="this.dataAccessServices.projects.length > 0">

    <ng-container matColumnDef="phase">
      <th mat-header-cell *matHeaderCellDef> {{ 'projects.phase' | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <span [ngSwitch]="element.phase">
          <span *ngSwitchCase="0"> <mat-icon>widgets</mat-icon></span>
          <span *ngSwitchCase="1"> <mat-icon>format_paint</mat-icon></span>
          <span *ngSwitchCase="2"> <mat-icon>play_for_work</mat-icon></span>
          <span *ngSwitchCase="3"> <mat-icon>delete_sweep</mat-icon></span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'projects.name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> {{ 'projects.description' | translate }} </th>
      <td mat-cell *matCellDef="let element"> <div [truncateText]="100">{{ element.description }}</div> </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ 'projects.status' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.projectStatus?.name }} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['project', element._id]"><mat-icon>edit</mat-icon> {{ 'generic.edit' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <button mat-flat-button color="warn" (click)="deleteProject(element)"><mat-icon>delete</mat-icon> {{ 'generic.delete' | translate }}</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
                 *ngIf="this.dataAccessServices.projects.length > 0"
                 [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</div>
