import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { ProjectsServices } from '../../../services/projects/projects.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { ScoringsServices } from 'src/app/services/suppliers/scorings.services';
import { Scoring } from 'src/app/models/entities/suppliers/scoring.model';

@Component({
  selector: 'app-scorings',
  templateUrl: './scorings.component.html'
})
export class ScoringsComponent {

  public selectedProject: Project = null;
  public scorings: Array<Scoring> = new Array<Scoring>();
  public displayedColumns: string[] = ['title', 'weighting', 'delete'];
  public dataSource = new MatTableDataSource<Scoring>(this.scorings);
  public panelOpenState = false;
  public scoring: Scoring = new Scoring();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private scoringsServices: ScoringsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('ScoringsComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  public async ngOnInit() {
    await this.scoringsServices.getScorings(this.selectedProject).then(data => {
      console.log('ScoringsComponent.ngOnInit : Scorings', data);
      this.scorings = data;
      this.dataSource = new MatTableDataSource<Scoring>(this.scorings);
    });
  }

  public async addScoring() {
    this.scoring.projectId = this.selectedProject._id;
    await this.scoringsServices.addScoring(this.scoring).then(data => {
      console.log('ScoringsComponent.addScoring : Scoring', data);
      this.scoring = new Scoring();
      this.scorings.push(data);
      this.dataSource = new MatTableDataSource<Scoring>(this.scorings);
    });
  }

  public deleteScoring(scoring: Scoring): void {

  }
}
