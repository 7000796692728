import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { QuotesComponent } from './list/quotes.component';
import { QuoteComponent } from './details/quote.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/quotes', component: QuotesComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/quotes/quote/:quoteid', component: QuoteComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class QuotesRoutingModule { }
