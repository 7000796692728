import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Alarm } from '../../models/entities/alarms/alarm.model';
import { Project } from 'src/app/models/entities/projects/project.model';

@Injectable({
  providedIn: 'root'
})
export class AlarmsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async getAlarms(project: Project): Promise<Array<Alarm>> {
    console.log('AlarmsServices.getAlarms: Start to get alarms', project._id);

    return new Promise<Array<Alarm>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('AlarmsServices.getAlarms : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Alarm[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `alarms`, filter: { 'projectId': project._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('AlarmsServices.getAlarms : Alarms', data);
        resolve(data.documents);
      });
    });
  }

  public async getAlarm(alarmId: string): Promise<Alarm> {
    console.log('AlarmsServices.getAlarm: Start to get alarm', alarmId);
      
    return new Promise<Alarm>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('AlarmsServices.getAlarm : Token', data);
          token = data;
        });

        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Project>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `alarms`, filter: { '_id': { $eq: { $oid: alarmId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('AlarmsServices.getAlarm : Alarm', data);
        resolve(data.document);
      });
    });
  }
}
