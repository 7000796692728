import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { ViewFilesComponent } from './view/view.files.component';
import { FilesComponent } from './list/files.component';
import { EditFileReviewComponent } from './edit/edit.filereview.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/reviewfiles', component: FilesComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/viewfile/:revisionfileid', component: ViewFilesComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/edit/:revisionfileid', component: EditFileReviewComponent, canActivate: [AuthGuardServices] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FileReviewsRoutingModule { }
