import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { RbqServices } from 'src/app/services/opendata/rbq.services';

import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Project } from 'src/app/models/entities/projects/project.model';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add.supplier.component.html',
  styleUrls: ['./add.supplier.component.scss']
})
export class AddSupplierComponent {

  public selectedProject: Project = null;
  public searchedSupplier: Supplier = new Supplier();
  public supplier: Supplier = new Supplier();
  public suppliersFound: Array<Supplier> = new Array<Supplier>();
  public displayedColumns: string[] = ['name', 'licenceNumber', 'deliveredLicenceDate', 'companyDigitalIdentifier', 'status', 'insuranceCompany', 'city', 'select'];
  public dataSource = new MatTableDataSource<Supplier>(this.suppliersFound);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices,
    private rbqServices: RbqServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('SuppliersComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
     }

  ngOnInit() {
  }

  public async searchSupplier() {
    await this.rbqServices.getCompany(this.searchedSupplier.name, this.searchedSupplier.companyDigitalIdentifier).then(data => {
      console.log('AddSupplierComponent.searchSupplier: Supplierd found', data);
      this.suppliersFound = data;
      this.dataSource = new MatTableDataSource<Supplier>(this.suppliersFound);
    });
  }

  public selectSupplier(supplier: Supplier) {
    this.supplier = supplier;
  }
  
  public async addSupplier(): Promise<void> {
    this.supplier.projectId = this.selectedProject._id;
    await this.suppliersServices.addSupplier(this.supplier).then((data: Supplier) => {
      console.log('AddSupplierComponent.addSupplier: Supplier added', data);
      this.supplier = data;
    });

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'suppliers']);
  }
}
