import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as uuid from 'uuid';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { Project } from 'src/app/models/entities/projects/project.model';

@Injectable({
  providedIn: 'root'
})
export class QuotesServices {

  @Output() uploadFileProgressEvent: EventEmitter<number> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private storage: AngularFireStorage,
    private realmTokenServices: RealmTokenServices) { }

  public async uploadQuote(project: Project, quote: Quote): Promise<Quote> {
    return new Promise<Quote>(async (resolve, reject) => {
      const fileGuid = uuid.v4();
      quote.documentTitle = `${fileGuid}-${quote.originalTitle}`;
      const filePath = `quotes/${project.name}/${quote.documentTitle}`;

      const task = this.storage.upload(filePath, quote.data).snapshotChanges().subscribe(uploadTask => {
        console.log('QuotesServices.uploadQuote : Uploading file', Math.round(100 * uploadTask.bytesTransferred / uploadTask.totalBytes), '%');
        this.uploadFileProgressEvent.emit(Math.round(100 * uploadTask.bytesTransferred / uploadTask.totalBytes));
        if(uploadTask.bytesTransferred === uploadTask.totalBytes) {
          console.log('QuotesServices.uploadQuote : File transfered', quote);
        } 
        if(uploadTask.state === 'success') {
          console.log('QuotesServices.uploadQuote : Upload task success', uploadTask, quote);
          resolve(quote);
        }
      });
    });
  }

  public async getDownloadUrl(project: Project, quote: Quote): Promise<Quote> {
    console.log('QuotesServices.getDownloadUrl : Get download url', project, quote);
    return new Promise<Quote>(async (resolve, reject) => {

      const filePath = `quotes/${project.name}/${quote.documentTitle}`;
      const fileRef = this.storage.ref(filePath);

      await fileRef.getDownloadURL().toPromise().then(data => {
        console.log('QuotesServices.getDownloadUrl : Download url', data);
        quote.downloadURL = data;
      });

      resolve(quote);
    });
  };

  public async getQuote(quoteId: string): Promise<Quote> {
    console.log('QuotesServices.getQuote: Start to get quote', quoteId);

    return new Promise<Quote>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('QuotesServices.getQuote : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Quote>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `quotes`, filter: { '_id': { $oid: quoteId } } }, httpOptions).toPromise().then((data: any) => {
        console.log('QuotesServices.getQuote : Quote', data);
        resolve(data.document);
      });
    });
  }

  public async getQuotes(supplier: Supplier): Promise<Array<Quote>> {
    console.log('QuotesServices.getQuotes: Start to get quotes', supplier);

    return new Promise<Array<Quote>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('QuotesServices.getQuotes : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Quote[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `quotes`, filter: { 'supplierId': supplier._id } }, httpOptions).toPromise().then((data: any) => {
        console.log('QuotesServices.getQuotes : Quotes', data);
        resolve(data.documents);
      });
    });
  }

  public async addQuote(quote: Quote): Promise<Quote> {
    console.log('QuotesServices.addQuote: Start to add quote', quote);

    return new Promise<Quote>(async (resolve, reject) => {

      const quoteData = { 
        supplierId: quote.supplierId,
        originalTitle: quote.originalTitle,
        documentTitle: quote.documentTitle, 
        name: quote.name, 
        comment: quote.comment, 
        downloadURL: quote.downloadURL, 
        ownerId: quote.ownerId,
        status: quote.status,
        amount: quote.amount,
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('QuotesServices.addQuote : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Annotation>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `quotes`, document: quoteData }, httpOptions).toPromise().then((data: any) => {
        console.log('QuotesServices.addQuote : Quote added', data);
        quote._id = data.insertedId;
        resolve(quote);
      });

    });
  }

  public async updateQuote(quote: Quote): Promise<Quote> {
    console.log('QuotesServices.updateQuote: Start to update quote', quote);

    return new Promise<Quote>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('QuotesServices.updateQuote : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const quoteData = { 
        name: quote.name, 
        comment: quote.comment, 
        status: quote.status,
        amount: quote.amount,
        modification: new Date()
      };
        
      await this.httpClient.post<Quote>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `quotes`, filter: { '_id': { $eq: { $oid: quote._id } } }, update: { $set: quoteData } }, httpOptions).toPromise().then((data: any) => {
        console.log('QuotesServices.updateQuote : Quote updated', data);
        if(data.matchedCount > 0) {
          resolve(quote);
         } else {
          reject(data);
        }
      });
    });
  }
}
