import { Component } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import {
  ViewerOptions,
  ViewerInitializedEvent
} from 'ng2-adsk-forge-viewer';
import { List } from 'linqts';
import { MatDialog } from '@angular/material/dialog';

import { DataAccessServices } from 'src/app/services/data.access.services';
import { AutoDeskServices } from '../../../services/autodesk/autodesk.services';
import { FilesServices } from 'src/app/services/files/files.services';
import { AddAnnotationModal } from 'src/app/shared/components/modals/annotations/add.annotation.modal';
import { Annotation } from 'src/app/models/entities/annotations/annotation.model';

import { ProjectFile } from 'src/app/models/entities/files/file.model';
import { AnnotationsServices } from 'src/app/services/annotations/annotations.services';
import { MatTableDataSource } from '@angular/material/table';
import { UsersServices } from 'src/app/services/users/users.services';
import { RevisionFilesServices } from 'src/app/services/files/revision.files.services';
import { RevisionFile } from 'src/app/models/entities/files/revision.file.model';

declare const Autodesk: any;

@Component({
  selector: 'app-view.files',
  templateUrl: './view.files.component.html',
  styleUrls: ['./view.files.component.scss']
})

export class ViewFilesComponent {

  private revisionFileId: string;
  private viewer : any;
  private viewerDocument: any;
  private selectedViewIndex: number = 0;
  private annotations: Array<Annotation> = new Array<Annotation>();

  public revisionFile: RevisionFile;
  public defaultViewName: string = null;
  public views: any;
  public views2d: any;
  public views3d: any;
  public pdfDocuments: any;
  public annotationsDataSource = new MatTableDataSource<Annotation>(this.annotations);
  public annotationsDisplayedColumns: string[] = ['subject', 'ownerFullName', 'viewName'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataAccessServices: DataAccessServices,
    private autoDeskServices: AutoDeskServices,
    private filesServices: FilesServices,
    private revisionFilesServices: RevisionFilesServices,
    private annotationsServices: AnnotationsServices,
    private usersServices: UsersServices,
    public dialog: MatDialog) {
      this.activatedRoute.params.subscribe(params => {
        console.log('ViewFilesComponent.constructor : Retrieved params', params, this.dataAccessServices.currentUser);
        this.revisionFileId = params['revisionfileid'];
      });
  }

  public async ngAfterViewInit() {
    console.log('ViewFilesComponent.ngAfterViewInit : Initialiez viewer');
    await this.revisionFilesServices.getRevisionFile(this.revisionFileId).then((data: RevisionFile) => {
      console.log('ViewFilesComponent.ngAfterViewInit: File', data);
      this.revisionFile = data;
    });

    await this.initializeViewer().then(data => {
      console.log('ViewFilesComponent.ngAfterViewInit: Viewer initialized', data);
    });

    await this.annotationsServices.getAnnotations(this.revisionFile).then((data: Array<Annotation>) => {
      console.log('ViewFilesComponent.ngAfterViewInit: Annotations', data);
      this.annotations = data;
      this.annotationsDataSource = new MatTableDataSource<Annotation>(this.annotations);
    });

    this.annotations.forEach(annotation => {
      this.setOwnerFullName(annotation);
    })
  }

  private async setOwnerFullName(annotation: Annotation): Promise<void> {
    this.usersServices.get(annotation.ownerId).then(data => {
      annotation.ownerFullName = `${data.firstname} ${data.lastname}`;
    });
  }

  private async initializeViewer() {
    console.log('ViewFilesComponent.initializeViewer : Start to generate token');
    
    const initializerOptions: Autodesk.Viewing.InitializerOptions = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',
      getAccessToken: function(onTokenReady) {
        this.autoDeskServices.getToken().then((data: string) => {
          console.log('ViewFilesComponent.initializeViewer : getAccessToken - Token generated', data);
          onTokenReady(data, 3600 * 30);
        });
      }.bind(this)
    };

    const viewerOptions: ViewerOptions = {
      initializerOptions: initializerOptions,
      onViewerInitialized: function (args: ViewerInitializedEvent): void {
        console.log('ViewFilesComponent.initializeViewer : OnViewerInitialized', args);
      }
    };
    
    Autodesk.Viewing.Initializer(viewerOptions.initializerOptions, () => {
      const forgeViewer = document.getElementById('forgeViewer');
      forgeViewer.addEventListener('click', this.onForgeViewerClick.bind(this));
      this.viewer = new Autodesk.Viewing.GuiViewer3D(forgeViewer);
      this.viewer.start();
      this.viewer.loadExtension('Autodesk.PDF').then(() => {
        console.log('ViewFilesComponent.initializeViewer : Extension pdf loaded');
      });
      this.loadDocument();
    });
    
  }

  private loadDocument() {
    Autodesk.Viewing.Document.load(`urn:${this.autoDeskServices.base64UrlEncode(this.revisionFile.decodedUrn)}`, (document) => {
      const defaultModel = document.getRoot().getDefaultGeometry();
      console.log('ViewFilesComponent.loadDocument : Default model', defaultModel);
      this.defaultViewName = defaultModel.data.name;
      this.viewer.loadDocumentNode(document, defaultModel);
      this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
        console.log('ViewFilesComponent.loadDocument : Geometry loaded');
        this.annotations.forEach(annotation => {
          this.drawAnnotation(annotation);
        });
      });
      this.setViews(document);
    });
  }

  private setViews(viewerDocument) {
    this.viewerDocument = viewerDocument;
    const documentRoot = this.viewerDocument.getRoot();
    this.views = documentRoot.search({type:'geometry'});
    const documents3d = documentRoot.search({type:'geometry', role: '3d'});
    this.views3d = documents3d;
    const documents2d = documentRoot.search({type:'geometry', role: '2d'});
    this.views2d = documents2d;
    this.annotations.forEach(annotation => {
      annotation.viewName = this.views[annotation.viewIndex].data.name;
    });
  }

  public on2DSelectViewChanged(event) {
    console.log('ViewFilesComponent.on2DSelectViewChanged : View selection changed', event);
    this.selectedViewIndex = event.value;
    const view = this.views2d[this.selectedViewIndex];
    console.log('ViewFilesComponent.on2DSelectViewChanged : Selected view', view);
    this.viewer.loadDocumentNode(this.viewerDocument, view);
    
  }

  public on3DSelectViewChanged(event) {
    console.log('ViewFilesComponent.on3DSelectViewChanged : View selection changed', event);
    this.selectedViewIndex = event.value;
    const view = this.views3d[this.selectedViewIndex];
    console.log('ViewFilesComponent.on3DSelectViewChanged : Selected view', view);
    this.viewer.loadDocumentNode(this.viewerDocument, view);
  }

  private onForgeViewerClick(event) {
    console.log('ViewFilesComponent.onForgeViewerClick : ', event);
    const clientX = event.clientX;
    const clientY = event.clientY;

    const canvas = this.viewer.canvas;
    const canvasRect = canvas.getBoundingClientRect();
    const canvasX = clientX - canvasRect.left;
    const canvasY = clientY - canvasRect.top;

    const width = canvas.width;
    const height = canvas.height;

    const normalizedX = (canvasX / width) * 2 - 1;
    const normalizedY = -(canvasY / height) * 2 + 1;

    const screenPoint = new THREE.Vector3(normalizedX, normalizedY, 0);
    const worldPoint = this.viewer.clientToWorld(screenPoint.x, screenPoint.y, screenPoint.z);

    console.log('ViewFilesComponent.onForgeViewerClick : Coordinates found', worldPoint);

    this.addMarker(clientX, clientY);
  }

  private addMarker(clientX, clientY) {
    console.log('ViewFilesComponent.addMarker : Start to add marker', clientX, clientY);

    let annotation = new Annotation();
    annotation.revisionFileId = this.revisionFile._id;
    annotation.viewIndex = this.selectedViewIndex;
    annotation.coordX = clientX;
    annotation.coordY = clientY;
    annotation.ownerId = this.dataAccessServices.currentUser._id;
    annotation.number = this.annotations.length + 1;
    const dialogRef = this.dialog.open(AddAnnotationModal, {
      data: annotation,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      this.drawAnnotation(annotation);
      await this.annotationsServices.addAnnotation(annotation).then((data: Annotation) => {
        console.log('ViewFilesComponent.addMarker: Annotation added', data);
        this.annotations.push(data);
      });
    });
  }

  private drawAnnotation(annotation: Annotation) {
    console.log('ViewFilesComponent.drawAnnotation: Draw annotation', annotation, this.selectedViewIndex);
    if(annotation.viewIndex === this.selectedViewIndex) {
      console.log('ViewFilesComponent.drawAnnotation: Annotation', annotation, 'matches with current view index', this.selectedViewIndex);
      const circle = document.createElement('div');
      circle.classList.add('annotation');
      circle.textContent = annotation.number.toString();
      circle.style.top = `${annotation.coordY}px`;
      circle.style.left = `${annotation.coordX}px`;
      circle.style.cursor = 'pointer'; 
      circle.style.position = 'absolute'; 
      circle.style.zIndex = '1000';
      circle.addEventListener('click', () => {
        const dialogRef = this.dialog.open(AddAnnotationModal, {
          data: annotation,
        });
        dialogRef.afterClosed().subscribe(async result => {
          console.log('The dialog was closed', result);
        });
      });
      document.body.appendChild(circle);
    }
  }
}
