import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { List } from 'linqts';

import { environment } from '../../../environments/environment';

import { DataAccessServices } from './../../services/data.access.services';
import { TasksServices } from 'src/app/services/tasks/tasks.services';

import { EditTaskModal } from 'src/app/shared/components/modals/edit-task/edit.task.modal';
import { AddTaskModal } from 'src/app/shared/components/modals/add-task/add.task.modal';

import { Project } from 'src/app/models/entities/projects/project.model';
import { Task } from 'src/app/models/entities/tasks/task.model';
import { TaskType } from 'src/app/models/entities/tasks/task.type.enum';
import { SettingsServices } from 'src/app/services/commons/settings.services';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent {

  public tasks: Array<Task> = new Array<Task>();
  public selectedDate: Date = new Date();
  public startTime: string;
  public endTime: string;
  public hoursDisplayed : number = this.settingsServices.isCellPhone ? 4 : 8; 
  public schedulerViewType: string = 'week';

  private selectedProject: Project = new Project();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public dataAccessServices: DataAccessServices,
    public settingsServices: SettingsServices,
    private tasksServices: TasksServices
  ) {
    this.activatedRoute.params.subscribe(params => {
      console.log('SchedulerComponent.constructor : Retrieved params', params);
      let id = params['projectid'];
      this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
      if (this.selectedProject === null || this.selectedProject === undefined) {
        this.router.navigate(['/projects']);
      }
    });

    const currentHour = this.selectedDate.getHours();
    const startTimeDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), currentHour, 0, 0);
    this.startTime = startTimeDate.toTimeString().substring(0, 5);
    startTimeDate.setHours(startTimeDate.getHours() + (this.hoursDisplayed - 1));
    this.endTime = startTimeDate.toTimeString().substring(0, 5);
   }

  async ngOnInit() {
    console.log('SchedulerComponent.ngOnInit: Scheduler view init');

    await this.tasksServices.getTasks(this.selectedProject).then(data => {
      console.log('SchedulerComponent.ngOnInit: Tasks', data);
      this.tasks = data;
    });
  }

  async ngAfterViewInit() {
    console.log('SchedulerComponent.ngAfterViewInit: After scheduler view init');
  }

  public toggleAddTaskModal() {
    console.log('SchedulerComponent.toggleAddTaskModal: Toggle add task');
    const dialogRef = this.dialog.open(AddTaskModal, {
      data: { tasks: new List(this.tasks).Where(t => t.type === TaskType.task).ToArray(), project: this.selectedProject },
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('SchedulerComponent.toggleAddTaskModal: The dialog was closed', result);
      if(result !== null && result !== undefined) {
        result.id = this.tasks.length + 1;
        const options: any = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        };
        if(result.start !== undefined && result.start !== null) {
          result.start = new Date(result.start).toLocaleDateString('en-US', options);
        }
        if(result.end !== undefined && result.end !== null) {
          result.end = new Date(result.end).toLocaleDateString('en-US', options);
        }
        if(result.date !== undefined && result.date !== null) {
          result.date = new Date(result.date).toLocaleDateString('en-US', options);
        }

        result.projectId = this.selectedProject._id;
        result.ownerId = this.dataAccessServices.currentUser._id;

        await this.tasksServices.addTask(result).then(data => {
          console.log('SchedulerComponent.toggleAddTaskModal: Task added', data);
          result = data;
        });

        const temp = this.tasks.slice();
        temp.push(result);
        this.tasks = temp;
        console.log('SchedulerComponent.toggleAddTaskModal: New array', this.tasks);
      }
    });
  }

  public selectedTaskChanged(event) {
    console.log('SchedulerComponent.selectedTaskChanged: Selected task changed', event);
    const dialogRef = this.dialog.open(EditTaskModal, {
      data: { task: event, tasks: this.tasks, project: this.selectedProject },
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('SchedulerComponent.selectedTaskChanged: The dialog was closed', result);
      if(result !== null && result !== undefined) {

        await this.tasksServices.updateTask(result).then(data => {
          console.log('SchedulerComponent.selectedTaskChanged: Task updated', data);
        });

        const index = this.tasks.indexOf(event);
        console.log('SchedulerComponent.selectedTaskChanged: Index', index);
        const temp = this.tasks.slice();
        temp.splice(index, 1);
        temp.splice(index, 0, result);
        this.tasks = temp;
        console.log('SchedulerComponent.selectedTaskChanged: New array', this.tasks);
      } else {
        await this.tasksServices.deleteTask(result).then(data => {
          console.log('SchedulerComponent.selectedTaskChanged: Task deleted', data);
        });
        const index = this.tasks.indexOf(event);
        const temp = this.tasks.slice();
        temp.splice(index, 1);
        this.tasks = temp;
      }
    });
  }

  public onStartTimeChange(event) {
    console.log('SchedulerComponent.onStartTimeChange: Start time changed 1', event);
    this.startTime = event;
    console.log('SchedulerComponent.onStartTimeChange: Start time changed 2', this.startTime);
    const startTimeHour = parseInt(this.startTime.split(':')[0]);
    const startTimeMinut = parseInt(this.startTime.split(':')[1]);
    const startTimeDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), startTimeHour, startTimeMinut, 0);
    startTimeDate.setHours(startTimeDate.getHours() + (this.hoursDisplayed - 1));
    this.endTime = startTimeDate.toTimeString().substring(0, 5);
  }

  public onEndTimeChange(event) {
    console.log('SchedulerComponent.onStartTimeChange: Start time changed', event);
    this.endTime = event;
    const endTimeHour = parseInt(this.endTime.split(':')[0]);
    const endTimeMinut = parseInt(this.endTime.split(':')[1]);
    const startTimeDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), endTimeHour, endTimeMinut, 0);
    startTimeDate.setHours(startTimeDate.getHours() - (this.hoursDisplayed - 1));
    this.startTime = startTimeDate.toTimeString().substring(0, 5);
  }
}
