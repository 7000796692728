import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { QuotesServices } from 'src/app/services/quotes/quotes.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { QuoteStatus } from 'src/app/models/entities/quotes/quote.status.enum';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html'
})
export class OrdersComponent {

  public selectedProject: Project = null;
  public suppliers: Array<Supplier> = new Array<Supplier>();
  public quotes: Array<Quote> = new Array<Quote>();
  public displayedColumns: string[] = ['supplier', 'name', 'owner', 'amount', 'status', 'download', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Quote>(this.quotes);
  public documentsCount: number = 0;
  public uploadingFile: boolean = false;
  public quote: Quote = new Quote();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private suppliersServices: SuppliersServices,
    private quotesServices: QuotesServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('SuppliersComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  public async ngOnInit() {
    await this.suppliersServices.getSuppliers(this.selectedProject).then(data => {
      console.log('OrdersComponent.ngOnInit : Supliers', data);
      this.suppliers = data;
    });

    for(let i = 0; i < this.suppliers.length; i++) {
      const supplier = this.suppliers[i];
      await this.quotesServices.getQuotes(supplier).then(data => {
        console.log('OrdersComponent.ngOnInit : Quotes', data);
        this.quotes = this.quotes.concat(data);
      });
    }

    for (let j = 0; j < this.quotes.length; j++) { 
      const quote = this.quotes[j];
      await this.usersServices.get(quote.ownerId).then(data => {
        quote.owner = `${data.firstname} ${data.lastname}`;
      });
      quote.supplier = new List(this.suppliers).First(s => s._id === quote.supplierId).name;
    }

    this.dataSource = new MatTableDataSource<Quote>(this.quotes);
  }

  public async uploadFile(files: any[]): Promise<void> {
    this.uploadingFile = true;
    const dataFile = files[0];
    console.log('OrdersComponent.uploadFile : ', dataFile);

    this.quote.data = dataFile;
    this.quote.ownerId = this.dataAccessServices.currentUser._id;
    this.quote.status = QuoteStatus.proofread;

    await this.quotesServices.uploadQuote(this.selectedProject, this.quote).then((data: Quote) => {
      console.log('OrdersComponent.uploadFile: File added', data);
      this.quote = data;
    });

    await this.quotesServices.getDownloadUrl(this.selectedProject, this.quote).then((data: Quote) => {
      console.log('OrdersComponent.uploadFile : Get download url finished', data);
      this.quote = data;
    });

    await this.quotesServices.addQuote(this.quote).then((data: Quote) => {
      console.log('OrdersComponent.uploadFile: File added', data);
      this.quote = data;
    });

    this.quotes.unshift(this.quote);

    for (let j = 0; j < this.quotes.length; j++) { 
      const quote = this.quotes[j];
      await this.usersServices.get(quote.ownerId).then(data => {
        quote.owner = `${data.firstname} ${data.lastname}`;
      });
    }

    this.quotes = new List(this.quotes).OrderByDescending(r => r.creation).ToArray();
    this.dataSource = new MatTableDataSource<Quote>(this.quotes);
    
    this.uploadingFile = false;
  }

  public downloadDocument(quote: Quote) {
    window.open(quote.downloadURL, '_blank');
  }

  public deleteSupplier(supplier: Supplier): void {

  }
}
