import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardServices } from '../../services/commons/auth.guard.services';

import { ContractsComponent } from './list/contracts.component';
import { ContractComponent } from './details/contract.component';

const routes: Routes = [
  { path: 'projects/project/:projectid/contracts', component: ContractsComponent, canActivate: [AuthGuardServices] },
  { path: 'projects/project/:projectid/contracts/contract/:contractid', component: ContractComponent, canActivate: [AuthGuardServices] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ContractsRoutingModule { }
