import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';
import { MatTableDataSource } from '@angular/material/table';

import { DataAccessServices } from '../../../services/data.access.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';
import { QuotesServices } from 'src/app/services/quotes/quotes.services';

import { Project } from '../../../models/entities/projects/project.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { QuoteStatus } from 'src/app/models/entities/quotes/quote.status.enum';
import { Contract } from 'src/app/models/entities/contracts/contract.model';
import { ContractStatus } from 'src/app/models/entities/contracts/contract.status.enum';
import { ContractsServices } from 'src/app/services/contracts/contracts.services';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html'
})
export class ContractsComponent {

  public selectedProject: Project = null;
  public suppliers: Array<Supplier> = new Array<Supplier>();
  public quotes: Array<Quote> = new Array<Quote>();
  public contracts: Array<Contract> = new Array<Contract>();
  public displayedColumns: string[] = ['supplier', 'quote', 'name', 'owner', 'amount', 'status', 'download', 'edit', 'delete'];
  public dataSource = new MatTableDataSource<Contract>(this.contracts);
  public documentsCount: number = 0;
  public uploadingFile: boolean = false;
  public contract: Contract = new Contract();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private suppliersServices: SuppliersServices,
    private quotesServices: QuotesServices,
    private contractsServices: ContractsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('ContractsComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
  }

  public async ngOnInit() {
    await this.suppliersServices.getSuppliers(this.selectedProject).then(data => {
      console.log('ContractsComponent.ngOnInit : Supliers', data);
      this.suppliers = data;
    });

    for(let i = 0; i < this.suppliers.length; i++) {
      const supplier = this.suppliers[i];
      await this.quotesServices.getQuotes(supplier).then(data => {
        console.log('ContractsComponent.ngOnInit : Quotes', data);
        this.quotes = this.quotes.concat(data);
      });
    }

    for(let i = 0; i < this.suppliers.length; i++) {
      const supplier = this.suppliers[i];
      for(let j = 0; j < this.quotes.length; j++) {
        const quote = this.quotes[j];
        await this.contractsServices.getContracts(supplier, quote).then(data => {
          console.log('ContractsComponent.ngOnInit : Contracts', data);
          if(data.length > 0) {
            const existingContractIds = new List(this.contracts).Select(contract => contract._id);
            const uniqueNewContracts = new List(data).Where(newContract => !existingContractIds.Contains(newContract._id)).ToArray();
            this.contracts = this.contracts.concat(uniqueNewContracts);
          }
        });
      }
    }

    for (let j = 0; j < this.contracts.length; j++) { 
      const contract = this.contracts[j];
      await this.usersServices.get(contract.ownerId).then(data => {
        contract.owner = `${data.firstname} ${data.lastname}`;
      });
      if(contract.supplierId === undefined || contract.supplierId === null) {
        this.quotesServices.getQuote(contract.quoteId).then(data => {
          contract.supplier = new List(this.suppliers).First(s => s._id === data.supplierId).name;
        });
      } else {
        contract.supplier = new List(this.suppliers).First(s => s._id === contract.supplierId).name;
      }
    }

    this.dataSource = new MatTableDataSource<Contract>(this.contracts);
  }

  public async uploadFile(files: any[]): Promise<void> {
    this.uploadingFile = true;
    const dataFile = files[0];
    console.log('ContractsComponent.uploadFile : ', dataFile);

    this.contract.data = dataFile;
    this.contract.ownerId = this.dataAccessServices.currentUser._id;
    this.contract.status = ContractStatus.proofread;

    await this.contractsServices.uploadContract(this.selectedProject, this.contract).then((data: Contract) => {
      console.log('ContractsComponent.uploadFile: File added', data);
      this.contract = data;
    });

    await this.contractsServices.getDownloadUrl(this.selectedProject, this.contract).then((data: Contract) => {
      console.log('ContractsComponent.uploadFile : Get download url finished', data);
      this.contract = data;
    });

    await this.contractsServices.addContract(this.contract).then((data: Contract) => {
      console.log('ContractsComponent.uploadFile: File added', data);
      this.contract = data;
    });

    this.contracts.unshift(this.contract);

    for (let j = 0; j < this.contracts.length; j++) { 
      const contract = this.contracts[j];
      await this.usersServices.get(contract.ownerId).then(data => {
        contract.owner = `${data.firstname} ${data.lastname}`;
      });
    }

    this.contracts = new List(this.contracts).OrderByDescending(r => r.creation).ToArray();
    this.dataSource = new MatTableDataSource<Contract>(this.contracts);
    
    this.uploadingFile = false;
  }

  public downloadDocument(contract: Contract) {
    window.open(contract.downloadURL, '_blank');
  }

  public deleteSupplier(supplier: Supplier): void {

  }
}
