import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';

import { DataAccessServices } from '../../../services/data.access.services';
import { Project } from '../../../models/entities/projects/project.model';
import { CampaignsServices } from 'src/app/services/campaigns/campaigns.services';
import { Campaign } from 'src/app/models/entities/campaigns/campaign.model';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add.campaign.component.html'
})
export class AddCampaignComponent {

  public selectedProject: Project = null;
  public campaign: Campaign = new Campaign();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private campaignsServices: CampaignsServices) {
      this.activatedRoute.params.subscribe(params => {
        console.log('AddCampaignComponent.constructor : Retrieved params', params);
        let id = params['projectid'];
        this.selectedProject = new List(this.dataAccessServices.projects).First(a => a._id == id);
        if (this.selectedProject === null || this.selectedProject === undefined) {
          this.router.navigate(['/projects']);
        }
      });
    }

  async ngOnInit() {
  }

  public async addCampaign(): Promise<void> {
    this.campaign.projectId = this.selectedProject._id;
    await this.campaignsServices.addCampaign(this.campaign).then((data: Campaign) => {
      console.log('AddCampaignComponent.addCampaign: Campaign added', data);
      this.campaign = data;
    });

    this.router.navigate(['/projects', 'project', this.selectedProject._id, 'campaigns']);
  }
}
