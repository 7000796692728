<autodesk-viewer 
  [revisionFile]="revisionFile" ></autodesk-viewer>

<div class="row mt-3">
  <table mat-table [dataSource]="annotationsDataSource">

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef> {{ 'annotation.subject' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.subject }} </td>
    </ng-container>

    <ng-container matColumnDef="ownerFullName">
      <th mat-header-cell *matHeaderCellDef> {{ 'annotation.owner' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.ownerFullName }} </td>
    </ng-container>

    <ng-container matColumnDef="viewName">
      <th mat-header-cell *matHeaderCellDef> {{ 'annotation.viewname' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.viewName }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="annotationsDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: annotationsDisplayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>



