<div class="form-container">

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'material.supplier' | translate }}</mat-label>
      <mat-select name="supplier" [(ngModel)]="material.supplierId">
        <mat-option *ngFor="let supplier of suppliers" [value]="supplier._id">{{ supplier.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'material.name' | translate }}</mat-label>
      <input matInput [(ngModel)]="material.name">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'material.description' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="material.description"></textarea>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'material.quantity' | translate }}</mat-label>
      <input matInput [(ngModel)]="material.quantity">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field>
      <mat-label>{{ 'material.originaladdress' | translate }}</mat-label>
      <textarea matInput rows="8" [(ngModel)]="material.originalAddress"></textarea>
    </mat-form-field>
  </div>

  <div class="row">
    <button mat-raised-button class="mt-3" color="basic" [routerLink]="'/materials'">{{ 'generic.cancel' | translate }}</button>
    <button mat-raised-button class="mt-3" color="primary" (click)="addMaterial()">{{ 'generic.validate' | translate }}</button>
  </div>
</div>
