<autodesk-viewer 
  [revisionFile]="revisionFile" 
  [annotations]="annotations" 
  [annotationTypes]="annotationTypes"
  [suppliers]="suppliers"></autodesk-viewer>

<div class="row mt-3">
  <div class="col-md-6 px-2">
    <table mat-table [dataSource]="annotationsDataSource">

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef> {{ 'annotation.number' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.number }} </td>
      </ng-container>
  
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> {{ 'annotation.subject' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.subject }} </td>
      </ng-container>
  
      <ng-container matColumnDef="campaign">
        <th mat-header-cell *matHeaderCellDef> {{ 'annotation.campaign' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.campaign?.name }} </td>
      </ng-container>
      
      <ng-container matColumnDef="ownerFullName">
        <th mat-header-cell *matHeaderCellDef> {{ 'annotation.owner' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.ownerFullName }} </td>
      </ng-container>
  
      <ng-container matColumnDef="viewName">
        <th mat-header-cell *matHeaderCellDef> {{ 'annotation.viewname' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.viewName }} </td>
      </ng-container>
  
      <ng-container matColumnDef="takesnapshot">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-mini-fab (click)="openAnnotationCampaignsFilters()" [disabled]="!isGeometryLoaded">
            <mat-icon>flag</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element"> </td>
      </ng-container>
  
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-mini-fab (click)="openAnnotationTypesFilters()" [disabled]="!isGeometryLoaded">
            <mat-icon>filter_list</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element"> <!--<button mat-flat-button color="primary" (click)="editAnnotation(element)">{{ 'generic.edit' | translate }}</button> --></td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="annotationsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: annotationsDisplayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
  <div class="col-md-6 px-3">
    <table mat-table [dataSource]="tasksDataSource">

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef> {{ 'task.supplier' | translate }} </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="getRowStyle(element)"> {{ element.supplier?.name }} </td>
      </ng-container>
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'task.name' | translate }} </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="getRowStyle(element)"> {{ element.name }} </td>
      </ng-container>
  
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> {{ 'task.startDate' | translate }} </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="getRowStyle(element)"> {{ element.start | date:'shortDate' }} </td>
      </ng-container>
      
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> {{ 'task.endDate' | translate }} </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="getRowStyle(element)"> {{ element.end  | date:'shortDate' }} </td>
      </ng-container>
  
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [ngStyle]="getRowStyle(element)"> <button mat-flat-button color="primary" (click)="editTask(element)">{{ 'generic.edit' | translate }}</button> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="tasksDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tasksDisplayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>



