import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as uuid from 'uuid';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Annotation } from 'src/app/models/entities/annotations/annotation.model';
import { Supplier } from 'src/app/models/entities/suppliers/supplier.model';
import { Quote } from 'src/app/models/entities/quotes/quote.model';
import { Project } from 'src/app/models/entities/projects/project.model';
import { Contract } from 'src/app/models/entities/contracts/contract.model';

@Injectable({
  providedIn: 'root'
})
export class ContractsServices {

  @Output() uploadFileProgressEvent: EventEmitter<number> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private storage: AngularFireStorage,
    private realmTokenServices: RealmTokenServices) { }

  public async uploadContract(project: Project, contract: Contract): Promise<Contract> {
    return new Promise<Contract>(async (resolve, reject) => {
      const fileGuid = uuid.v4();
      contract.documentTitle = `${fileGuid}-${contract.originalTitle}`;
      const filePath = `contracts/${project.name}/${contract.documentTitle}`;

      const task = this.storage.upload(filePath, contract.data).snapshotChanges().subscribe(uploadTask => {
        console.log('ContractsServices.uploadContract : Uploading file', Math.round(100 * uploadTask.bytesTransferred / uploadTask.totalBytes), '%');
        this.uploadFileProgressEvent.emit(Math.round(100 * uploadTask.bytesTransferred / uploadTask.totalBytes));
        if(uploadTask.bytesTransferred === uploadTask.totalBytes) {
          console.log('ContractsServices.uploadContract : File transfered', contract);
        } 
        if(uploadTask.state === 'success') {
          console.log('ContractsServices.uploadContract : Upload task success', uploadTask, contract);
          resolve(contract);
        }
      });
    });
  }

  public async getDownloadUrl(project: Project, contract: Contract): Promise<Contract> {
    console.log('ContractsServices.getDownloadUrl : Get download url', project, contract);
    return new Promise<Contract>(async (resolve, reject) => {

      const filePath = `contracts/${project.name}/${contract.documentTitle}`;
      const fileRef = this.storage.ref(filePath);

      await fileRef.getDownloadURL().toPromise().then(data => {
        console.log('ContractsServices.getDownloadUrl : Download url', data);
        contract.downloadURL = data;
      });

      resolve(contract);
    });
  };

  public async getContract(contractId: string): Promise<Contract> {
    console.log('ContractsServices.getContract: Start to get contract', contractId);

    return new Promise<Contract>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ContractsServices.getContract : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Contract>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `contracts`, filter: { '_id': { $oid: contractId } } }, httpOptions).toPromise().then((data: any) => {
        console.log('ContractsServices.getContract : Contract', data);
        resolve(data.document);
      });
    });
  }

  public async getContracts(supplier: Supplier, quote: Quote): Promise<Array<Contract>> {
    console.log('ContractsServices.getContracts: Start to get contracts', supplier);

    return new Promise<Array<Contract>>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ContractsServices.getContracts : Token', data);
        token = data;
      });

      const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
        
      await this.httpClient.post<Contract[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `contracts`, filter: { $or: [{ 'supplierId': supplier._id} , { 'quoteId': quote._id}]  } }, httpOptions).toPromise().then((data: any) => {
        console.log('ContractsServices.getContracts : Contracts', data);
        resolve(data.documents);
      });
    });
  }

  public async addContract(contract: Contract): Promise<Contract> {
    console.log('ContractsServices.addContract: Start to add contract', contract);

    return new Promise<Contract>(async (resolve, reject) => {

      const contractData = { 
        supplierId: contract.supplierId,
        quoteId: contract.quoteId,
        originalTitle: contract.originalTitle,
        documentTitle: contract.documentTitle, 
        name: contract.name, 
        comment: contract.comment, 
        downloadURL: contract.downloadURL, 
        ownerId: contract.ownerId,
        status: contract.status,
        amount: contract.amount,
        modification: new Date(),
        creation: new Date() 
      };

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ContractsServices.addContract : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Contract>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `contracts`, document: contractData }, httpOptions).toPromise().then((data: any) => {
        console.log('ContractsServices.addContract : Contract added', data);
        contract._id = data.insertedId;
        resolve(contract);
      });

    });
  }

  public async updateContract(contract: Contract): Promise<Contract> {
    console.log('ContractsServices.updateContract: Start to update contract', contract);

    return new Promise<Contract>(async (resolve, reject) => {
      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('ContractsServices.updateContract : Token', data);
        token = data;
      });
  
      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      const contractData = { 
        name: contract.name, 
        comment: contract.comment, 
        status: contract.status,
        amount: contract.amount,
        modification: new Date()
      };
        
      await this.httpClient.post<Contract>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `contracts`, filter: { '_id': { $eq: { $oid: contract._id } } }, update: { $set: contractData } }, httpOptions).toPromise().then((data: any) => {
        console.log('ContractsServices.updateContract : Contract updated', data);
        if(data.matchedCount > 0) {
          resolve(contract);
         } else {
          reject(data);
        }
      });
    });
  }
}
