import { EntityBase } from "../entity.base.model";
import { BIMPhase } from "./bim.phase.enum";
import { ProjectStatus } from "./project.status.model";

export class Project extends EntityBase {
  name: string;
  description: string;
  address: string;
  image: string;
  projectStatus: ProjectStatus;
  phase: BIMPhase;
  latitude: number;
  longitude: number;
}
